import React, { createContext, useCallback, useContext, useMemo, useState } from "react"

type ValidationContextType = {
    resetValidation: () => void
    updateValidationError: (identifier: string, error: string | undefined) => void
    firstError: string | undefined
    errors: Map<string, string>
}

const ValidationContext = createContext<ValidationContextType | undefined>(undefined)

export const ValidationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [errors, setErrors] = useState<Map<string, string>>(new Map())

    const updateValidationError = useCallback((identifier: string, error: string | undefined) => {
        setErrors(prev => {
            const updated = new Map(prev)

            if (error) {
                updated.set(identifier, error)
            } else {
                updated.delete(identifier)
            }

            return updated
        })
    }, [])

    const resetValidation = useCallback(() => setErrors(new Map()), [])

    const firstError = useMemo(() => {
        const errorMessages = [...errors.values()]
        return errorMessages.length > 0 ? errorMessages[0] : undefined
    }, [errors])

    const value = useMemo(() => ({ updateValidationError, resetValidation, firstError, errors }), [errors, firstError, resetValidation, updateValidationError])

    return (
        <ValidationContext.Provider value={value}>
            {children}
        </ValidationContext.Provider>
    )
}

export const useValidationProvider = () => {
    const context = useContext(ValidationContext)

    if (!context) {
        throw new Error("useValidation must be used within a ValidationProvider")
    }

    return context
}
