import { MarginedBoundingBox } from "../../../../Types/MarginedBoundingBox"
import { Offset } from "../../../../Types/Offset"
import { ModalityGraphConfig } from "../../../../Types/Graph"
import { D3TimeBasedVisualization } from "../../D3TimeBasedVisualization"
import { D3ModalityGraphGroupConfigurationBuilder } from "./D3ModalityGraphGroupConfigurationBuilder"
import { D3ModalityGraphGroupRenderer } from "./D3ModalityGraphGroupRenderer"
import { ModalityGraphGroupReactCallbacks } from "../../../../Types/ReactCallbacks"
import { ModalityGraphGroupConfig } from "../../../../Types/ModalityGraphGroup"
import { TimeSeriesPageManager } from "../../../../Data/TimeSeriesPageManager"
import { ModalityPage } from "../../../../Data/ModalityPage"
import { LinkedWindowJumpEvent } from "../../../../Types/LinkedWindowInfo"
import { getVisibleGraphs } from "../visibleGraphs"
import { LEFT_MARGIN, RIGHT_MARGIN } from "../../Constants"
import { ModalityDataSource } from "../../../../Types/ModalityDataSource"
import { getFullTraceDataConfigs, RenderStrategy, TraceDataConfig } from "../../../../Types/Trace"

export class D3ModalityGraphGroup extends D3TimeBasedVisualization<
	ModalityGraphGroupConfig,
	ModalityGraphGroupReactCallbacks,
	D3ModalityGraphGroupRenderer,
	TimeSeriesPageManager<ModalityPage>
> {
	public graphsBoundingBox: MarginedBoundingBox
	public graphSpacing: number = 30
	public graphsMargins: Offset = { top: 30, left: LEFT_MARGIN, bottom: 60, right: RIGHT_MARGIN }
	public overlayMargins: Offset = { top: this.graphSpacing, bottom: this.graphSpacing, left: 0, right: 0 }
	public overlayBoundingBox: MarginedBoundingBox
	public graphs: ModalityGraphConfig[] = []

	constructor(root: HTMLDivElement, config: ModalityGraphGroupConfig, pageManager: TimeSeriesPageManager<any>, reactCallbacks: ModalityGraphGroupReactCallbacks) {
		super(root, config, pageManager, reactCallbacks)
		this.graphsBoundingBox = new MarginedBoundingBox(config.dimensions, this.graphsMargins)
		this.overlayBoundingBox = new MarginedBoundingBox({ width: this.graphsBoundingBox.width, height: this.graphsBoundingBox.height }, this.overlayMargins)
		this.mount(new D3ModalityGraphGroupRenderer(this, new D3ModalityGraphGroupConfigurationBuilder(this), "d3-modality-graph-group"))
		this.renderer?.autoScaleGraphs()
	}

	public clearDataAndReload = () => {
		this.timeSeriesPageManager.resetPages()
		this.renderer?.graphsWrapper?.clearSnapshot()
		this.updateConfig(this.config)
		this.timeSeriesPageManager.clearQueueAndLoad()
	}

	public getOverlay = () => this.renderer?.graphsOverlay

	public getLastHoveredDate = () => this.getOverlay()?.getLastHoveredDate()

	protected renderPage(page: ModalityPage): void {
		this.renderer?.graphsWrapper?.renderPage(page)
	}

	public viewTimesChanged = (startTime: number, endTime: number) => {
		const { viewDuration } = this.getStartTimeEndTimeViewDuration()
		const calculatedViewDuration = endTime - startTime

		if (viewDuration !== calculatedViewDuration) {
			// If the view duration has changed, we need to let React handle the update.
			// Otherwise, we end up reverting the duration back to the old duration.
			// This bug is caused by the updates from another component's timeline when it is playing.
			if (this.anotherLinkedWindowIsPlaying()) {
				return
			}
		}

		this.config.viewScale.domain([startTime, endTime])
		this.renderer?.viewTimesChanged()

		if (!this.renderer?.graphsWrapper?.isRescaling()) {
			this.timeSeriesPageManager?.clearQueueAndLoad()
		}
	}

	public onTimeAxisDragStart = () => {
		this.renderer?.graphsWrapper?.takeSnapshot()
	}

	public onTimeAxisDrag = () => {
		this.renderer?.onTimeAxisDrag()
		this.updateLinkedWindows()
	}

	public onTimelineSliderDrag = () => {
		this.timeSeriesPageManager?.clearQueueAndLoad()
		this.renderer?.onTimelineSliderDrag()
		this.updateLinkedWindows()
	}

	public onTimelineSliderDragEnd = () => {
		this.timeSeriesPageManager?.clearQueueAndLoad()
		this.updateLinkedWindows({ autoScale: true })
		this.renderer?.autoScaleGraphs()
	}

	public getVisibleTraces(): TraceDataConfig[] {
		return this.config.graphs?.flatMap(graph => graph.traces.flatMap(getFullTraceDataConfigs))
	}

	// PROTECTED

	protected getModalityDataSources(): ModalityDataSource[] {
		return [...new Set(this.graphs.flatMap(graph => graph.traces))].flatMap(trace => {
			switch (trace.renderStrategy) {
				case RenderStrategy.DELTA:
					return [trace.first, trace.second].map(config => ({
							modality: config.dataKey,
							dataObjectId: this.reactCallbacks.dataSourceMap.get(config.dataSource) ?? Infinity,
							onDemandAnalysis: config.onDemandAnalysis
						}))
				default:
					return {
						modality: trace.dataKey,
						dataObjectId: this.reactCallbacks.dataSourceMap.get(trace.dataSource) ?? Infinity,
						onDemandAnalysis: trace.onDemandAnalysis
					}
			}
		})
	}

	protected onLinkedWindowsUpdate = (event: LinkedWindowJumpEvent) => {
		if (!this.renderer?.graphsWrapper?.isRescaling() && this.config.isLinked && event.controller !== this.config.id) {
			this.onJumpToTime(event)

			if (event.options?.autoScale) {
				this.renderer?.autoScaleGraphs()
			}
		}
	}

	protected updateDerivedState = () => {
		this.graphsBoundingBox.setDimensions(this.config.dimensions)
		this.overlayBoundingBox.setDimensions({ height: this.graphsBoundingBox.height, width: this.graphsBoundingBox.width })

		this.config.viewScale.range([0, this.graphsBoundingBox.width])

		const visibleGraphs = getVisibleGraphs(this.config.graphs, this.config.patientModalities, this.config.hideEmptyGraphs)

		const graphHeight = Math.max(1, (this.graphsBoundingBox.height - this.graphSpacing) / visibleGraphs.length - this.graphSpacing)

		this.graphs = visibleGraphs.map((graph, index) => ({
			...graph,
			xScale: this.config.viewScale,
			height: graphHeight,
			width: this.graphsBoundingBox.width,
			offset: index * (graphHeight + this.graphSpacing) + this.graphSpacing,
		}))

		this.renderer?.updateChildren()
	}
}
