import { Socket } from "socket.io-client"
import { ModalityDataSource } from "../Types/ModalityDataSource"
import { ImagePage, ImagePageProperties } from "./ImagePage"

type PersystTrendsImagePageProperties = ImagePageProperties & {
	isArtifactReductionEnabled: boolean
}

export class PersystTrendsImagePage extends ImagePage<PersystTrendsImagePageProperties> {
	public isArtifactReductionEnabled = false
	
    socketEventName = () => "render_persyst_trend"

    requestData(batch: Map<number, ModalityDataSource[]>, socket: Socket): void {
		if (this.getDataQuerySocket === null) {
			return
		}
		
		batch.forEach((modalities, dataObjectId) => {
			socket.emit(
				this.socketEventName(), 
				this.patientId, 
				dataObjectId, 
				this.id,
				modalities[0],
				this.startTime,
				this.endTime,
				this.width,
				this.height,
				this.isArtifactReductionEnabled
			)
		})
    }
}