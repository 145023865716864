import React, { useState, useContext } from "react";
import { formQuestionTabs } from "./FormQuestions";
import { ControlledTabs } from "../../../../../../../../../Components/Tabs/Tabs";
import { TabSelector } from "../../../../../../../../../Components/Tabs/TabSelector";
import { IICPatternTypeForm } from "./IICPatternTypeForm";
import { IICPatternFeaturesForm } from "./IICPatternFeaturesForm";
import { IICPatternType, useEBOOSTEventReviewProvider } from "../../../../../../../../../Providers/EBOOSTEventReviewProvider";
import { MobergColumn, MobergFontFamily, MobergFontSize, MobergRow } from "../../../../../../../../../Moberg";
import { Scrollbar } from "../../../../../../../../../Constants/StyledComponents";
import { DimensionsContext } from "../../../../../../../../../Providers/DimensionsProvider";

interface EventReviewFormWindowProps {
    style?: React.CSSProperties
}

export const EventReviewFormsWindow: React.FC<EventReviewFormWindowProps> = ({style}) => {
    const EBOOSTEventReviewProvider = useEBOOSTEventReviewProvider()
    const { height } = useContext(DimensionsContext)
    const [selectedTab, setSelectedTab] = useState(formQuestionTabs.IIC_PATTERN_TYPE)
    const tabs = (EBOOSTEventReviewProvider.patternType === IICPatternType.OTHER) || (!EBOOSTEventReviewProvider.patternType) ? ['IIC Pattern Type']: ['IIC Pattern Type', 'IIC Pattern Features']
    
    // It's possible that the current tab could be missing from the tabs list
    if (!tabs.includes(selectedTab)) {
        setSelectedTab(tabs[0])
    }

    return (
        <MobergColumn verticalAlign="start" style={{ fontFamily: MobergFontFamily.REGULAR, padding: "10px", height: height, width: "100%", overflow: "auto", whiteSpace: "nowrap", ...style }} gap="8px">
            <MobergRow gap="32px">
                <ControlledTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab}>
                    <TabSelector tabKeys={tabs} style={{fontSize: MobergFontSize.REGULAR}}/>
                </ControlledTabs>
            </MobergRow>

            <Scrollbar style={{ height: "100%" }}>
                {selectedTab === formQuestionTabs.IIC_PATTERN_TYPE && <IICPatternTypeForm />}
                {selectedTab === formQuestionTabs.IIC_PATTERN_FEATURES && <IICPatternFeaturesForm />}
            </Scrollbar>
        </MobergColumn>
    )
}