import { debounce } from "lodash"
import { PersystTrendsTitlesAndLabelsSocketResponse } from "../Types/SocketResponse"
import { PersystPageManager } from "./PersystPageManager"
import { PersystTrendsImagePage } from "./PersystTrendsImagePage"
import { ImagePageManagerConfig } from "./ImageTimeSeriesPageManager"
import { ModalityDataSource } from "../Types/ModalityDataSource"

export type PersystTrendsPageManagerConfig = ImagePageManagerConfig & {
	artifactReductionEnabled: boolean
}

export class PersystTrendsImagePageManager extends PersystPageManager<PersystTrendsPageManagerConfig> {
	getPageMaker = () => {
		return () => new PersystTrendsImagePage()
	}

	protected getNewPage(startTime: number, endTime: number, width: number, height: number, patientId: string, modalityDataSources: ModalityDataSource[], socketId: string, patientIsAdmitted: boolean): PersystTrendsImagePage {
		const page = new PersystTrendsImagePage()

		page.updateProperties({ 
			startTime, 
			endTime, 
			width, 
			height, 
			patientId, 
			modalityDataSources, 
			socketId, 
			isArtifactReductionEnabled: this.config.artifactReductionEnabled, 
			patientIsAdmitted,
			maxReadTime: this.config.fileScale.domain()[1].getTime(),
			getDataQuerySocket: this.getDataQuerySocket
		})

		return page
	}

	protected updatePageProperties(page: PersystTrendsImagePage, startTime: number, endTime: number, width: number): void {
		page.updateProperties({
			startTime,
			endTime,
			width,
			socketId: this.config.windowId,
			patientId: this.config.patientId,
			modalityDataSources: this.config.modalityDataSources,
			patientIsAdmitted: this.config.patientIsAdmitted,
			height: this.config.height,
			maxReadTime: this.config.fileScale.domain()[1].getTime(),
			getDataQuerySocket: this.getDataQuerySocket,
			isArtifactReductionEnabled: this.config.artifactReductionEnabled
		})
	}

	public isArtifactReductionEnabled = () => this.config.artifactReductionEnabled

	// Debounce allows us to call this function a lot and not worry about sending a butt ton of requests.
	protected requestTitlesAndLabels = debounce((height: number, width: number, panel: string) => {
		this.setRequestDimensions({ height, width, panel })

		const eventName = "render_persyst_trend_labels"

		if (this.getDataQuerySocket === null) {
			return
		}
		
		const socket = this.getDataQuerySocket(this.config.windowId)

		const listener = (event: PersystTrendsTitlesAndLabelsSocketResponse) => {
			if (event.height !== height || event.width !== width) {
				socket.off(eventName, listener)
				return
			}

			const data = {
				titles: event.titles,
				labels: event.labels,
			}

			this.setTitlesAndLabelsCache({
				panel,
				dimensions: { height, width },
				data: data,
			})

			socket.off(eventName, listener)
			this.setRequestDimensions(null)
			this.onTitlesAndLabelsLoaded()
		}

		socket.on(eventName, listener)
		socket.emit(eventName, this.config.patientId, this.config.modalityDataSources[0].dataObjectId, panel, width, 50, height)
	}, 200)
}
