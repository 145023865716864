import React from "react";
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { editedLayoutGroupAtom, selectedLayoutAtom, selectedLayoutGroupAtom, uneditedLayoutGroupAtom } from "../../../../Atoms/Layout";
import { ConfigureEventReviewModal } from "./ConfigureEventReviewModal";
import { getColors, MobergBoxShadow, MobergButton, MobergButtonShape, MobergFontSize, MobergIconSize, MobergRow, MobergTheme } from "../../../../../../../../Moberg";
import { MdModeEdit, MdOutlineErrorOutline } from "react-icons/md"
import MobergToggle from "../../../../../../../../Components/MobergToggle/MobergToggle";
import { useEventReviewProvider } from "../../../../../../../../Providers/EventReviewProvider";
import { useEBOOSTEventReviewProvider } from "../../../../../../../../Providers/EBOOSTEventReviewProvider";
import { useValidationProvider } from "../../../../../../../../Providers/ValidationProvider";

type EventReviewSettingsProps = {
    windowId: string
}

export function EventReviewSettings(props: EventReviewSettingsProps) {
    const { createModal } = useModalProvider()
    const { firstError } = useValidationProvider()
    const eventReviewProvider = useEventReviewProvider()
    const EBOOSTEventReviewProvider = useEBOOSTEventReviewProvider()
    const setUneditedLayoutGroup = useSetRecoilState(uneditedLayoutGroupAtom)
    const setEditedLayoutGroup = useSetRecoilState(editedLayoutGroupAtom)
    const selectedLayoutGroup = useRecoilValue(selectedLayoutGroupAtom)
    const selectedLayout = useRecoilValue(selectedLayoutAtom)
    const red = getColors(MobergTheme.RED).main


    function renderConfigureModal() {
        if (!selectedLayout) {
            console.error("Could not render the Configure Event Review modal because a layout was not selected.")
            return
        }

        setUneditedLayoutGroup(selectedLayoutGroup)
        setEditedLayoutGroup(selectedLayoutGroup)
        createModal(<ConfigureEventReviewModal windowId={props.windowId} layoutId={selectedLayout.id}/>)
    }

    function jumpToCurrentEvent() {
        if (eventReviewProvider.currentAnnotation) {
            const noSave = true
            EBOOSTEventReviewProvider.navigateToAnnotation(eventReviewProvider.currentAnnotation, noSave)
        }
    }

    const errorMessage = firstError ? firstError : EBOOSTEventReviewProvider.saveReviewTask.error

    return (
        <MobergRow horizontalAlign="space-between" style={{ boxShadow: `${MobergBoxShadow.LOW}`, padding: "0px 8px" }}>
            <MobergRow expand={false} style={{ flex: 1, whiteSpace: "nowrap", overflowX: "auto" }} gap="32px">
                <MobergButton onClick={jumpToCurrentEvent} theme={MobergTheme.BLUE} style={{ padding: "2px 8px" }}>
                    Jump to current event
                </MobergButton>

                <MobergToggle
                    checked={eventReviewProvider.showMoreMetadata}
                    onChange={() => eventReviewProvider.setShowMoreMetadata(prev => !prev)}
                    color="primary"
                    label="Detailed event info"
                    labelPlacement="end"
                />

                <MobergToggle
                    checked={eventReviewProvider.showAllEvents}
                    onChange={() => eventReviewProvider.setShowAllEvents(prev => !prev)}
                    color="primary"
                    label="Show event list"
                    labelPlacement="end"
                />

                {eventReviewProvider.showAllEvents && <MobergToggle
                    checked={eventReviewProvider.showIncompleteEventsOnly}
                    onChange={() => eventReviewProvider.setShowIncompleteEventsOnly(prev => !prev)}
                    color="primary"
                    label="Only show incomplete events"
                    labelPlacement="end"
                />}
                
                <MobergToggle
                    checked={eventReviewProvider.hideAnnotations}
                    onChange={() => eventReviewProvider.setHideAnnotations(prev => !prev)}
                    color="primary"
                    label="Hide annotations"
                    labelPlacement="end"
                />
            </MobergRow>

            <MobergRow expand={false}>
                { EBOOSTEventReviewProvider.saveReviewTask.isWaiting && <span style={{ fontSize: MobergFontSize.SMALL }}>Saving...</span> }
                { errorMessage && (
                    <MdOutlineErrorOutline 
                        size={MobergIconSize.REGULAR}
                        title={errorMessage}
                        color={red}
                    /> 
                )}
                <MobergButton
                    onClick={renderConfigureModal}
                    shape={MobergButtonShape.SQUARE}
                    theme={MobergTheme.BLUE}
                    tooltip={"Edit window settings"}
                >
                    <MdModeEdit size={MobergIconSize.SMALL}/>
                </MobergButton>
            </MobergRow>
        </MobergRow>
    )
}