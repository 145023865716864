import React, { useContext, useEffect } from "react";
import { EventReviewJSON } from "../../../../Types/EventReview";
import { EventReviewSettings } from "./EventReviewSettings";
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider";
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts";
import { hotkeyActions } from "../../../../Types/KeyboardShortcut";
import { EventReviewMetadataWindow } from "./Metadata/EventReviewMetadata";
import { EventReviewFormsWindow } from "./Forms/EventReviewForm";
import { useEventReviewProvider } from "../../../../../../../../Providers/EventReviewProvider";
import { EBOOSTEventReviewProvider, useEBOOSTEventReviewProvider } from "../../../../../../../../Providers/EBOOSTEventReviewProvider";
import { MobergButton, MobergButtonVariant, MobergColumn, MobergRow, MobergTheme } from "../../../../../../../../Moberg";
import { useModalProvider } from "../../../../../../../../Providers/ModalProvider";
import { AnnotationGroupLoader } from "../../../Modals/AnnotationGroupLoader/AnnotationGroupLoader";
import { EventReviewNextPrevious } from "./EventReviewNextPrevious";
import { useOnMount } from "../../../../../../../../Hooks/useOnMount";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { eventReviewConfigsAtom } from "../../../../Atoms/EventReview";
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout";
import { annotationsAtom } from "../../../../Atoms/Annotations";
import { ValidationProvider } from "../../../../../../../../Providers/ValidationProvider";

type EventReviewProps = {
    windowId: string,
    json: EventReviewJSON
}

export enum QuestionResponseType {
    SINGLE_SELECT = "single-select",
    NUMBER = "number",
    DATETIME = "datetime"
}

export type BaseQuestionResponse = {
    id: string
    type: QuestionResponseType
    label: string
    response?: any
}

export type AnnotationReviews = AnnotationReview[]

export type AnnotationReview = {
    id: number
    annotation_id: number
    review_type_id: number
    user_id: number
    user_email: string
    timestamp: number
    is_complete: boolean
    form: QuestionResponse[]
}

export type SingleSelectResponse = BaseQuestionResponse & {
    type: QuestionResponseType.SINGLE_SELECT
    options: string[]
    response?: string
}

export type NumberResponse = BaseQuestionResponse & {
    type: QuestionResponseType.NUMBER
    minimum: number
    maximum: number
    numDecimals: number
    response?: number
}

export type DateTimeResponse = BaseQuestionResponse & {
    type: QuestionResponseType.DATETIME
    response?: number
}

export type QuestionResponse = SingleSelectResponse | NumberResponse | DateTimeResponse

export const EventReview = (props: EventReviewProps) => {
    return (
        <ValidationProvider>
            <EBOOSTEventReviewProvider>
                <div style={{height: "100%", maxHeight: "100%", display: "flex", flexDirection: "column"}}>
                    <div style={{ flex: 0 }}>
                        <EventReviewSettings windowId={props.windowId}/>
                    </div>
                    <div style={{ flex: 1, minHeight: 0 }}>
                        <DimensionsProvider>
                            <EventReviewVisualization {...props}/>
                        </DimensionsProvider>
                    </div>
                </div>
            </EBOOSTEventReviewProvider>
        </ValidationProvider>
    )
}

const EventReviewVisualization = (props: EventReviewProps) => {
    const eventReviewProvider = useEventReviewProvider()
    const EBOOSTEventReviewProvider = useEBOOSTEventReviewProvider()
    const annotations = useRecoilValue(annotationsAtom)
    const { width, height } = useContext(DimensionsContext)
    const { createModal } = useModalProvider()
    const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
    const setEventReviewConfig = useSetRecoilState(eventReviewConfigsAtom(selectedLayoutId ?? ""))

    const overrideShortcutsMap: Map<string, Function> = new Map([
        [hotkeyActions.NEXT_PAGE, EBOOSTEventReviewProvider.goToNextAnnotation], 
        [hotkeyActions.PREVIOUS_PAGE, EBOOSTEventReviewProvider.goToPreviousAnnotation]
    ])

    // Initializes the state of the atom from the JSON provided from the backend
    useOnMount(() => {
        setEventReviewConfig(props.json)
    })

    useD3KeyboardShortcuts({
        windowId: props.windowId,
        shortcuts: [hotkeyActions.NEXT_PAGE, hotkeyActions.PREVIOUS_PAGE],
        overrideShortcuts: overrideShortcutsMap
    })
    
    useEffect(() => {
        // When the component re-renders, check that there is a current annotation review.
        // If there is not, find the first incomplete event and jump to it. 
        // Update the annotation review with the incomplete annotation review, or a new one if it does not exist.
        if (eventReviewProvider.currentAnnotationReview && eventReviewProvider.currentAnnotation) {
            return
        }

        const incompleteEvents = EBOOSTEventReviewProvider.getIncompleteEvents()

        if (incompleteEvents.length > 0) {
            EBOOSTEventReviewProvider.navigateToAnnotation(incompleteEvents[0])
        } else if (eventReviewProvider.sortedAnnotations.length > 0) {
            EBOOSTEventReviewProvider.navigateToAnnotation(eventReviewProvider.sortedAnnotations[0])
        }
    }, [EBOOSTEventReviewProvider, eventReviewProvider])

    // When the current annotation changes, update the current annotation review
    useEffect(() => {
        const annotation = eventReviewProvider.currentAnnotation

        if (!annotation || eventReviewProvider.currentAnnotationReview?.annotation_id === annotation.id) {
            return // nothing to update
        }

        const annotationReview = eventReviewProvider.annotationReviews.find(review => review.annotation_id === annotation.id)

        // If the new event has an existing annotation review, then we load that review.
        if (annotationReview) {
            eventReviewProvider.updateCurrentAnnotationReview(annotationReview)
        } else { 
            // We reset the state of the current annotation review to a default state.
            const newReview = EBOOSTEventReviewProvider.createDefaultAnnotationReview()
            newReview.annotation_id = annotation.id
            eventReviewProvider.setAnnotationReviews(previous => [...previous, newReview])
            eventReviewProvider.updateCurrentAnnotationReview(newReview)
        }
    }, [EBOOSTEventReviewProvider, eventReviewProvider])

    if (width < 900 || height < 125) {
        return (
            <MobergColumn verticalAlign="center" expand={true}>
                <MobergRow horizontalAlign="center">
                    <MobergColumn gap="16px" horizontalAlign="center">
                        <h3 style={{ margin: 0 }}>Window is too small to review events</h3>
                    </MobergColumn>
                </MobergRow>
            </MobergColumn>
        )
    }

    if (eventReviewProvider.isRequestingReviews) {
        return (
            <MobergColumn verticalAlign="center" expand={true}>
                <MobergRow horizontalAlign="center">
                    <MobergColumn gap="16px" horizontalAlign="center">
                        <h3 style={{ margin: 0 }}>Loading reviews...</h3>
                    </MobergColumn>
                </MobergRow>
            </MobergColumn>
        )
    }

    if (eventReviewProvider.totalEvents === 0) {
        return (
            <MobergColumn verticalAlign="center" expand={true}>
                <MobergRow horizontalAlign="center">
                    <MobergColumn gap="16px" horizontalAlign="center">
                        <h3 style={{ margin: 0 }}>There are no events to review.</h3>
                        
                        {annotations.length === 0 
                            ? (
                                <MobergButton 
                                    theme={MobergTheme.BLUE} 
                                    variant={MobergButtonVariant.FILLED} 
                                    onClick={() => createModal(<AnnotationGroupLoader style={{ height: "80vh" }} />)}>
                                    Load events
                                </MobergButton>
                                )
                            : (
                                <span>(all events are being filtered out)</span>
                            )
                        }
                    </MobergColumn>
                </MobergRow>
            </MobergColumn>
        )
    }

    return (
        <div style={{ position: "absolute", width, overflowX: "hidden" }}>
            <MobergColumn verticalAlign="center">
                <MobergRow horizontalAlign="center" verticalAlign="start" gap={"10px"}>
                    <EventReviewMetadataWindow />
                    <EventReviewFormsWindow />
                    <EventReviewNextPrevious />
                </MobergRow>
            </MobergColumn>
        </div>
    )
}