import { Socket } from "socket.io-client";
import { ModalityDataSource } from "../Types/ModalityDataSource";
import { PageType } from "../Types/PageType";
import { ModalityPageProperties, Page } from "./Page";
import { TimeSeriesData } from "./TimeSeriesData";

type ManualDownsampledPageProperties = ModalityPageProperties & {
    resamplingPeriodSeconds: number
}

export class ManualDownsampledPage extends Page<TimeSeriesData, ManualDownsampledPageProperties> {
    private resamplingPeriodSeconds: number = 0

    getType(): PageType {
        return PageType.MANUAL_DOWNSAMPLE
    }

    socketEventName = () => "render_modalities_with_sampling"

    requestData(batchedDataSources: Map<number, ModalityDataSource[]>, socket: Socket) {
		if (this.getDataQuerySocket === null) {
            return
        }
        
		batchedDataSources.forEach((modalities, dataObjectId) => {
			socket.emit(this.socketEventName(), this.patientId, dataObjectId, this.id, modalities, this.startTime, this.endTime, this.resamplingPeriodSeconds, this.patientIsAdmitted)
		})
	}
}