import React, { useEffect, useState } from "react"
import { MobergButton, MobergButtonShape, MobergColumn, MobergIconSize, MobergRow, MobergShortTextInput, MobergTheme } from "../../../../../../../../Moberg"
import { MdClose } from "react-icons/md"
import { Scrollbar } from "../../../../../../../../Constants/StyledComponents"

type Tag = {
    id: string
    value: string
}

type TagFilterProps = {
    defaultTags: string[]
    onChange: (tags: string[]) => void
}

export const TagFilter: React.FC<TagFilterProps> = ({ defaultTags, onChange }) => {
    const [tags, setTags] = useState<Tag[]>(defaultTags?.map((t, index) => ({ id: index.toString(), value: t })) ?? [])
    const [inputValue, setInputValue] = useState<string>("")

    function addTag() {
        if (inputValue === "") {
            return
        }

        setTags(previous => [...previous, { id: new Date(Date.now()).toISOString(), value: inputValue }])
        setInputValue("")
    }

    useEffect(() => {
        onChange(tags.map(t => t.value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags])

    return (
        <MobergColumn gap="16px">
            <MobergRow gap="16px">
                <MobergShortTextInput
                    value={inputValue}
                    placeholder="Enter a tag"
                    onChange={setInputValue}
                    onSubmit={addTag}
                    limit={30}
                />

                <MobergRow horizontalAlign="space-between">
                    <MobergButton
                        theme={MobergTheme.BLUE}
                        onClick={addTag}
                        disabled={inputValue === ""}
                    >
                        Add
                    </MobergButton>

                    <MobergButton onClick={() => setTags([])} disabled={tags.length === 0}>
                        Clear all
                    </MobergButton>
                </MobergRow>
            </MobergRow>

            <MobergRow>
                <MobergColumn style={{ maxHeight: "150px" }}>
                    <Scrollbar style={{ height: "100%" }}>
                        {tags.map(tag => (
                            <MobergRow 
                                key={tag.id} 
                                horizontalAlign="space-between" 
                                expand={false} 
                                style={{ width: "325px", padding: "4px", overflow: "hidden", borderTop: "1px solid lightgray" }}
                            >
                                <span>{tag.value}</span>

                                <MobergButton
                                    shape={MobergButtonShape.SQUARE}
                                    style={{ padding: "4px" }}
                                    onClick={() => setTags(previous => previous.filter(t => t.id !== tag.id))}>
                                    <MdClose size={MobergIconSize.REGULAR} />
                                </MobergButton>
                            </MobergRow>
                        ))}
                    </Scrollbar>
                </MobergColumn>
            </MobergRow>
        </MobergColumn>
    )
}