import { GraphType } from "../../../../../../../../Enums/GraphType"
import { D3AnnotationsConfig } from "../../../D3/D3Annotations"
import { D3ClipPathConfig } from "../../../D3/D3ClipPath"
import { D3UTCAxisConfig } from "../../../D3/D3UTCAxis"
import { D3TimelineConfig } from "../../../D3/Timeline/D3Timeline"
import { ConfigurationBuilder } from "../../D3ConfigurationBuilder"
import { D3AnnotationReviewIndicatorConfig } from "../../EventReview/D3/D3AnnotationReviewIndicator"
import { D3GraphsOverlay, D3GraphsOverlayConfig } from "./D3GraphsOverlay"
import { ModalityGraphsWrapperConfig } from "./D3GraphsWrapper"
import { D3ModalityGraphGroup } from "./D3ModalityGraphGroup"

export class D3ModalityGraphGroupConfigurationBuilder extends ConfigurationBuilder<D3ModalityGraphGroup> {
	getUTCAxisConfig = (): D3UTCAxisConfig => {
		return {
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			viewScale: this.visualization.config.viewScale,
			fileScale: this.visualization.config.fileScale,
			onDragStart: this.visualization.onTimeAxisDragStart,
			onDrag: this.visualization.onTimeAxisDrag,
			onDragEnd: this.visualization.clearDataAndReload,
		}
	}

	getOverlayConfig = (svg: SVGSVGElement | null): D3GraphsOverlayConfig => {
		return {
			viewScale: this.visualization.config.viewScale,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			boundingBox: this.visualization.overlayBoundingBox,
			svgOffsetX: this.visualization.graphsBoundingBox.x,
			svg,
			inProgressAnnotation: this.visualization.config.inProgressAnnotation,
			clipPathId: this.getOverlayClipPathId(),
		}
	}

	getTimelineConfig = (): D3TimelineConfig => {
		const timelineUpdateTimes = (start: number, end: number) => {
			this.visualization.viewTimesChanged(start, end)
			this.visualization.updateLinkedWindows()
		}

		return {
			id: this.visualization.config.id,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			viewScale: this.visualization.config.viewScale,
			fileScale: this.visualization.config.fileScale,
			annotations: this.visualization.config.annotations,
			width: this.visualization.graphsBoundingBox.width,
			playbackSpeed: this.visualization.config.playbackSpeed,
			currentTimelineController: this.visualization.config.timelineController,
			isLinked: this.visualization.config.isLinked,
			timeZone: this.visualization.config.timeZone,
			updateViewTimes: timelineUpdateTimes,
			goToStart: this.visualization.goToStart,
			goToEnd: this.visualization.goToEnd,
			goToNextPage: this.visualization.goToNextPage,
			goToPreviousPage: this.visualization.goToPreviousPage,
			onDrag: this.visualization.onTimelineSliderDrag,
			onDragEnd: this.visualization.onTimelineSliderDragEnd,
		}
	}

	getAnnotationsConfig = (): D3AnnotationsConfig => {
		return {
			type: GraphType.MODALITY_GRAPH,
			overlay: this.visualization.getOverlay() as D3GraphsOverlay,
			annotations: this.visualization.config.hideAnnotations ? [] : this.visualization.config.annotations,
			clipPathId: this.getGraphsClipPathId(),
			viewScale: this.visualization.config.viewScale,
			boundingBox: this.visualization.overlayBoundingBox,
			graphs: this.visualization.graphs,
			canOpenAnnotationsModal: this.visualization.config.enableOpeningAnnotationsModal
		}
	}

	getCurrentAnnotationReviewIndicator = (): D3AnnotationReviewIndicatorConfig => {
		return {
			viewScale: this.visualization.config.viewScale,
			startTime: this.visualization.config.currentlyReviewedAnnotation?.start_time,
			endTime: this.visualization.config.currentlyReviewedAnnotation?.end_time,
			clipPath: this.getGraphsClipPathId()
		}
	}

	getGraphsConfig = (): ModalityGraphsWrapperConfig => {
		return {
			graphs: this.visualization.graphs,
		}
	}

	getGraphsClipPathConfig = (): D3ClipPathConfig => {
		return {
			id: this.getGraphsClipPathId(),
			boundingBox: this.visualization.graphsBoundingBox,
		}
	}

	getOverlayClipPathConfig = (): D3ClipPathConfig => {
		return {
			id: this.getOverlayClipPathId(),
			boundingBox: this.visualization.overlayBoundingBox,
		}
	}

	getGraphsClipPathId = () => `d3-graphs-clip-path-${this.visualization.config.id}`

	getOverlayClipPathId = () => `d3-overlay-clip-path-${this.visualization.config.id}`
}
