import React, { useEffect, useState } from "react"
import { Analytic, GenericOnDemandAnalysis, getAnalyticDisplayName, OnDemandAnalysis } from "../../../Types/AnalysisDetails"
import { MobergColumn, MobergDropdown, MobergInputLabel, MobergNumberInput, MobergRow } from "../../../../../../../Moberg"
import { DurationUnit, fromMilliseconds, getDurationUnitRange, toMilliseconds, useDuration } from "../../../Hooks/useDuration"

type AnalysisBuilderProps = {
    initialState: OnDemandAnalysis | undefined
    rawDataKey: string
    onChange: (analysis: GenericOnDemandAnalysis | undefined) => void
}

type AnalysisBuilderOption = Analytic | undefined

export const AnalysisBuilder: React.FC<AnalysisBuilderProps> = ({ initialState, rawDataKey, onChange }) => {
    const [selectedAnalytic, setSelectedAnalytic] = useState(initialState?.analytic)
    const [calculationWindow, updateCalculationWindow] = useDuration(fromMilliseconds(initialState?.calculationWindowMs ?? 60000, getDurationUnitRange(DurationUnit.SECONDS, DurationUnit.HOURS)))
	const [calculationPeriod, updateCalculationPeriod] = useDuration(fromMilliseconds(initialState?.calculationPeriodMs ?? 60000, getDurationUnitRange(DurationUnit.SECONDS, DurationUnit.HOURS)))
	const [validationErrors, setValidationErrors] = useState(new Map<string, string | undefined>())

    const formIsInvalid = Object.values(validationErrors).filter(err => err !== undefined).length > 0 || isNaN(calculationPeriod.value) || isNaN(calculationWindow.value)

    const genericAnalysisOptions: AnalysisBuilderOption[] = [
        undefined, // No post-processing
        Analytic.ROLLING_AVERAGE
    ]

    const validateDurationValue = (value: number, units: DurationUnit): string | undefined => {
		if (value <= 0) {
			return "The duration must be greater than 0."
		}

        const valueInMilliseconds = toMilliseconds(value, units)

		if (valueInMilliseconds < 1000) {
			return "The duration cannot be less than 1 second."
		}

        if (valueInMilliseconds > 24 * 60 * 60 * 1000) {
            return `The duration must be less than 1 day.`
        }

		return undefined // no validation error
	}

	const updateValidationError = (key: string, error: string | undefined) => {
		setValidationErrors(previous => {
			const next = new Map(previous)
			next.set(key, error)
			return next
		})
	}
    
    useEffect(() => {
		if (formIsInvalid) {
			return
		}

        if (selectedAnalytic) {
            onChange({
                analytic: selectedAnalytic,
                rawDataKey,
                calculationPeriodMs: calculationPeriod.milliseconds,
                calculationWindowMs: calculationWindow.milliseconds
            })
        } else {
            onChange(undefined)
        }
	})
    
    return (
        <MobergColumn gap="16px">
            <MobergDropdown 
                label={"Post processing:"}
                selectedValue={selectedAnalytic}
                options={genericAnalysisOptions.map(analytic => (
                    { 
                        label: analytic ? getAnalyticDisplayName(analytic) : "No post processing", 
                        value: analytic 
                    }
                ))}
                onChange={(analytic: AnalysisBuilderOption) => {
                    setSelectedAnalytic(analytic)
                }}
            />

            {selectedAnalytic && (<>
                <MobergColumn gap="8px">
                    <MobergInputLabel text={"Calculation window"} />
                    <MobergRow gap="16px">
                        <div style={{ width: "120px" }}>
                            <MobergNumberInput
                                defaultValue={calculationWindow.value}
                                value={calculationWindow.value}
                                onChange={newValue => updateCalculationWindow(newValue ?? 1, calculationWindow.units)}
                                validationFunction={value => validateDurationValue(value, calculationWindow.units)}
                                onValidationErrorChange={error => updateValidationError("calculation window", error)}
                                style={{ width: "112px" }}
                            />
                        </div>

                        <MobergDropdown
                            options={getDurationUnitRange(DurationUnit.SECONDS, DurationUnit.HOURS).map((unit: DurationUnit) => ({ label: unit, value: unit }))}
                            onChange={newUnits => updateCalculationWindow(calculationWindow.value, newUnits)}
                            selectedValue={calculationWindow.units}
                        />
                    </MobergRow>
                </MobergColumn>

                <MobergColumn gap="8px">
                    <MobergInputLabel text={"Calculate every"} />
                    <MobergRow gap="16px">
                        <div style={{ width: "120px" }}>
                            <MobergNumberInput
                                defaultValue={calculationPeriod.value}
                                value={calculationPeriod.value}
                                onChange={newValue => updateCalculationPeriod(newValue ?? 1, calculationPeriod.units)}
                                validationFunction={value => validateDurationValue(value, calculationPeriod.units)}
                                onValidationErrorChange={error => updateValidationError("calculation period", error)}
                                style={{ width: "112px" }}
                            />
                        </div>

                        <MobergDropdown
                            options={getDurationUnitRange(DurationUnit.SECONDS, DurationUnit.HOURS).map((unit: DurationUnit) => ({ label: unit, value: unit }))}
                            onChange={newUnits => updateCalculationPeriod(calculationPeriod.value, newUnits)}
                            selectedValue={calculationPeriod.units}
                        />
                    </MobergRow>
                </MobergColumn>
            </>)}
        </MobergColumn>
    )
}
