import React from "react";
import { BaseVisualizationConfigureModal } from "../../../Modals/BaseVisualizationConfigureModal";
import { eventReviewConfigsAtom } from "../../../../Atoms/EventReview";
import { useEditWindow } from "../../../Modals/useEditWindow";
import { EventReviewJSON } from "../../../../Types/EventReview";
import { MobergColumn, MobergDropdown, MobergRow } from "../../../../../../../../Moberg";
import { TagFilter } from "./TagFilter";

type EventReviewConfigureModalProps = {
	windowId: string,
	layoutId: string
}

export const ConfigureEventReviewModal = (props: EventReviewConfigureModalProps) => {
	const atom = eventReviewConfigsAtom(props.layoutId)
	const { editedConfig, saveChanges, updateProperty } = useEditWindow<EventReviewJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })
	const EVENT_TYPES = ['EEG']

	return (
		<BaseVisualizationConfigureModal
			layoutId={props.layoutId}
			windowId={props.windowId}
			title={"Configure Event Review"}
			saveChanges={saveChanges}
		>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: "20px", width: "700px" }}>
					<MobergColumn gap="32px" style={{ whiteSpace: "nowrap" }}>
						<MobergRow expand={false} gap="16px">
							Review Type
							<MobergDropdown
								selectedValue={editedConfig?.eventType}
								onChange={value => updateProperty("eventType", value)}
								options={EVENT_TYPES.map(type => ({ label: type, value: type }))}
							/>
						</MobergRow>

						<MobergColumn>
							<span style={{ padding: "8px 0px" }}>
								Event is tagged with:
							</span>
							<TagFilter
								defaultTags={editedConfig.includeTags}
								onChange={tags => updateProperty("includeTags", tags)} />
						</MobergColumn>

						<MobergColumn>
							<span style={{ padding: "8px 0px" }}>
								Event is NOT tagged with:
							</span>
							<TagFilter
								defaultTags={editedConfig.excludeTags}
								onChange={tags => updateProperty("excludeTags", tags)} />
						</MobergColumn>
					</MobergColumn>
				</div>
			</div>
		</BaseVisualizationConfigureModal>
	)
}