import { atomFamily } from "recoil";

export type EventReviewConfig = {
    includeTags: string[]
    excludeTags: string[]
}

// Only one event reviewer should be present on a screen at a time
// Use the display ID to uniquely identify the event reviewer.
export const eventReviewConfigsAtom = atomFamily<EventReviewConfig, string>({
    key: "eventReviewConfigs",
    default: {
        includeTags: [],
        excludeTags: []
    }
})
