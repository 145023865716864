import { utcHour, utcMinute, utcSecond } from "d3"
import { D3EEGMontage } from "./D3EEGMontage"
import { EEGMontageGraph } from "../../../../Types/MontageGraph"
import { D3GraphsOverlay, D3GraphsOverlayConfig } from "../../TimeSeriesGraphGroup/D3/D3GraphsOverlay"
import { ConfigurationBuilder } from "../../D3ConfigurationBuilder"
import { D3TimelineConfig } from "../../../D3/Timeline/D3Timeline"
import { D3ClipPathConfig } from "../../../D3/D3ClipPath"
import { D3UTCAxisConfig } from "../../../D3/D3UTCAxis"
import { D3EEGYAxisConfig } from "./D3BandScaleAxis"
import { GraphType } from "../../../../../../../../Enums/GraphType"
import { RenderStrategy } from "../../../../Types/Trace"
import { EEGCanvasConfig } from "./D3EEGCanvas"
import { DataSource } from "../../../../Types/DataSource"
import { D3AnnotationsConfig } from "../../../D3/D3Annotations"
import { D3AnnotationReviewIndicatorConfig } from "../../EventReview/D3/D3AnnotationReviewIndicator"

export class EEGMontageConfigurationBuilder extends ConfigurationBuilder<D3EEGMontage>{

	getXAxisConfig = (): D3UTCAxisConfig => {
		return {
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			viewScale: this.visualization.config.viewScale,
			fileScale: this.visualization.config.fileScale,
		}
	}

	getYAxisConfig = (): D3EEGYAxisConfig => ({
		scale: this.visualization.channelScale,
		tickValues: this.visualization.eegConfig.channelLabels
	})

	getOverlayConfig = (svg: SVGSVGElement): D3GraphsOverlayConfig => {
		return {
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			boundingBox: this.visualization.overlayBoundingBox,
			svg,
			svgOffsetX: this.visualization.graphBoundingBox.x,
			viewScale: this.visualization.config.viewScale,
			clipPathId: this.getOverlayClipPathId(),
			inProgressAnnotation: this.visualization.config.inProgressAnnotation,
		}
	}

	private timelineUpdateTimes = (start: number, end: number) => {
		this.visualization.viewTimesChanged(start, end)
		this.visualization.updateLinkedWindows()
	}

	getTimelineConfig = (): D3TimelineConfig => {
		return {
			id: this.visualization.config.id,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			viewScale: this.visualization.config.viewScale,
			width: this.visualization.graphBoundingBox.width,
			annotations: this.visualization.config.annotations,
			fileScale: this.visualization.config.fileScale,
			playbackSpeed: this.visualization.config.playbackSpeed,
			currentTimelineController: this.visualization.config.timelineController,
			isLinked: this.visualization.config.isLinked,
			timeZone: this.visualization.config.timeZone,
			onDrag: this.visualization.onTimelineSliderDrag,
			onDragEnd: this.visualization.onTimelineSliderDragEnd,
			updateViewTimes: this.timelineUpdateTimes,
			goToStart: this.visualization.goToStart,
			goToEnd: this.visualization.goToEnd,
			goToNextPage: this.visualization.goToNextPage,
			goToPreviousPage: this.visualization.goToPreviousPage,
		}
	}

	getAnnotationsConfig = (): D3AnnotationsConfig => {
		const graphs: EEGMontageGraph[] = [
			{
				id: this.visualization.config.id,
				name: "EEG Montage",
				offset: 0,
				height: this.visualization.graphBoundingBox.height,
				width: this.visualization.graphBoundingBox.width,
				channels: this.visualization.eegConfig.leads,
				channelPairs: this.visualization.eegConfig.channelPairs,
				xScale: this.visualization.config.viewScale,
				renderStrategy: RenderStrategy.LINE
			},
		]

		return {
			type: GraphType.EEG_MONTAGE,
			overlay: this.visualization.getOverlay() as D3GraphsOverlay,
			annotations: this.visualization.config.hideAnnotations ? [] : this.visualization.config.annotations,
			clipPathId: this.getGraphClipPathId(),
			viewScale: this.visualization.config.viewScale,
			boundingBox: this.visualization.overlayBoundingBox,
			graphs,
			canOpenAnnotationsModal: this.visualization.config.enableOpeningAnnotationsModal
		}
	}

	getCurrentAnnotationReviewIndicator = (): D3AnnotationReviewIndicatorConfig => {
		return {
			viewScale: this.visualization.config.viewScale,
			startTime: this.visualization.config.currentlyReviewedAnnotation?.start_time,
			endTime: this.visualization.config.currentlyReviewedAnnotation?.end_time,
			clipPath: this.getGraphClipPathId()
		}
	}

	getEEGCanvasConfig = (): EEGCanvasConfig => {
		return {
			dataObjectId: this.visualization.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) ?? Infinity,
			graphId: this.visualization.config.id,
			viewScale: this.visualization.config.viewScale,
			channelScale: this.visualization.channelScale,
			sensitivityScaleVoltsToPixels: this.visualization.sensitivityScaleVoltsToPixels,
			eegModality: this.visualization.eegConfig.eegModality,
		}
	}

	getVerticalLinesConfig = () => {
		const { viewDuration } = this.visualization.getStartTimeEndTimeViewDuration()

		let tickInterval

		if (viewDuration <= 1 * 60 * 1000) {
			tickInterval = utcSecond
		} else if (viewDuration <= 10 * 60 * 1000) {
			tickInterval = utcMinute
		} else {
			tickInterval = utcHour
		}

		return {
			xScale: this.visualization.config.viewScale,
			height: this.visualization.graphBoundingBox.height,
			color: "gray",
			opacity: 0.5,
			tickInterval: tickInterval,
		}
	}

	getGraphClipPathConfig = (): D3ClipPathConfig => {
		return {
			id: this.getGraphClipPathId(),
			boundingBox: this.visualization.graphBoundingBox
		}
	}

	getOverlayClipPathConfig = (): D3ClipPathConfig => {
		return {
			id: this.getOverlayClipPathId(),
			boundingBox: this.visualization.overlayBoundingBox
		}
	} 

	getGraphClipPathId = () => `d3-eeg-montage-graph-clip-path-${this.visualization.config.id}`

	getOverlayClipPathId = () => `d3-eeg-montage-overlay-clip-path-${this.visualization.config.id}`
}
