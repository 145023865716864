export let IICPatternTypeQuestions = [
    {
        groupLabel: "IIC Pattern Type",
        prompt: "Select predominant pattern",
        type: "checkbox",
        required: true,
        default: null,
        questionType: "predominantPattern",
        multiple: false,
        
    }
]

export const formQuestionTabs = {
    IIC_PATTERN_TYPE: "IIC Pattern Type",
    IIC_PATTERN_FEATURES: "IIC Pattern Features"
}