import { drag, Selection } from "d3"
import { D3TimeBasedVisualization } from "./D3TimeBasedVisualization"
import { D3GraphsOverlay } from "./TimeSeriesGraphGroup/D3/D3GraphsOverlay"
export function attachD3WheelAndDragListeners(selection: Selection<any, any, any, any>, visualization: D3TimeBasedVisualization<any, any, any, any>, overlay: D3GraphsOverlay) {
    // Attach listeners to the SVG to handle scroll and drag events.
    // By attaching the listeners to the SVG and forwarding the event to the appropriate child components, 
    // we can avoid the issue of Annotations capturing scroll and drag events just because they are on top.
    selection
        .on("wheel", visualization.onWheel)
        .call(drag<any, any, any>().on("drag", (event) => { 
            overlay.onDrag(event)
            visualization.onPan(event)
        }))
}
