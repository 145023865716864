import React, { useCallback, useContext, useMemo } from "react";
import { MobergColumn, MobergFontFamily, MobergFontSize } from "../../../../../../../../../Moberg";
import { useEventReviewProvider } from "../../../../../../../../../Providers/EventReviewProvider";
import { useEBOOSTEventReviewProvider } from "../../../../../../../../../Providers/EBOOSTEventReviewProvider";
import { DimensionsContext } from "../../../../../../../../../Providers/DimensionsProvider";
import { DurationUnit, fromMilliseconds, getFormattedDuration } from "../../../../../Hooks/useDuration";
import { PaginationType } from "../../../../../../../../../Components/MUITable/MUITableTypes";
import { useRecoilValue } from "recoil";
import { currentPatientFileInfoAtom } from "../../../../../Atoms/PatientFile";
import MUITable from "../../../../../../../../../Components/MUITable/MUITable";

interface SeeAllEventsWindowProps {
    style?: React.CSSProperties
}

type AnnotationTableRow = {
	id: number
	start_time: number
	duration: string
}

export const EventReviewSeeAllEventsWindow: React.FC<SeeAllEventsWindowProps> = () => {
    const eventReviewProvider = useEventReviewProvider()
    const EBOOSTEventReviewProvider = useEBOOSTEventReviewProvider()
    const { height } = useContext(DimensionsContext) 
    const sortedAnnotations = eventReviewProvider.sortedAnnotations
    const tableAnnotations = eventReviewProvider.showIncompleteEventsOnly ? EBOOSTEventReviewProvider.getIncompleteEvents() : sortedAnnotations
    const patientInfo = useRecoilValue(currentPatientFileInfoAtom)
    
    const dateFormatter = useMemo(() => {
        const options: Intl.DateTimeFormatOptions & { fractionalSecondDigits: number, hourCycle: string } = {
            timeZone: patientInfo.timeZone,
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            fractionalSecondDigits: 3,
            hourCycle: "h23"
        }
    
        return new Intl.DateTimeFormat("en-us", options)
    }, [patientInfo.timeZone])

    const handleSelectedRow = useCallback((selectedRow: AnnotationTableRow) => {
        const annotation = tableAnnotations[selectedRow.id]

        if (!annotation) {
            return
        }

        EBOOSTEventReviewProvider.navigateToAnnotation(annotation)
    }, [EBOOSTEventReviewProvider, tableAnnotations])

    const annotationTableColumns = useMemo(() => [
        {
            field: "time", flex: 0.6, headerName: "Time", visible: true, 
            renderCell: (params: any) => {
                const [monthAndDay, year, time] = dateFormatter.format(params.row.time).split(", ")
                return (
                    <MobergColumn style={{ fontSize: MobergFontSize.SMALL }}>
                        <div>{monthAndDay}, {year} {time}</div>
                    </MobergColumn>
                )
            },
        },
        {
            field: "name", flex: 0.8, headerName: "Name", minWidth: 100, visible: true, renderCell: (params: any) => {
                return (
                    <span onMouseDown={() => handleSelectedRow(params.row)} style={{ color: "#207dea", cursor: "pointer", fontWeight: "bold", textDecoration: "underline" }}>
                        {params.row.name}
                    </span>
                )
            },
        },
        {
            field: "duration", flex: 0.3, headerName: "Duration", visible: true, renderCell: (params: any) => {
                const duration = fromMilliseconds(params.row.duration, [DurationUnit.SECONDS, DurationUnit.MINUTES])
                return (<span>{getFormattedDuration(duration, { abbreviation: true })}</span>)
            },
        },
        {
            field: "complete", flex: 0.3, headerName: "Complete?", visible: true, renderCell: (params: any) => {
                return (<span>{params.row.complete ? "Yes" : "No"}</span>)
            },
        }
    ], [dateFormatter, handleSelectedRow])

    const annotationTableData = tableAnnotations.map(annotation => ({
        name: annotation.text,
        time: annotation.start_time,
        duration: annotation.end_time - annotation.start_time,
        complete: EBOOSTEventReviewProvider.reviewedEventIds.has(annotation.id)
    }))

    const tableLineHeight = 18
    const headerSpacing = 4

    return (
        <MobergColumn verticalAlign="start" style={{ fontFamily: MobergFontFamily.REGULAR, padding: "4px", minWidth: "600px", height: height, borderRight: "1px solid lightgray" }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", fontWeight: "bold", columnGap: "25px", width: "100%" }}>
                <span>Name</span>
                <span>Start time</span>
                <span>Duration</span>
                <span>Complete?</span>
            </div>

            <MUITable
                tableData={annotationTableData}
                tableToolbarProps={{ searchInput: { isShow: false }} }
                columns={annotationTableColumns}
                isCheckboxSelection={false}
                isRowSelectable={() => false}
                paginationType={PaginationType.COMPACT}
                handleOnRowClick={handleSelectedRow}
                autoPageSizeProps={{
                    enabled: true,
                    height: height - 4,
                    lineHeight: tableLineHeight,
                    headerHeight: tableLineHeight + headerSpacing,
                    paginationHeight: 52
                }}
                style={{ 
                    padding: 0, 
                    background: "#fff", 
                    position: "absolute",
                    width: "575px",
                    height: height - 4,
                }}
                overrideStyles={{
                    "& .MuiDataGrid-columnHeaderTitleContainer": {
                        padding: "0px 4px",
                        height: `${tableLineHeight}px`, // Reduce height
                    },
                    "& .MuiDataGrid-columnHeaderDraggableContainer": {
                        height: `${tableLineHeight}px`, // Reduce height
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        height: `${tableLineHeight}px !important`, // Reduce header height
                        minHeight: `${tableLineHeight}px !important`,
                        maxHeight: `${tableLineHeight}px !important`,
                        lineHeight: `${tableLineHeight}px !important`,
                        marginBottom: `${headerSpacing}px`
                    },
                    "& .MuiDataGrid-columnHeader": {
                        padding: "0px !important",
                        margin: "0px !important",
                        height: `${tableLineHeight}px !important`
                    },
                    "& .MuiDataGrid-columnHeaderRow": {
                        padding: "0 !important",
                        height: `${tableLineHeight}px !important`
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        padding: "0 !important",
                        height: `${tableLineHeight}px !important`,
                        fontSize: MobergFontSize.SMALL
                    },
                    "& .MuiDataGrid-cell": {
                        padding: "0px 4px",
                        fontSize: MobergFontSize.SMALL,
                        minHeight: `${tableLineHeight}px !important`,
                        maxHeight: `${tableLineHeight}px !important`
                    },
                    "& .MuiDataGrid-row": {
                        minHeight: `${tableLineHeight}px !important`,
                        maxHeight: `${tableLineHeight}px !important`
                    },
                    "& .MuiDataGrid-footerContainer": {
                        margin: 0,
                        minHeight: 0,
                        fontSize: `${MobergFontSize.SMALL} !important`,
                    },
                    "& .MuiDataGrid-tablePagination-root": {
                        height: "10px",
                    },
                    "& .MuiInputBase-input": {
                        padding: "12px",
                    },
                }}
            />
        </MobergColumn>
    )
}