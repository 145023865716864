import React, { useState, useRef, CSSProperties } from "react"
import styled from "styled-components"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { MdKeyboardArrowDown } from "react-icons/md"
import { MobergAnimationCurve, MobergAnimationTiming, MobergAnimationTimingMs } from "../MobergAnimation/MobergAnimation"
import { MobergTheme, MobergThemeColors, getColors } from "../MobergThemes/MobergColors"
import { FloatingFocusManager, useDismiss, useFloating, useInteractions, useRole, shift, size, flip, autoUpdate, useTransitionStyles } from "@floating-ui/react"
import { isEqual } from "lodash"
import { MobergFontFamily, MobergFontSize } from "../MobergFont/MobergFont"

export type MobergDropdownValue = any

export enum MobergDropdownSize {
	SMALL = "small",
	REGULAR = "regular",
}

interface MobergDropdownOption {
	value: MobergDropdownValue
	label: string
	highlight?: boolean
}

interface MobergDropdownProps {
	options: MobergDropdownOption[]
	onChange: (value: MobergDropdownValue) => void
	selectedValue: MobergDropdownValue
	width?: number | string
	label?: string
	size?: MobergDropdownSize
	equals?: (a: any, b: any) => boolean
	disabled?: boolean
	style?: React.CSSProperties
}

type MobergDropdownButtonProps = {
	theme: MobergThemeColors
	size: MobergDropdownSize
}

type DropdownIconProps = {
	isOpen: boolean
}

type MobergDropdownMenuItemProps = {
	width: number
	height: number
}

type DropdownContainerProps = {
	size: MobergDropdownSize
}

export const MobergDropdown: React.FC<MobergDropdownProps> = ({ options, onChange, selectedValue, label, size: dropdownSize = MobergDropdownSize.REGULAR, width, equals, disabled, style }) => {
	const [isOpen, setIsOpen] = useState(false)
	const dropdownRef = useRef<HTMLDivElement>(null)

	// If the user provides an equals function, call that instead of the default equals functionality.
	const selectedLabel = options.find(option => equals ? equals(option.value, selectedValue) : isEqual(option.value, selectedValue))?.label

	const { refs, floatingStyles, context } = useFloating({
		strategy: "fixed",
		open: isOpen,
		onOpenChange: setIsOpen,
		middleware: [
			shift(),
			flip(),
			size({
				apply({ availableWidth, availableHeight, elements }) {
					Object.assign(elements.floating.style, {
						maxWidth: `${availableWidth}px`,
						maxHeight: `${availableHeight}px`,
					})
				},
			}),
		],
		whileElementsMounted: autoUpdate,
	})

	const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
		duration: MobergAnimationTimingMs.FAST,
	})

	const dismiss = useDismiss(context)
	const role = useRole(context)
	const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, role])

	const handleOptionClick = (option: MobergDropdownOption) => {
		setIsOpen(false)
		onChange(option.value)
	}

	// Menu item dimensions should match the dimensions of the dropdown box
	const referenceBoundingBox = refs.reference.current?.getBoundingClientRect()
	const menuItemDimensions = {
		height: Math.max(0, (referenceBoundingBox?.height ?? 0) - 2), // Subtract the border from the width
		width: Math.max(0, (referenceBoundingBox?.width ?? 0) - 2)
	}

	return (
		<MobergDropdownContainer ref={dropdownRef} size={dropdownSize} style={{ flex: width === '100%' ? 1 : '', pointerEvents: disabled ? 'none' : 'auto', opacity: disabled ? 0.7 : 1 , ...style}}>
			{label && (
				<span style={{
					fontFamily: MobergFontFamily.REGULAR,
					fontSize: MobergFontSize.REGULAR,
					fontWeight: "bold",
					whiteSpace: "nowrap"
				}}>
					{label}
				</span>
			)}

			<div style={{ position: "relative", display: 'flex', flex: 1 }}>
				<MobergDropdownButton
					ref={refs.setReference}
					theme={getColors(MobergTheme.BLACK)}
					size={dropdownSize ?? MobergDropdownSize.REGULAR}
					{...getReferenceProps()}
					onMouseDown={() => setIsOpen(previous => !previous)}
					style={{ width: width ?? "auto", ...getSpacingBySize(dropdownSize) }}
				>
					{selectedLabel ?? "Select an option..."}
					<DropdownIcon isOpen={isOpen}>
						<MdKeyboardArrowDown size={MobergIconSize.REGULAR} />
					</DropdownIcon>
				</MobergDropdownButton>
			</div>

			{isMounted && (
				<FloatingFocusManager context={context}>
					<MobergDropdownMenu ref={refs.setFloating} style={{ ...floatingStyles, ...transitionStyles }} {...getFloatingProps()}>
						{options.map(option => (
							<MobergDropdownMenuItem
								key={JSON.stringify(option.value)}
								width={menuItemDimensions.width}
								height={menuItemDimensions.height}
								theme={getColors(MobergTheme.BLACK)}
								onMouseDown={event => { event.stopPropagation(); handleOptionClick(option) }}
								style={{ fontWeight: option.highlight ? '800' : 'normal' }}
							>
								{option.label}
							</MobergDropdownMenuItem>
						))}
					</MobergDropdownMenu>
				</FloatingFocusManager>
			)}
		</MobergDropdownContainer>
	)
}

export const getSpacingBySize = (size?: MobergDropdownSize): CSSProperties => {
	switch (size) {
		case MobergDropdownSize.SMALL:
			return {
				padding: "6px",
				paddingLeft: "11px",
				paddingRight: "7px",
				gap: "11px",
			}
		case MobergDropdownSize.REGULAR:
		default:
			return {
				padding: "8px 16px",
				paddingRight: "8px",
				gap: "16px"
			}

	}
}

const DropdownIcon = styled.span<DropdownIconProps>`
	display: flex;
	align-items: center;
	transform: rotate(${props => props.isOpen ? "-180deg" : "0"});
	transform-origin: center;
`

const MobergDropdownContainer = styled.div<DropdownContainerProps>`
	display: flex;
	gap: ${({ size }) => size === MobergDropdownSize.SMALL ? "8px" : "16px"};
	align-items: center;
	font-family: ${MobergFontFamily.REGULAR};
	font-size: ${MobergFontSize.REGULAR};
`


const MobergDropdownMenuItem = styled.div<MobergDropdownMenuItemProps>`
	padding: 4px 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	white-space: nowrap;
	min-width: ${props => props.width}px;
	height: ${props => props.height}px;

	&:hover {
		color: white;
		background: #207dea;
	}

	&:active {
		color: white;
		background: #1966BE
	}

	&:first-child {
		border-radius 4px 4px 0 0;
	}

	&:last-child {
		border-radius 0 0 4px 4px;
	}
`

const MobergDropdownMenu = styled.div`
	z-index: 10001;
	color: #222;
	background: #fff;
	border-radius: 4px;
	border: 1px solid #cdcdcd;
	overflow: auto;
	overscroll-behavior: contain;
`

const MobergDropdownButton = styled.button<MobergDropdownButtonProps>`
	position: relative;
	background: #fff;
	color: #222;
	margin: 0;
	white-space: nowrap;
	border: 1px solid #cdcdcd;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
	border-radius: 6px;
	transition: all ${MobergAnimationTiming.FAST} ${MobergAnimationCurve.EASE_IN};

	&:hover {
		background: ${({ theme }) => theme.noFill.hover};
	}

	&:active {
		background: ${({ theme }) => theme.noFill.active};
	}
`
