import React from "react"
import { BaseVisualizationConfigureModal } from "./BaseVisualizationConfigureModal"
import { useModalitiesProvider, modalityUpdates } from "../../../../../../Providers/ModalitiesProvider"
import { ScatterPlotConfigsAtom } from "../../Atoms/ScatterPlot"
import { useEditWindow } from "./useEditWindow"
import { ScatterPlotJSON } from "../../Types/ScatterPlot"
import { useOnMount } from "../../../../../../Hooks/useOnMount"
import { ElementConfigureDiv } from "../../../../../../Constants/StyledComponents"
import { MobergDropdown, MobergRow } from "../../../../../../Moberg"
import { ScatterPlotPointShapes } from "../Visualizations/ScatterPlot/D3/D3ScatterPlotCanvas"
import { SCATTER_PLOT_SAMPLING_PRESETS, WINDOW_TIME_PRESETS } from "../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { useRecoilValue } from "recoil"
import { currentPatientFileInfoAtom } from "../../Atoms/PatientFile"
import { MobergNumberInput } from "../../../../../../Components/MobergInput/MobergNumberInput"

type ConfigureScatterPlotModalProps = {
	windowId: string
	layoutId: string
}

export const ConfigureScatterPlotModal = (props: ConfigureScatterPlotModalProps) => {
	const modalitiesProvider = useModalitiesProvider()
	const atom = ScatterPlotConfigsAtom({ windowId: props.windowId, layoutId: props.layoutId })
	const { editedConfig, saveChanges, updateProperty } = useEditWindow<ScatterPlotJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })
	const { patientModalities } = useRecoilValue(currentPatientFileInfoAtom)
	const config = useRecoilValue(atom)

	const sortedModalities = Object.keys(modalitiesProvider.modalities).sort()

	useOnMount(() => {
		modalitiesProvider.update(modalityUpdates.MODALITIES)
	})

	const mobergDropdownOptions = sortedModalities.filter(modality => !modality.startsWith('EEG')).map(modality => ({
		label: modality,
		value: modality,
		highlight: patientModalities.includes(modality)
	}))

	function validatePointSize(value: number) {
		if (value > 20) {
			return "Point size cannot be greater than 20"
		} else if (value < 1) {
			return "Point size cannot be less than 1"
		} else {
			return undefined
		}
	}

	function roundToTwoDecimals(value?: number) {
		return Math.round((value ?? 0) * 100) / 100
	}

	return (
		<BaseVisualizationConfigureModal
			layoutId={props.layoutId}
			windowId={props.windowId}
			title={"Configure Scatter Plot"}
			saveChanges={saveChanges}
		>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: "20px", width: "400px" }}>
					<ElementConfigureDiv style={{ alignItems: 'center' }}>
						Initial Page Size

						<MobergDropdown
							selectedValue={editedConfig?.viewDuration}
							onChange={value => updateProperty("viewDuration", parseInt(value as string))}
							options={WINDOW_TIME_PRESETS.filter(preset => preset.label !== "Page Size").map(preset => ({ label: preset.label, value: preset.time }))}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv style={{ alignItems: 'center' }}>
						Resample

						<MobergDropdown
							selectedValue={editedConfig?.resamplingPeriodSeconds}
							onChange={value => updateProperty("resamplingPeriodSeconds", parseInt(value))}
							options={SCATTER_PLOT_SAMPLING_PRESETS.filter(preset => preset.label !== "Page Size").map(preset => ({ label: preset.label, value: preset.time }))}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv>
						X-Axis range

						<MobergRow style={{ display: 'flex', gap: '5px', width: 'min-content', minWidth: '200px', justifyContent: 'end' }}>
							<MobergNumberInput
								key={'XAxis-lowerBound'}
								defaultValue={roundToTwoDecimals(editedConfig?.xAxisConfig?.minValue)}
								onChange={value => updateProperty("xAxisConfig.minValue", value)}
							/>

							-

							<MobergNumberInput
								key={'XAxis-upperBound'}
								defaultValue={roundToTwoDecimals(editedConfig?.xAxisConfig?.maxValue)}
								onChange={value => updateProperty("xAxisConfig.maxValue", value)}
							/>
						</MobergRow>
					</ElementConfigureDiv>

					<ElementConfigureDiv style={{ alignItems: 'center' }}>
						X-Axis modality

						<MobergDropdown
							selectedValue={editedConfig?.xAxisConfig?.modality}
							onChange={value => { updateProperty('xAxisConfig.modality', value) }}
							options={mobergDropdownOptions}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv>
						Y-Axis range

						<MobergRow style={{ display: 'flex', gap: '5px', width: 'min-content', minWidth: '200px', justifyContent: 'end' }}>
							<MobergNumberInput
								key={'YAxis-lowerBound'}
								defaultValue={roundToTwoDecimals(editedConfig?.yAxisConfig?.minValue)}
								onChange={value => updateProperty("yAxisConfig.minValue", value)}
							/>

							-

							<MobergNumberInput
								key={'YAxis-upperBound'}
								defaultValue={roundToTwoDecimals(editedConfig?.yAxisConfig?.maxValue)}
								onChange={value => updateProperty("yAxisConfig.maxValue", value)}
							/>
						</MobergRow>
					</ElementConfigureDiv>

					<ElementConfigureDiv style={{ alignItems: 'center' }}>
						Y-Axis modality

						<MobergDropdown
							selectedValue={editedConfig?.yAxisConfig?.modality}
							onChange={value => { updateProperty('yAxisConfig.modality', value) }}
							options={mobergDropdownOptions}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv style={{ alignItems: 'center' }}>
						Shape

						<MobergDropdown
							selectedValue={editedConfig?.shape}
							onChange={value => { updateProperty('shape', value) }}
							options={ScatterPlotPointShapes.map(shape => ({ label: shape.toUpperCase(), value: shape }))}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv>
						Point size

						<MobergNumberInput 
							key={'pointSize'}
							defaultValue={editedConfig?.size}
							onChange={value => updateProperty("size", value ?? config.size)}
							validationFunction={validatePointSize}
						/>
					</ElementConfigureDiv>

					<ElementConfigureDiv style={{ alignItems: 'center' }}>
						Color

						<input type="color" value={editedConfig?.color} style={{ width: '42px', height: '42px', marginLeft: '10px' }} onChange={e => { updateProperty('color', e.target.value) }} />
					</ElementConfigureDiv>

					<ElementConfigureDiv style={{ alignItems: 'center' }}>
						Autoscale

						<MobergDropdown
							selectedValue={editedConfig?.autoScale}
							onChange={value => { updateProperty('autoScale', value) }}
							options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
						/>
					</ElementConfigureDiv>
				</div>
			</div>
		</BaseVisualizationConfigureModal>
	)
}
