
const CNSFilterFunction = async (filenames, files, newDirectoryName, endpointProvider, endpointLink) => {

    async function check_cns(files, segment, is_segmented_root = false) {
        let body = {
            files: files,
            segment: segment,
            is_segmented_root: is_segmented_root
        }

        return endpointProvider.post(endpointLink.DATA.UPLOAD.CHECK_CNS_FILES, body)
    }

    function getAspects(filename) {
        return filename.split(',')
    }

    function getModalities(filenames) {
        const modalities = filenames.filter((filename) => {
            return (getAspects(filename)[2] === 'SampleSeries' && getAspects(filename)[5] === 'data') || (getAspects(filename)[2] === 'Numeric' && getAspects(filename)[5] === 'data')
        })
        return modalities
    }

    function getDirectoryType(incomingFiles) {
        const rootFileNames = incomingFiles.map(file => file.webkitRelativePath.split('/')[1])

        if (rootFileNames.includes('admission.info') && rootFileNames.includes('patient.info')) {
            return 'Corrupted'
        } else if (rootFileNames.includes('admission.info')) {
            return 'Segmented'
        } else if (rootFileNames.includes('patient.info')) {
            return 'Regular'
        } else {
            return 'Corrupted'
        }
    }

    function getSegmentedFilePaths(files) {
        const filepaths = Object.keys(files).map((i) => { return files[i].webkitRelativePath })
        const segmentedFilepaths = filepaths.filter((filepath) => { return filepath.split('/')[1].startsWith('Segment_') })
        return segmentedFilepaths
    }

    function getNonSegmentedFilePaths(files) {
        const filepaths = Object.keys(files).map((i) => { return files[i].webkitRelativePath })
        const nonSegmentedFilepaths = filepaths.filter((filepath) => { return !filepath.split('/')[1].startsWith('Segment_') })
        return nonSegmentedFilepaths
    }

    function getSegmentedFiles(files, segment) {
        const filepaths = Object.keys(files).map((i) => { return files[i].webkitRelativePath })
        const segmentedFilepaths = filepaths.filter((filepath) => { return filepath.split('/')[1] === segment })
        const segmentedFiles = Object.keys(files).map((i) => { if (segmentedFilepaths.includes(files[i].webkitRelativePath)) { return files[i] } }).filter((file) => { return file !== undefined })
        return segmentedFiles
    }

    function getSegmentedFilenames(files, segment) {
        const filepaths = Object.keys(files).map((i) => { return files[i].webkitRelativePath })
        const segmentedFilepaths = filepaths.filter((filepath) => { return filepath.split('/')[1] === segment })
        const segmentedFilenames = segmentedFilepaths.map((segmentedFilepath) => { return segmentedFilepath.split('/').at(-1) })
        return segmentedFilenames
    }

    function getSegments(files) {
        const segmentedFilepaths = getSegmentedFilePaths(files)
        const segments = [...new Set(segmentedFilepaths.map((segmentedFilepath) => { return segmentedFilepath.split('/')[1] }))]
        return segments
    }

    function containsIllegalFiles(files) {
        return files.map(file => file.name).includes('patient.info.json') || files.map(file => file.name).includes('admission.info.json')
    }

    async function getChildNodesFromFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (e) => {
                try {
                    const parser = new DOMParser()
                    const fileText = reader.result
                    const fileXMLEncrypt = parser.parseFromString(fileText, "text/xml")
                    const infoFileXML = fileXMLEncrypt.getElementsByTagName('PatientInfo')[0]
                    const childNodes = Array.from(infoFileXML.childNodes).map(node => node.nodeName).filter(node => node !== '#text')

                    resolve(childNodes)
                } catch (error) {
                    reject(`${file.name} may be encrypted`)
                }
            }
            reader.onerror = () => {
                reject('Error reading file')
            }
            reader.readAsText(file);
        })
    }

    let allErrors = []
    let validFiles = []

    if (!newDirectoryName.startsWith("Patient_")) {
        alert('Directory names must begin with "Patient_"')
        return {}
    }

    const incomingFiles = [...files]

    if (containsIllegalFiles(incomingFiles)) {
        alert('Directory contains either illegal patient.info.json or admission.info.json files. Please remove these and select the file again.')
        return {}
    }

    const directoryType = getDirectoryType(incomingFiles)
    const requiredNodes = ['TimeStamp', 'RecordingEndTime']

    if (directoryType === 'Segmented') {
        if (allErrors.length === 0) {
            const admissionInfo = incomingFiles.find(file => file.name === 'admission.info')
            if (admissionInfo) {
                try {
                    const nodes = await getChildNodesFromFile(admissionInfo)
                    const missingElements = requiredNodes.filter(node => !nodes.includes(node))

                    if (missingElements.length > 0) {
                        alert(`admission.info is missing: ${missingElements}`)
                        return {}
                    }
                } catch (error) {
                    alert(error)
                    return {}
                }
            }
        }

        const nonSegmentedFilePaths = getNonSegmentedFilePaths(incomingFiles)

        try {
            const response = await check_cns(nonSegmentedFilePaths, 'Regular', true)
            validFiles = incomingFiles.filter(file => response.validFilenames.includes(file.name))
            allErrors = response.errors
        } catch (error) {
            alert(error)
            return {}
        }

        const segments = getSegments(incomingFiles)
        const segmentFilesObject = {}

        incomingFiles.forEach(file => {
            const segment = file.webkitRelativePath.split("/").at(-2)

            if (!segment) {
                alert("There was a problem uploading one of the directories: " + segment)
                return
            }

            if (!segments.includes(segment)) {
                return
            }

            if (Object.keys(segmentFilesObject).includes(segment)) {
                segmentFilesObject[segment] = [...segmentFilesObject[segment], file]
            } else {
                segmentFilesObject[segment] = [file]
            }
        })

        const promises = segments.map(segment => {
            const segmentFiles = getSegmentedFiles(incomingFiles, segment)
            let filepaths = segmentFiles.map(file => file.webkitRelativePath)
            return check_cns(filepaths, segment)
        })

        let responses = []
        try {
            responses = await Promise.all(promises)
        } catch (error) {
            alert(error)
            return {}
        }

        const validSegmentedFiles = responses.map((data, index) => {
            const segmentedFiles = segmentFilesObject[segments[index]]
            return segmentedFiles.filter(file => data.validFilenames.includes(file.name))
        }).flat()

        validFiles = [...validFiles, ...validSegmentedFiles]
        allErrors = [...allErrors, ...responses.map(data => data.errors).flat()]
    }
    else if (directoryType === 'Regular') {
        if (allErrors.length === 0) {
            const patientInfo = incomingFiles.find(file => file.name === 'patient.info')
            if (patientInfo) {
                try {
                    const nodes = await getChildNodesFromFile(patientInfo)
                    const missingElements = requiredNodes.filter(node => !nodes.includes(node))

                    if (missingElements.length > 0) {
                        alert(`patient.info is missing: ${missingElements}`)
                        return {}
                    }
                } catch (error) {
                    alert(error)
                    return {}
                }
            }
        }

        const filenames = incomingFiles.map(file => file.name)
        try {
            const response = await check_cns(filenames, 'Regular')
            allErrors = response.errors
            validFiles = incomingFiles.filter(file => response.validFilenames.includes(file.name))
        } catch (error) {
            alert(error)
            return {}
        }
    } else {
        allErrors.push('There may be issues with the patient.info or admission.info file.')
    }

    if (allErrors.length !== 0) {
        alert(`Error: This directory does not follow the CNS standard structure.\n${allErrors}`)
        return {}
    }

    let nmodalities = getModalities(filenames).length

    let filteredInputData = {
        nmodalities: nmodalities,
        directoryType: directoryType,
        allFiles: validFiles
    }

    return filteredInputData
}

export default CNSFilterFunction