import { atom, atomFamily } from "recoil";
import { AnnotationGroup, InProgressAnnotation } from "../Types/Annotations";
import { Annotation } from "../../../../../Managers/VisualizationManager/Variables/Annotations";

export const inProgressAnnotationAtom = atomFamily<InProgressAnnotation, string>({
    key: "inProgressAnnotation",
    default: {
        id: "",
        color: "#FF0000",
        startDate: null,
        endDate: null,
        opacity: 0.2,
        text: "",
        keyPressed: null,
        modalities: []
    }
})

export const lastInProgressAnnotationAtom = atom<InProgressAnnotation>({
    key: "lastInProgressAnnotation",
    default: {
        id: "",
        color: "#FF0000",
        startDate: null,
        endDate: null,
        opacity: 0.2,
        text: "",
        keyPressed: null,
        modalities: []
    }
})

export const annotationsAtom = atom<Annotation[]>({
    key: "annotations",
    default: [],
})

export const annotationGroupsAtom = atom<AnnotationGroup[]>({
    key: "annotationGroups",
    default: []
})

export const activeAnnotationSessionAtom = atom<AnnotationGroup | null>({
    key: "activeAnnotationSession",
    default: null
})

// This code is very confusing. It should probably be moved to a context provider.
// The selected annotation group is the group that the user can load and unload annotations from.
export const selectedAnnotationGroupAtom = atom<AnnotationGroup | null>({
    key: "selectedAnnotationGroup",
    default: null
})

export const selectedAnnotationAtom = atom<Annotation | null>({
    key: "selectedAnnotation",
    default: null
})

export const canOpenAnnotationsModalAtom = atom<boolean>({
    key: "canOpenAnnotationsModal",
    default: true
})

// Annotations can be hidden on a per-display basis. This was introduced by Event Review.
export const hideAnnotationsAtom = atomFamily<boolean, string>({
    key: "hideAnnotations",
    default: false,
})