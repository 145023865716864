import React, { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useEventReviewProvider } from "./EventReviewProvider";
import { Annotation } from "../Managers/VisualizationManager/Variables/Annotations";
import { AnnotationReview, QuestionResponseType } from "../Pages/Data/Visualize/DataReview/Components/Visualizations/EventReview/React/EventReview";
import { useAuthProvider } from "./AuthProvider";
import { AsyncTask, useAsyncTask } from "../Hooks/useAsyncTask";
import { jumpAllWindows } from "../Pages/Data/Visualize/DataReview/Atoms/Visualizations";
import { isEqual } from "lodash";
import { LinkedWindowEventType } from "../Pages/Data/Visualize/DataReview/Types/LinkedWindowInfo";
import { VisualizationComponent } from "../Pages/Data/Visualize/DataReview/VisualizationComponent";
import { useValidationProvider } from "./ValidationProvider";

type EBOOSTEventReviewContextValue = {
    patternType: IICPatternType | null
    setPatternType: Dispatch<SetStateAction<IICPatternType | null>>
    location: string | null
    setLocation: Dispatch<SetStateAction<string | null>>
    prevalence: number | null
    setPrevalence: Dispatch<SetStateAction<number | null>>
    predominantFrequency: number | null
    setPredominantFrequency: Dispatch<SetStateAction<number | null>>
    maxFrequency: number | null
    setMaxFrequency: Dispatch<SetStateAction<number | null>>
    timeOfMaxFrequency: number | null
    setTimeOfMaxFrequency: Dispatch<SetStateAction<number | null>>
    getIncompleteEvents: () => Annotation[]
    goToNextAnnotation: () => void
    goToPreviousAnnotation: () => void
    reviewedEventIds: Set<number>
    isComplete: boolean
    saveReviewTask: AsyncTask
    isSetDateTimeActive: boolean
    setIsSetDateTimeActive: Dispatch<SetStateAction<boolean>>
    navigateToAnnotation: (annotation: Annotation, noSave?: boolean) => void
    createDefaultAnnotationReview: () => AnnotationReview
}

export enum IICPatternType {
    LPD = "LPD/L-SW",
    GPD = "GPD/G-SW/BIPD",
    LRDA = "LRDA",
    OTHER = "Other (SEDs, GRDA, suppression, burst-suppression, background, etc.)"
}

export enum EBOOSTQuestionID {
    IIC_PATTERN_TYPE = "iic_pattern_type",
    IIC_PATTERN_LOCATION = "iic_pattern_location",
    IIC_PATTERN_PREVALENCE = "iic_pattern_prevalence",
    IIC_PREDOMINANT_FREQUENCY = "iic_pattern_predominant_frequency",
    IIC_MAX_FREQUENCY = "iic_pattern_max_frequency",
    IIC_TIME_OF_MAX_FREQUENCY = "iic_pattern_time_of_max_frequency"
}

const EBOOSTEventReviewContext = createContext<EBOOSTEventReviewContextValue>({} as any)

export const EBOOSTEventReviewProvider: React.FC = ({ children }) => {
    const eventReviewProvider = useEventReviewProvider()
    const { firstError, resetValidation } = useValidationProvider()
    const authProvider = useAuthProvider()
    const [patternType, setPatternType] = useState<IICPatternType | null>(null)
    const [location, setLocation] = useState<string | null>(null)
    const [prevalence, setPrevalence] = useState<number | null>(null)
    const [predominantFrequency, setPredominantFrequency] = useState<number | null>(null)
    const [maxFrequency, setMaxFrequency] = useState<number | null>(null)
    const [timeOfMaxFrequency, setTimeOfMaxFrequency] = useState<number | null>(null)
    const [isSetDateTimeActive, setIsSetDateTimeActive] = useState(false)

    const isComplete = checkIsReviewComplete()
    const reviewedEventIds = getReviewedEventIds()

    const formIsEmpty = (
        patternType === null &&
        location === null &&
        prevalence === null &&
        predominantFrequency === null &&
        maxFrequency === null &&
        timeOfMaxFrequency === null
    )

    function checkIsReviewComplete() {
        if (patternType === IICPatternType.OTHER) {
            return true
        }

        return (
            location !== null && 
            prevalence !== null && 
            predominantFrequency !== null && 
            maxFrequency !== null && 
            timeOfMaxFrequency !== null
        )
    }

    const createDefaultAnnotationReview = useCallback(() => {
        const annotationReview: AnnotationReview = {
            id: new Date().getTime(),
            annotation_id: -1,
            review_type_id: 1,
            user_id: authProvider.currentUser.id,
            user_email: authProvider.currentUser.email,
            timestamp: new Date().getTime(),
            is_complete: false,
            form: [
                {
                    "id": EBOOSTQuestionID.IIC_PATTERN_TYPE,
                    "type": QuestionResponseType.SINGLE_SELECT,
                    "label": "Select predominant pattern",
                    "options": [IICPatternType.LPD, IICPatternType.GPD, IICPatternType.LRDA, IICPatternType.OTHER],
                },
                {
                    "id": EBOOSTQuestionID.IIC_PATTERN_LOCATION,
                    "type": QuestionResponseType.SINGLE_SELECT,
                    "label": "Location",
                    "options": ["Left", "Right", "Bilat", "Bilateral-Independent", "Multifocal"]
                },
                {
                    "id": EBOOSTQuestionID.IIC_PATTERN_PREVALENCE,
                    "type": QuestionResponseType.NUMBER,
                    "label": "Prevalence",
                    "minimum": 0,
                    "maximum": 100,
                    "numDecimals": 0,
                },
                {
                    "id": EBOOSTQuestionID.IIC_PREDOMINANT_FREQUENCY,
                    "type": QuestionResponseType.NUMBER,
                    "label": "Predominant frequency",
                    "minimum": 0,
                    "maximum": 5,
                    "numDecimals": 1,
                },
                {
                    "id": EBOOSTQuestionID.IIC_MAX_FREQUENCY,
                    "type": QuestionResponseType.NUMBER,
                    "label": "Max frequency",
                    "minimum": 0,
                    "maximum": 5,
                    "numDecimals": 1,
                },
                {
                    "id": EBOOSTQuestionID.IIC_TIME_OF_MAX_FREQUENCY,
                    "type": QuestionResponseType.DATETIME,
                    "label": "Time point of max frequency",
                }
            ]
        }
        return annotationReview
    }, [authProvider])

    const createAnnotationReviewJSON = useCallback((annotationId: number): AnnotationReview =>  {
        const currentAnnotationReview = createDefaultAnnotationReview()
        currentAnnotationReview.annotation_id = annotationId
        currentAnnotationReview.is_complete = isComplete
        currentAnnotationReview.timestamp = new Date(Date.now()).getTime()

        currentAnnotationReview?.form?.forEach(questionResponse => {
            switch (questionResponse.id) {
                case EBOOSTQuestionID.IIC_PATTERN_TYPE:
                    questionResponse.response = patternType                    
                    break
                case EBOOSTQuestionID.IIC_PATTERN_LOCATION:
                    questionResponse.response = location
                    break
                case EBOOSTQuestionID.IIC_PATTERN_PREVALENCE:
                    questionResponse.response = prevalence
                    break
                case EBOOSTQuestionID.IIC_PREDOMINANT_FREQUENCY:
                    questionResponse.response = predominantFrequency
                    break
                case EBOOSTQuestionID.IIC_MAX_FREQUENCY:
                    questionResponse.response = maxFrequency
                    break
                case EBOOSTQuestionID.IIC_TIME_OF_MAX_FREQUENCY:
                    questionResponse.response = timeOfMaxFrequency
                    break
                default:
                    break
            }
        })

        return currentAnnotationReview
    }, [createDefaultAnnotationReview, isComplete, location, maxFrequency, patternType, predominantFrequency, prevalence, timeOfMaxFrequency])


    function getReviewedEventIds(): Set<number> {
        const annotationReviews = eventReviewProvider.annotationReviews ?? []
        const reviewedEventIds = new Set<number>()

        for (const review of annotationReviews) {
            const foundAnnotation = eventReviewProvider.sortedAnnotations.find(annotation => annotation.id === review.annotation_id)
            
            if (foundAnnotation && review.is_complete) {
                reviewedEventIds.add(review.annotation_id)
            }
        }

        return reviewedEventIds
    }

    function getIncompleteEvents() {
        const sortedAnnotations = eventReviewProvider.sortedAnnotations
        return sortedAnnotations.filter(annotation => !reviewedEventIds.has(annotation.id))
    }

    function setExistingValues(annotationReview: AnnotationReview) {
        annotationReview?.form?.forEach(questionResponse => {
            switch (questionResponse.id) {
                case EBOOSTQuestionID.IIC_PATTERN_TYPE:
                    setPatternType(questionResponse.response ?? null)
                    break
                case EBOOSTQuestionID.IIC_PATTERN_LOCATION:
                    setLocation(questionResponse.response ?? null)
                    break
                case EBOOSTQuestionID.IIC_PATTERN_PREVALENCE:
                    setPrevalence(questionResponse.response ?? null)
                    break
                case EBOOSTQuestionID.IIC_PREDOMINANT_FREQUENCY:
                    setPredominantFrequency(questionResponse.response ?? null)
                    break
                case EBOOSTQuestionID.IIC_MAX_FREQUENCY:
                    setMaxFrequency(questionResponse.response ?? null)
                    break
                case EBOOSTQuestionID.IIC_TIME_OF_MAX_FREQUENCY:
                    setTimeOfMaxFrequency(questionResponse.response ?? null)
                    break
                default:
                    break
            }
        })
    }

    const madeChangesToCurrentEventReview = (
        eventReviewProvider.unmodifiedAnnotationReview?.form !== undefined 
        && !isEqual(getResponses(eventReviewProvider.unmodifiedAnnotationReview), getResponses(createAnnotationReviewJSON(-1)))
    )

    function getResponses(annotationReview: AnnotationReview) {
        return annotationReview.form.map(q => q.response)
    }

    const handleSavingReview = () => {
        if (firstError !== undefined) {
            return new Promise((_, reject) => reject(`Save failed: ${firstError}`))
        }
        
        if (!eventReviewProvider.currentAnnotationReview || !madeChangesToCurrentEventReview || formIsEmpty) {
            return new Promise(resolve => resolve(0)) // Don't bother talking to the DB, nothing to save
        }

        const reviewJSON = createAnnotationReviewJSON(eventReviewProvider.currentAnnotationReview.annotation_id)

        return eventReviewProvider.addAnnotationReviewEntry(reviewJSON)
    }

    // TODO: eventually lift this task into the event review provider, using a switch statement to handle creating different types of AnnotationReview JSON.
    const saveReviewTask = useAsyncTask(handleSavingReview)

    const navigateToAnnotation = (annotation: Annotation, noSave: boolean=false) => {
        const startDate = new Date(annotation.start_time)
        const allWindowTypesExceptPersystTrends = Object.values(VisualizationComponent).filter(v => v !== VisualizationComponent.PERSYST_TRENDS)

        let middleDate: Date 

        if (annotation.start_time === annotation.end_time) {
            middleDate = new Date(annotation.start_time)
        } else {
            middleDate = new Date((annotation.start_time + annotation.end_time) / 2)
        }

        const doJump = () => {
            jumpAllWindows(startDate, LinkedWindowEventType.JUMP_START, { autoScale: true, windowTypes: allWindowTypesExceptPersystTrends })
            jumpAllWindows(middleDate, LinkedWindowEventType.JUMP_MIDDLE, { autoScale: true, windowTypes: [VisualizationComponent.PERSYST_TRENDS]})
        }

        if (noSave || (annotation.id === eventReviewProvider.currentAnnotation?.id && !madeChangesToCurrentEventReview)) {
            doJump()
        } else {
            saveReviewTask.run()
                .then(() => {
                    eventReviewProvider.setCurrentAnnotation(annotation)
                    doJump()
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    const goToNextAnnotation = () => {
        const sortedAnnotations = eventReviewProvider.sortedAnnotations
        const annotationIndex = eventReviewProvider.annotationIndex
       
        if (annotationIndex >= sortedAnnotations.length - 1) {
            navigateToAnnotation(sortedAnnotations[0])
        } else {
            navigateToAnnotation(sortedAnnotations[annotationIndex + 1])
        }
    }
    
    const goToPreviousAnnotation = () => {
        const sortedAnnotations = eventReviewProvider.sortedAnnotations
        const annotationIndex = eventReviewProvider.annotationIndex

        if (annotationIndex <= 0) {
            navigateToAnnotation(sortedAnnotations[sortedAnnotations.length - 1])
        } else {
            navigateToAnnotation(sortedAnnotations[annotationIndex - 1])
        }
    }

    // When the annotation review changes, update the state
    useEffect(() => {
        if (!eventReviewProvider.currentAnnotationReview) {
            return
        }

        setExistingValues(eventReviewProvider.currentAnnotationReview)
    }, [eventReviewProvider.currentAnnotationReview])


    // When the user selects "Other", the location, prevalence, etc are not used or needed.
    // Remove any values or validation associated with it.
    useEffect(() => {
        if (patternType === IICPatternType.OTHER) {
            setLocation(null)
            setPrevalence(null)
            setPredominantFrequency(null)
            setMaxFrequency(null)
            setTimeOfMaxFrequency(null)
            resetValidation()
        }
    }, [patternType, resetValidation])


    return (
        <EBOOSTEventReviewContext.Provider 
            value={{patternType, setPatternType, location, setLocation, prevalence, setPrevalence, predominantFrequency, setPredominantFrequency, 
            maxFrequency, setMaxFrequency, timeOfMaxFrequency, setTimeOfMaxFrequency, getIncompleteEvents, reviewedEventIds, isComplete, 
            saveReviewTask, isSetDateTimeActive, setIsSetDateTimeActive, goToNextAnnotation, goToPreviousAnnotation, navigateToAnnotation, createDefaultAnnotationReview }}
        >
            {children}
        </EBOOSTEventReviewContext.Provider>
    )
}

export const useEBOOSTEventReviewProvider = () => useContext(EBOOSTEventReviewContext)