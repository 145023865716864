import styled from "styled-components";

const CircularCheckboxComponent = ({ style, checked, onChangeFxn, checkboxLabel, labelStyle }) => {

    return (
       <CircularCheckbox style={style}>
		<label>
			<input type="checkbox" checked={checked} onChange={() => {onChangeFxn()}}/>
				<span style={labelStyle}>{checkboxLabel}</span>
		</label>
	   </CircularCheckbox>
    )
}

// example usage:
// <CircularCheckboxComponent style={{color: existingPatientsCheckedProvider ? "#207DEA" : "#B6B6B6"}} checked={existingPatientsCheckedProvider} onChangeFxn={handleExistingPatientsCheckbox} checkboxLabel={"Existing Patients"} />

const CircularCheckbox = styled.div`
  display: inline-flex;
	label {
	cursor: pointer;
	}

	span {
	font-family: "Source Sans Pro";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	white-space: nowrap;
	} //controls styling for checkbox label/text

	input[type="checkbox"] {
	display: none;
	} //removes default square checkbox

	input[type="checkbox"] + span:before {
	width: 22px;
	height: 22px;
	border: 3px solid #B6B6B6;
	content: "";
	display: inline-block;
	margin-right: 12px;
	padding: 0;
	vertical-align: top;
	border-radius: 50%;
	} // controls styling for circular checkbox before it is checked

	input[type="checkbox"]:checked + span:before {
	background: #207DEA;
	border: 3px solid #207DEA;
	box-shadow: inset 0px 0px 0px 4px #fff;
	color: #207DEA;
	content: "";
	text-align: center;
	border-radius: 50%;
	} //controls styling for circular checkbox after it is checked
`;

export default CircularCheckboxComponent;