import React from "react"
import { BaseVisualizationConfigureModal } from "./BaseVisualizationConfigureModal"
import { histogramConfigAtom } from "../../Atoms/Histogram"
import { useEditWindow } from "./useEditWindow"
import { HistogramJSON, HistogramModalityConfig } from "../../Types/Histogram"
import { WINDOW_TIME_PRESETS } from "../../../../../../Managers/VisualizationManager/Viewport/Components/XAxis"
import { useOnMount } from "../../../../../../Hooks/useOnMount"
import { modalityUpdates, useModalitiesProvider } from "../../../../../../Providers/ModalitiesProvider"
import { MobergDropdown } from "../../../../../../Components/MobergDropdown/MobergDropdown"
import { MobergButton, MobergButtonShape, MobergColumn, MobergIconSize, MobergNumberInput, MobergRow, MobergTheme } from "../../../../../../Moberg"
import { currentPatientFileInfoAtom } from "../../Atoms/PatientFile"
import { useRecoilValue } from "recoil"
import { MdAdd, MdClose } from "react-icons/md"
import { DataSource } from "../../Types/DataSource"

type ConfigureHistogramModalProps = {
	windowId: string
	layoutId: string
}

export const ConfigureHistogramModal = (props: ConfigureHistogramModalProps) => {
	const modalitiesProvider = useModalitiesProvider()
	const atom = histogramConfigAtom({ windowId: props.windowId, layoutId: props.layoutId })
	const { patientModalities } = useRecoilValue(currentPatientFileInfoAtom)
	const { editedConfig, saveChanges, updateProperty } = useEditWindow<HistogramJSON>({ recoilState: atom, windowId: props.windowId, layoutId: props.layoutId })
	const config = useRecoilValue(atom)
	const sortedModalities = Object.keys(modalitiesProvider.modalities).sort()
	const modalityOptions = sortedModalities.filter(modality => !modality.startsWith('EEG')).map(modality => (
		{
			label: modality,
			value: {
				name: modality,
				dataKey: modality,
				dataSource: DataSource.CURRENT_PATIENT
			},
			highlight: patientModalities.includes(modality)
		}
	))

	useOnMount(() => {
		modalitiesProvider.update(modalityUpdates.MODALITIES)
	})

	function handleColorChange(index: number, color: string) {
		const currentModalities = structuredClone(editedConfig?.modalityConfigs)
		currentModalities[index].color = color
		updateProperty("modalityConfigs", currentModalities)
	}

	function handleModalityChange(index: number, newConfig: HistogramModalityConfig) {
		const currentModalityConfigs = structuredClone(editedConfig?.modalityConfigs)
		Object.assign(currentModalityConfigs[index], newConfig)
		updateProperty("modalityConfigs", currentModalityConfigs)
	}

	const addModality = () => {
		const newConfig: HistogramModalityConfig = {
			id: `histogram-modality-config-${new Date(Date.now()).toISOString()}`,
			name: "ABP_na",
			dataKey: "ABP_na",
			rawDataKey: "ABP_na",
			color: "#000",
			units: "mmHg",
			isCompositePart: false,
			dataSource: DataSource.CURRENT_PATIENT,
		}

		updateProperty("modalityConfigs", [...editedConfig.modalityConfigs, newConfig])
	}

	const deleteModality = (index: number) => {
		const copy = structuredClone(editedConfig.modalityConfigs)
		copy.splice(index, 1)
		updateProperty("modalityConfigs", copy)
	}

	function binMaxValidation(value: number) {
		if (value > 10000) {
			return "Max bin value must be less than 10000."
		} else if (value < editedConfig?.binMinimum) {
			return `Max bin value must be greater than the bin minimum of ${editedConfig?.binMinimum}.`
		} else {
			return undefined
		}
	}

	function binMinValidation(value: number) {
		if (value < -10000) {
			return "Min bin value must be greater than -10000."
		} else if (value > editedConfig?.binMaximum) {
			return `Min bin value must be less than the bin maximum of ${editedConfig?.binMaximum}.`
		} else {
			return undefined
		}
	}

	function binSizeValidation(value: number) {
		if (value < 0) {
			return "Bin size must be greater than 0."
		} else if (value > 10000) {
			return "Bin size must be less than 10000."
		} else {
			return undefined
		}
	}

	return (
		<BaseVisualizationConfigureModal layoutId={props.layoutId} windowId={props.windowId} title={"Configure Histogram"} saveChanges={saveChanges}>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<div style={{ padding: "20px" }}>
					<MobergColumn gap="16px">

						<MobergRow horizontalAlign="space-between">
							Initial Page Size

							<MobergDropdown
								selectedValue={editedConfig?.viewDuration}
								onChange={value => updateProperty("viewDuration", parseInt(value as string))}
								options={WINDOW_TIME_PRESETS.filter(preset => preset.label !== "Page Size").map(preset => ({ label: preset.label, value: preset.time }))}
							/>
						</MobergRow>

						<MobergRow gap="32px" horizontalAlign="space-between">
							Lowest Bin Value
							<MobergNumberInput
								key={'binMinimum'}
								defaultValue={0}
								value={editedConfig?.binMinimum}
								validationFunction={binMinValidation}
								onChange={value => updateProperty("binMinimum", value ?? config?.binMinimum)}
							/>
						</MobergRow>

						<MobergRow gap="32px" horizontalAlign="space-between">
							Highest Bin Value
							<MobergNumberInput
								key={'binMaximum'}
								defaultValue={100}
								value={editedConfig?.binMaximum}
								validationFunction={binMaxValidation}
								onChange={value => updateProperty("binMaximum", value ?? config?.binMaximum)}
							/>
						</MobergRow>

						<MobergRow gap="32px" horizontalAlign="space-between">
							Bin Size
							<MobergNumberInput
								key={'binSize'}
								defaultValue={5}
								value={editedConfig?.binSize}
								validationFunction={binSizeValidation}
								onChange={value => updateProperty("binSize", value ?? config?.binSize)}
							/>
						</MobergRow>

						<MobergRow gap="16px" verticalAlign="start">
							<span style={{ paddingTop: "8px" }}>
								Modalities
							</span>

							<MobergColumn gap="16px">
								{editedConfig?.modalityConfigs?.map((modalityConfig, index) => {
									return (
										<MobergRow gap="8px" horizontalAlign="right" key={modalityConfig.id}>
											<MobergDropdown
												selectedValue={modalityConfig}
												onChange={newConfig => handleModalityChange(index, newConfig)}
												options={modalityOptions}
												equals={(a: HistogramModalityConfig, b: HistogramModalityConfig) => (
													a.name === b.name &&
													a.dataKey === b.dataKey &&
													a.dataSource === b.dataSource
												)}
											/>

											<input type="color"
												value={editedConfig?.modalityConfigs ? editedConfig?.modalityConfigs[index].color : modalityConfig.color}
												style={{ width: '42px', height: '42px', marginLeft: '10px' }}
												onChange={e => handleColorChange(index, e.target.value)} />

											<MobergButton
												shape={MobergButtonShape.SQUARE}
												onClick={() => deleteModality(index)}>
												<MdClose size={MobergIconSize.SMALL} />
											</MobergButton>
										</MobergRow>
									)
								})}
							</MobergColumn>
						</MobergRow>

						<MobergRow horizontalAlign="right">
							<MobergButton onClick={addModality} theme={MobergTheme.BLUE}>
								<MdAdd size={MobergIconSize.REGULAR} />
								Add Modality
							</MobergButton>
						</MobergRow>

					</MobergColumn>

				</div>
			</div>
		</BaseVisualizationConfigureModal>
	)
}