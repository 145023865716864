import React, { Dispatch, SetStateAction } from "react"
import { Scrollbar } from "../../Constants/StyledComponents"
import { Outlet, Route, Routes } from "react-router-dom"
import PageHeader from "../../Components/PageHeader/PageHeader"
import UserTabs from "../../Pages/Admin/Users/UserTabs"
import Sidebar from "../../Components/SideBar/Sidebar"
import { useAuthProvider } from "../../Providers/AuthProvider"
import { FRONTEND_LINKS } from "../../Constants/FrontendLinks"

type MainLayoutProps = {
    navPage: boolean
    sidebar: boolean
    setNavPage: Dispatch<SetStateAction<boolean>>
    setSidebar: Dispatch<SetStateAction<boolean>>
}

export const MainLayout: React.FC<MainLayoutProps> = ({ navPage, sidebar, setNavPage, setSidebar }) => {
    const authProvider = useAuthProvider()
    
    return (
    <>
        {navPage &&
            <Sidebar
                sidebar={sidebar}
                setSidebar={setSidebar}
            />
        }

        <div id='HeaderAndPage' style={{ marginLeft: "250px", height: "100vh", display: "flex", flexDirection: "column" }}>
            {navPage && authProvider.userIsLoggedIn && (<>
                <PageHeader
                    setNavPage={setNavPage}
                    sidebar={sidebar}
                    setSidebar={setSidebar}
                />

                <Routes>
                    <Route path={`${FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.MAIN}/*`} element={<UserTabs />} />
                    <Route path="*" element={null} />
                </Routes>

            </>)}

            <Scrollbar style={{ flex: 1, display: "flex", alignItems: "center", background: navPage ? '#F8F8F8' : 'white', }}>
                <div style={{ width: "100%", height: "100%" }}>
                    <Outlet /> {/* This is where the route element actually gets rendered */}
                </div>
            </Scrollbar>
        </div>
    </>)
}