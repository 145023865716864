import { Selection, EnterElement } from "d3-selection";
import { SdDetectionReactCallbacks } from "../../../../Types/ReactCallbacks";
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable";
import { ModalityPage } from "../../../../Data/ModalityPage";
import { ScaleBand, ScaleTime } from "d3";
import { ModalityPageManager } from "../../../../Data/ModalityPageManager";
import { TimeSeriesData } from "../../../../Data/TimeSeriesData";

export type D3SDEventCanvasConfig = {
    viewScale: ScaleTime<any, any, any>
    channelScale: ScaleBand<string>
    dataObjectId: number
    eventExpansionMinutes: number
    autoDetectionEnabled: boolean
    color?: string
}

export class D3SDEventCanvas extends D3OneToOneRenderable<SVGGElement, SVGForeignObjectElement, D3SDEventCanvasConfig, SdDetectionReactCallbacks> {
    private pageManager: ModalityPageManager
    private context?: CanvasRenderingContext2D | null
    private pageRectangle = { x: 0, y: 0, width: 0, height: 0 }

    constructor(root: SVGGElement, config: D3SDEventCanvasConfig, pageManager: ModalityPageManager, reactCallbacks: SdDetectionReactCallbacks) {
        super(root, config, "d3-sd-event-canvas", reactCallbacks)
        this.pageManager = pageManager
        this.render()
    }

    public renderPage(page: ModalityPage | undefined) {
        if (!page || !this.context) {
            return
        }

        const { x, y, width, height } = this.getPageRectangle(page.startTime, page.endTime)

        if (!this.config.autoDetectionEnabled) {
            return
        }

        const montageData = page.data.get(this.config.dataObjectId)?.get("EEG,Composite,SampleSeries") as TimeSeriesData | undefined

        // If no data exists (page still loading), show a loading overlay.
        if (!page.loaded || !montageData) {
            this.context.fillStyle = "rgba(0, 0, 255, 0.2)"
            this.context.fillRect(x, y, width, height)
            return
        }

        if (montageData && (montageData.data.length === 0 || montageData.times.length === 0)) {
            return // We can't draw anything on the screen.
        }

        const [viewStartDate, viewEndDate] = this.config.viewScale.domain()
        const viewStartTime = viewStartDate.getTime()
        const viewEndTime = viewEndDate.getTime()
        const options = this.config.channelScale.domain()

        for (let i = 0; i < montageData.times.length; i++) {
            const time = montageData.times[i]

            if (!time) {
                continue
            }

            const channelPair = options[montageData.data[i]]
            const y = this.config.channelScale(channelPair) ?? 0
            const height = this.config.channelScale.bandwidth()
            const eventExpansionMilliseconds = this.config.eventExpansionMinutes * 60 * 1000
            const startTime = time - eventExpansionMilliseconds
            const endTime = time + eventExpansionMilliseconds

            if (endTime <= viewStartTime || startTime > viewEndTime) {
                continue
            }

            const x = this.config.viewScale(startTime)
            const width = this.config.viewScale(endTime) - x

            this.context.strokeStyle = this.config.color ?? "blue"
            this.context.lineWidth = 5
            this.context.strokeRect(x, y, width, height)
        }
    }

    protected enter(newElements: Selection<EnterElement, D3SDEventCanvasConfig, any, any>): Selection<SVGForeignObjectElement, D3SDEventCanvasConfig, SVGGElement, any> {
        const foreignObject = newElements.append("foreignObject").attr("class", this.className)
        const canvas = foreignObject.append("xhtml:canvas") as Selection<HTMLCanvasElement, any, any, any>

        const width = this.config.viewScale?.range()[1] - this.config.viewScale?.range()[0]
        const height = this.config.channelScale?.range()[1] - this.config.channelScale?.range()[0]

        foreignObject.attr("width", width).attr("height", height)
        canvas.attr("width", width).attr("height", height)

        const canvasNode = canvas.node()

        if (canvasNode != null) {
            this.context = canvasNode.getContext("2d") ?? undefined

            if (this.context && this.config.autoDetectionEnabled) {
                this.context.fillStyle = "rgba(0, 0, 255, 0.2)"
                this.context.fillRect(0, 0, width, height)
            }
        }

        this.pageManager.getPagesInView().forEach(page => this.renderPage(page))

        return foreignObject
    }

    protected update(updatedForeignObject: Selection<SVGForeignObjectElement, D3SDEventCanvasConfig, any, any>): Selection<SVGForeignObjectElement, D3SDEventCanvasConfig, SVGGElement, any> {
        const updatedCanvas = updatedForeignObject.select("canvas")

        const width = this.config.viewScale?.range()[1] - this.config.viewScale?.range()[0]
        const height = this.config.channelScale?.range()[1] - this.config.channelScale?.range()[0]

        updatedForeignObject.attr("width", width).attr("height", height)
        updatedCanvas.attr("width", width).attr("height", height)

        this.pageManager.getPagesInView().forEach(page => this.renderPage(page))

        return updatedForeignObject
    }

    private getPageRectangle = (startTime: Date | number, endTime: Date | number) => {
        const x1 = this.config.viewScale(startTime)
        const x2 = this.config.viewScale(endTime)
        const width = x2 - x1
        const height = this.config.channelScale.range()[1]

        this.pageRectangle.x = Math.round(x1)
        this.pageRectangle.width = width
        this.pageRectangle.height = height

        return this.pageRectangle
    }
}
