import React, { Dispatch, MutableRefObject, SetStateAction, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MdChevronLeft, MdChevronRight, MdOutlineFolder } from 'react-icons/md'
import MUITable from '../../../Components/MUITable/MUITable'
import { MobergBoxShadow, MobergColumn, MobergFontSize } from '../../../Moberg';
import { useRecoilState } from 'recoil';
import { annotationGroupsAtom, annotationsAtom, activeAnnotationSessionAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/Annotations';
import { currentPatientFileInfoAtom } from '../../../Pages/Data/Visualize/DataReview/Atoms/PatientFile';
import { useRecoilValue } from "recoil"
import { useModalProvider } from '../../../Providers/ModalProvider';
import { AnnotationSessionManagerModal } from '../ToolBar/Modals/AnnotationSessionManagerModal';
import { MobergAnimationTiming, MobergAnimationCurve, MobergDropdown, MobergRow, MobergButtonShape, MobergIconSize, MobergDropdownSize, MobergButton } from '../../../Moberg';
import { DurationUnit, fromMilliseconds, getFormattedDuration } from '../../../Pages/Data/Visualize/DataReview/Hooks/useDuration';
import { PaginationType } from '../../../Components/MUITable/MUITableTypes';
import { jumpAllWindows } from '../../../Pages/Data/Visualize/DataReview/Atoms/Visualizations';
import { Annotation } from '../Variables/Annotations';
import { DimensionsProvider } from '../../../Providers/DimensionsProvider';

type ActionsBarProps = {
	sidebarWidth: string
	enableSidebar: boolean
	setEnableSidebar: Dispatch<SetStateAction<boolean>>
	actionsBarRef: MutableRefObject<HTMLDivElement>
	isResizing: boolean
}

type AnnotationTableRow = {
	id: number
	start_time: number
	duration: string

}

function ActionsBar(props: ActionsBarProps) {
	const { createModal } = useModalProvider()
	const annotations = useRecoilValue(annotationsAtom)
	const patientInfo = useRecoilValue(currentPatientFileInfoAtom)
	const annotationGroups = useRecoilValue(annotationGroupsAtom)
	const [searchValue, setSearchValue] = useState("")
	const cleanSearchValue = searchValue.trim().toLowerCase()
	const [activeAnnotationSession, setActiveAnnotationSession] = useRecoilState(activeAnnotationSessionAtom)
	const sidebarRef = useRef<HTMLDivElement>(null)
	const sidebarContentRef = useRef<HTMLDivElement>(null)

	const dateFormatter = useMemo(() => {
		const options: Intl.DateTimeFormatOptions & { fractionalSecondDigits: number, hourCycle: string } = {
			timeZone: patientInfo.timeZone,
			year: "numeric",
			month: "short",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			fractionalSecondDigits: 3,
			hourCycle: "h23"
		}
	
		return new Intl.DateTimeFormat("en-us", options)
	}, [patientInfo.timeZone])

	const annotationTableData = annotations
		.filter((annotation: Annotation) => (
			annotation.text.toLowerCase().includes(cleanSearchValue) ||
			annotation.description.toLowerCase().includes(cleanSearchValue) ||
			annotation.tags.some(tag => tag.toLowerCase().includes(cleanSearchValue))
		))
		.map(annotation => ({
			name: annotation.text,
			time: annotation.start_time,
			duration: annotation.end_time - annotation.start_time
		}))

	const dropdownOptions = annotationGroups.map(group => ({
		label: group.group_name,
		value: group.id
	}))

	const handleDropdownChange = (value: any) => {
		const selectedGroup = annotationGroups.find(group => group.id === value);
		if (selectedGroup) {
			setActiveAnnotationSession(selectedGroup)
		}
	}

	useLayoutEffect(() => {
		const sidebar = sidebarRef.current
		const sidebarContent = sidebarContentRef.current

		if (!sidebar || !sidebarContent) {
			return
		}

		if (!props.enableSidebar) {
			sidebarContent.style.width = "0px"
			sidebarContent.style.transition = `all ${MobergAnimationCurve.EASE_OUT} ${MobergAnimationTiming.REGULAR}`
		} else {
			sidebarContent.style.width = "400px"
			sidebarContent.style.transition = `all ${MobergAnimationCurve.EASE_IN} ${MobergAnimationTiming.REGULAR}`
		}
	}, [props.enableSidebar, props.sidebarWidth])

	const handleSelectedRow = useCallback((selectedRow: AnnotationTableRow) => {
		const annotation = annotations[selectedRow.id]

		if (!annotation) {
			return
		}

		jumpAllWindows(new Date(annotation.end_time))
	}, [annotations])

	const annotationTableColumns = useMemo(() => [
		{
			field: "time", flex: 0.5, headerName: "Time", visible: true, 
			renderCell: (params: any) => {
				const [monthAndDay, year, time] = dateFormatter.format(params.row.time).split(", ")
				return (
					<MobergColumn style={{ fontSize: MobergFontSize.SMALL }}>
						<div>{monthAndDay}, {year}</div>
						<div>{time}</div>
					</MobergColumn>
				)
			},
		},
		{
			field: "name", flex: 1, headerName: "Name", minWidth: 100, visible: true, renderCell: (params: any) => {
				return (
					<span onMouseDown={() => handleSelectedRow(params.row)} style={{ color: "#207dea", cursor: "pointer", fontWeight: "bold", textDecoration: "underline" }}>
						{params.row.name}
					</span>
				)
			},
		},
		{
			field: "duration", flex: 0.5, headerName: "Duration", visible: true, renderCell: (params: any) => {
				const duration = fromMilliseconds(params.row.duration, [DurationUnit.SECONDS, DurationUnit.MINUTES])
				return (<span>{getFormattedDuration(duration, { abbreviation: true })}</span>)
			},
		},
	], [dateFormatter, handleSelectedRow])

	return (
		<div ref={sidebarRef} style={{ backgroundColor: '#fff', boxShadow: MobergBoxShadow.LOW, borderRadius: "6px", position: "relative", margin: "14px 0px", padding: props.enableSidebar ? "8px" : "8px 2px" }}>
			<MobergRow expand={false} style={{ height: "100%" }} gap="4px">
				<MobergColumn verticalAlign='center'>
					<MobergButton onClick={() => props.setEnableSidebar(previous => !previous)} shape={MobergButtonShape.SQUARE} style={{ padding: "2px" }}>
						{props.enableSidebar
							? <MdChevronRight size={MobergIconSize.REGULAR} />
							: <MdChevronLeft size={MobergIconSize.REGULAR} />
						}
					</MobergButton>
				</MobergColumn>
			<div ref={sidebarContentRef} style={{ height: '100%', width: "0px", overflow: "hidden", transition: `all ${MobergAnimationTiming.REGULAR} ${MobergAnimationCurve.EASE_OUT}` }}>
				<MobergColumn expand={true} gap="16px" style={{ width: "400px" }}>
					<MobergRow expand={false} gap="8px" horizontalAlign="right">
						<MobergDropdown
							label={"Current Annotation Group:"}
							options={dropdownOptions}
							onChange={handleDropdownChange}
							selectedValue={activeAnnotationSession?.id}
							size={MobergDropdownSize.SMALL}
						/>

						<MobergButton onClick={() => createModal(<AnnotationSessionManagerModal />)} shape={MobergButtonShape.SQUARE}>
							<MdOutlineFolder size={MobergIconSize.LARGE} />
						</MobergButton>
					</MobergRow>
					
					<DimensionsProvider>
						{/* By putting the absolute container inside a relative container, 
							we can override the browser behavior where the element determines its own width and height */}
						<div style={{ position: "relative" }}>
							<MUITable
								tableData={annotationTableData}
								columns={annotationTableColumns}
								isCheckboxSelection={false}
								isRowSelectable={() => false}
								style={{ padding: 0, background: "#fff", position: "absolute" }}
								paginationType={PaginationType.COMPACT}
								handleOnRowClick={handleSelectedRow}
								onSearchValueChanged={(searchValue: string) => setSearchValue(searchValue)}
								autoPageSizeProps={{
									enabled: true,
									lineHeight: 40,
									headerHeight: 124,
									paginationHeight: 52
								}}
							/>
						</div>
					</DimensionsProvider>
				</MobergColumn>
			</div>
			</MobergRow>
		</div>
	)
}

export default ActionsBar;