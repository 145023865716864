import { useUploadProcessProvider } from "../../Providers/UploadProcessProvider";
import BasicTextField from "../TextField/BasicTextField";
import DropdownField from "../TextField/DropdownField";
import { CheckboxField, GroupCheckboxField } from "../TextField/CheckboxField";
import { Grid } from "@mui/material";
import { CalendarPicker } from "../DateTimePicker/CalendarPicker";

const FormConstructor = ({ questions, control, disabledAll = false, defaultValues, changeDisabledTheme = false }) => {

    const generateField = (question) => {
        let fieldComponent = null;
        if (question.type === "text") {
            fieldComponent = (
                <BasicTextField
                    label={question.prompt}
                    placeholder={question.placeholder}
                    control={control}
                    name={question.questionType}
                    numberColumn={6}
                    disabled={disabledAll ? true : false}
                    changeDisabledTheme={changeDisabledTheme}
                    value={
                        defaultValues ? defaultValues[question.questionType].value :
                            uploadProcessProvider.submittedForm[question.questionType]
                                ? uploadProcessProvider.submittedForm[question.questionType].value
                                : question.default
                    }
                    maxLength={question.characterLimit}
                />
            );
        } else if (question.type === "number") {
            fieldComponent = (
                <BasicTextField
                    label={question.prompt}
                    placeholder={question.placeholder}
                    control={control}
                    name={question.questionType}
                    type="number"
                    numberColumn={6}
                    changeDisabledTheme={changeDisabledTheme}
                    disabled={disabledAll ? true : false}
                />
            );
        } else if (question.type === "dropdown") {
            fieldComponent = (
                <DropdownField
                    label={question.prompt}
                    control={control}
                    name={question.questionType}
                    options={question.options}
                    numberColumn={12}
                    disabled={disabledAll ? true : false}
                    changeDisabledTheme={changeDisabledTheme}
                    value={
                        uploadProcessProvider.submittedForm[question.questionType] && uploadProcessProvider.submittedForm[question.questionType].value !== undefined
                            ? uploadProcessProvider.submittedForm[question.questionType].value
                            : defaultValues && defaultValues[question.questionType] ? defaultValues[question.questionType].value
                                : question.default
                    }
                />
            );
        } else if (question.type === "checkbox") {
            if (question.multiple) {
                fieldComponent = (
                    <GroupCheckboxField
                        label={question.prompt}
                        control={control}
                        value={
                            uploadProcessProvider.submittedForm[question.questionType] && uploadProcessProvider.submittedForm[question.questionType].value !== undefined
                                ? uploadProcessProvider.submittedForm[question.questionType].value
                                : defaultValues && defaultValues[question.questionType] ? defaultValues[question.questionType].value
                                    : question.default
                        }
                        name={question.questionType}
                        options={question.options}
                        changeDisabledTheme={changeDisabledTheme}
                        disabled={disabledAll ? true : false}
                    />
                );
            } else {
                fieldComponent = (
                    <CheckboxField
                        label={question.prompt}
                        control={control}
                        value={
                            defaultValues ? defaultValues[question.questionType].value :
                                uploadProcessProvider.submittedForm[question.questionType] ? uploadProcessProvider.submittedForm[question.questionType].value : question.default
                        }
                        name={question.questionType}
                        options={question.options}
                        changeDisabledTheme={changeDisabledTheme}
                        disabled={disabledAll ? true : false}
                        required={question.required}
                    />
                );
            }
        } else if (question.type === "datepicker") {
            const currentDate = new Date();
            const minTime = new Date(currentDate);
            const maxTime = new Date(currentDate);
            const oneHundredYearsInMilliseconds = 100 * 365 * 24 * 60 * 60 * 1000;
            minTime.setTime(currentDate.getTime() - oneHundredYearsInMilliseconds);
            maxTime.setTime(currentDate.getTime() + oneHundredYearsInMilliseconds);
            fieldComponent = (
                <CalendarPicker
                    label={question.prompt}
                    control={control}
                    value={
                        uploadProcessProvider.submittedForm[question.questionType] && uploadProcessProvider.submittedForm[question.questionType].value !== undefined
                            ? uploadProcessProvider.submittedForm[question.questionType].value
                            : defaultValues && defaultValues[question.questionType] ? defaultValues[question.questionType].value
                                : null
                    }
                    changeDisabledTheme={changeDisabledTheme}
                    name={question.questionType}
                    disabled={disabledAll ? true : false}
                />
            );
        }

        return (
            <Grid xs={12} style={{ marginBottom: '20px' }}>
                {fieldComponent}
            </Grid>
        );
    }

    
    const uploadProcessProvider = useUploadProcessProvider();
    const questionsArray = Object.keys(questions).map((key) => questions[key]);
    const formFields = questionsArray.map((question, index) => {
        if (Array.isArray(question)) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {question.map((q, i) => {
                        return (
                            <div style={{ margin: '40px' }} key={i}>
                                {generateField(q)}
                            </div>
                        );
                    })}
                </div>
            );
        }
        return (
            generateField(question)
        );
    });


    return (
        <div
            style={{
                paddingTop: "15px",
                paddingLeft: "47px",
                paddingRight: "47px",
                paddingBottom: "0px",
                height: "100%",
                marginBottom: '20px',
                maxHeight: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
            }}
        >
            <Grid container spacing={2}>
                {formFields}
            </Grid>
        </div>
    );
};

export default FormConstructor;