import React from "react";
import { Column, Columns } from "../../Utils/Styling";
import { AnnotationGroupTable } from "../Components/AnnotationGroupTable";
import { AnnotationGroupEditor } from "../Components/AnnotationGroupEditor";
import { useModalProvider } from "../../../../Providers/ModalProvider";
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader";

export function AnnotationSessionManagerModal() {
    const { close } = useModalProvider()
    
    return (
        <div style={{ height: "80vh", width: "90vw" }}>
            <ModalHeader headerText={"Load Annotations"} closeFxn={close} />

            <Columns spacing="10px" style={{ padding: "0px", height: "90%" }}>
                <Column basis="56%" shrink="1" style={{ margin: "0px 6px", overflowY: "scroll" }}>
                    <AnnotationGroupTable />
                </Column>

                <Column basis="44%" shrink="1" style={{ margin: "0px 6px", overflowY: "scroll" }}>
                    <AnnotationGroupEditor />
                </Column>
            </Columns>
        </div>
    )
}