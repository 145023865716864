import React, { useContext, useRef } from "react"
import { SdDetectionConfig, SdDetectionJSON } from "../../../../Types/SdDetection"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { useRecoilState, useRecoilValue } from "recoil"
import { SdDetectionConfigAtom } from "../../../../Atoms/SdDetection"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { D3SdDetection } from "../D3/D3SdDetection"
import { annotationsAtom, canOpenAnnotationsModalAtom } from "../../../../Atoms/Annotations"
import { useInProgressAnnotation } from "../../TimeSeriesGraphGroup/React/useInProgressAnnotation"
import { Annotation } from "../../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { SDDetectionWindowSettings } from "../../../../../../../../Managers/VisualizationManager/WindowSettings/SDDetectionWindowSettings"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { useD3UpdateVisibleTraces } from "../../../../Hooks/useD3UpdateVisibleTraces"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { useValidateEEGMontage } from "../../../../Hooks/useValidateEEGMontage"
import { useLayoutService } from "../../../../../../../../Hooks/useLayoutService"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"
import { useEventReviewProvider } from "../../../../../../../../Providers/EventReviewProvider"
import { SDTimeSeriesPageManager } from "../../../../Data/SDTimeSeriesPageManager"
import { VisualizationComponent } from "../../../../VisualizationComponent"

type SdDetectionProps = {
    windowId: string,
    json: SdDetectionJSON
}

export const SdDetection = (props: SdDetectionProps) => {
    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 0 }}>
                <SDDetectionWindowSettings windowId={props.windowId} />
            </div>
            <div style={{ flex: 1 }}>
                <DimensionsProvider>
                    <SdDetectionVisualization {...props} />
                </DimensionsProvider>
            </div>
        </div>
    )
}


const SdDetectionVisualization = (props: SdDetectionProps) => {
    const eventReviewProvider = useEventReviewProvider()
    const dimensions = useContext(DimensionsContext)
    const { inProgressAnnotation } = useInProgressAnnotation(props.windowId)
    const { getDataQuerySocket } = useSocketProvider()
    const domNode = useRef<HTMLDivElement>(null)
    const layoutId = useRecoilValue(selectedLayoutIdAtom)
    const visualizationId = { layoutId: layoutId as string, windowId: props.windowId }
    const atom = SdDetectionConfigAtom(visualizationId)
    const [config, setRootConfig] = useRecoilState(atom)
    const { fileStartDate, fileEndDate, montageMapping } = useRecoilValue(currentPatientFileInfoAtom)
    const { modifyDatabaseWindowProperty } = useLayoutService()

    const annotations = useRecoilValue<Annotation[]>(annotationsAtom)
    const enableOpeningAnnotationsModal = useRecoilValue(canOpenAnnotationsModalAtom)

    const [viewStart, viewEnd] = config.viewScale.domain()
    const viewDuration = viewEnd.getTime() - viewStart.getTime()

    const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.SD_DETECTION, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration })

    const d3Controller = useD3Controller<D3SdDetection, SdDetectionConfig, SdDetectionJSON, SDTimeSeriesPageManager>({
        atom,
        nodeRef: domNode,
        initProps: props.json,
        pageManager: pageManagerRegistry.get(visualizationId, VisualizationComponent.SD_DETECTION, new SDTimeSeriesPageManager(), getDataQuerySocket),
        props: {
            id: props.windowId,
            windowType: VisualizationComponent.SD_DETECTION,
            viewScale,
            fileScale: fileScaleRegistry.get(visualizationId, VisualizationComponent.SD_DETECTION, fileStartDate, fileEndDate),
            annotations,
            inProgressAnnotation,
            montageMapping,
            enableOpeningAnnotationsModal,
            currentlyReviewedAnnotation: eventReviewProvider.currentAnnotation,
            hideAnnotations: eventReviewProvider.hideAnnotations
        },
        d3ControllerConstructor(node, config, pageManager, callbacks) {
            return new D3SdDetection(node, config, pageManager, callbacks)
        },
        extractJSON(config) {
            return {
                viewDuration,
                playbackSpeed: config.playbackSpeed,
                montage: config.montage,
                eegConfig: config.eegConfig,
                overlayEEGConfig: config.overlayEEGConfig,
                isLinked: config.isLinked,
                autoDetectionEnabled: config.autoDetectionEnabled
            }
        }
    })

    useD3CheckReloadData({
        d3Controller,
        clearDataIfChanges: {
            dimensions,
            viewDuration,
            eegConfig: {
                LFF: config.eegConfig.LFF,
                HFF: config.eegConfig.HFF,
                notch: config.eegConfig.notch
            },
            overlayEEGConfig: {
                LFF: config.overlayEEGConfig.LFF,
                HFF: config.overlayEEGConfig.HFF,
                notch: config.overlayEEGConfig.notch
            },
            channels: config.montage?.channels.map(({ first, second }) => `${first}-${second}`),
        },
        clearRenderCacheIfChanges: {
            eegSensitivity: config.eegConfig.sensitivityMicroVolts,
            overlaySensitivity: config.overlayEEGConfig.sensitivityMicroVolts,
        }
    })

    useD3UpdateVisibleTraces({
        d3Controller,
        windowId: props.windowId
    })

    useD3KeyboardShortcuts({
        d3Controller,
        windowId: props.windowId,
        shortcuts: [
            hotkeyActions.NEXT_PAGE,
            hotkeyActions.PREVIOUS_PAGE,
            hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
            hotkeyActions.CANCEL_ANNOTATION,
            hotkeyActions.PLAY_PAUSE
        ]
    })

    useValidateEEGMontage({
        montage: config.montage,
        defaultMontageId: 6,
        updateMontage: montage => {
            if (config.hasInitialized) {
                // Update the local state
                setRootConfig(previous => ({ ...previous, montage }))

                // Update the database
                modifyDatabaseWindowProperty("montage", visualizationId.layoutId, visualizationId.windowId, montage)
            }
        }
    })

    return <div ref={domNode} style={{ height: "100%" }} />
}