import React, { useState } from 'react'
import ToolBar from './ToolBar/ToolBar'
import Footer from './Footer/Footer'
import ActionsBar from './ActionsBar/ActionsBar'
import { CreateVizState } from './VisualizationManager'
import { Visualizations } from "../../Pages/Data/Visualize/DataReview/Visualizations"

function VisualizationArea () {
	const [enableSideBar, setEnableSidebar] = useState(false)
	
	return (
		<div style={{background: "#F8F8F8", minHeight: "100%", height: '100%', width: '100%'}}>
			<div style={{display: "flex", position: "relative", zIndex: 2, width: "100%", height: '100%'}}>
					<div style={{height: "100vh", width: "100vw", display: 'flex', flexDirection: 'column', overscrollBehavior: "contain"}}>
						<ToolBar enableSidebar={enableSideBar} />

						<div style={{display: 'flex', height: '100%'}}>
							<div style={{flex: 1}}>
								<Visualizations />
							</div>

							<ActionsBar enableSidebar={enableSideBar} setEnableSidebar={setEnableSidebar} />
						</div>

						<Footer />
					</div>
			</div>
		</div>
	)
}

export default VisualizationArea;
export const useLayouts = CreateVizState('layouts', [])
export const useCurrentDisplay = CreateVizState('currentDisplay', '')
export const useSelectedLayout = CreateVizState('selectedLayout', {})
export const useToolBarSettings = CreateVizState('toolbarSettings', {})
export const usePageSize = CreateVizState('pageSize', {})