import React, { useContext } from "react"
import { MobergButton, MobergColumn, MobergRow, MobergTheme } from "../../../../../../../../Moberg"
import { useEBOOSTEventReviewProvider } from "../../../../../../../../Providers/EBOOSTEventReviewProvider"
import { DimensionsContext } from "../../../../../../../../Providers/DimensionsProvider"
import { useValidationProvider } from "../../../../../../../../Providers/ValidationProvider"

export const EventReviewNextPrevious: React.FC = () => {
    const { firstError } = useValidationProvider()
    const { goToPreviousAnnotation, goToNextAnnotation, saveReviewTask } = useEBOOSTEventReviewProvider()
    const { height } = useContext(DimensionsContext)

    return (
        <MobergColumn verticalAlign="end" horizontalAlign="end" style={{ height, width: "100%", flex: 1 }}>
            <MobergRow expand={false} horizontalAlign="end" verticalAlign="end" style={{ height: "100%", padding: "10px"}}>
                <MobergRow gap="8px">
                    <MobergButton
                        tooltip={"Go to previous event"}
                        theme={MobergTheme.BLACK}
                        disabled={saveReviewTask.isWaiting || firstError !== undefined}
                        onClick={goToPreviousAnnotation}
                    >
                        Previous
                    </MobergButton>

                    <MobergButton
                        tooltip={"Go to next event"}
                        theme={MobergTheme.BLUE}
                        disabled={saveReviewTask.isWaiting || firstError !== undefined}
                        onClick={goToNextAnnotation}
                    >
                        Next
                    </MobergButton>
                </MobergRow>
            </MobergRow>
        </MobergColumn>
    )
}