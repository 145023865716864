import { Selection, EnterElement, select, ScaleTime } from "d3";
import { Annotation } from "../../../../../../../Managers/VisualizationManager/Variables/Annotations";
import { Renderable } from "../../../Types/Renderable";

type D3AnnotationsConfig = {
	fileScale: ScaleTime<any, any, any>
	annotations: Annotation[]
    height: number
	clipPathId: string
}

export class D3timelineAnnotationsWrapper implements Renderable {
    private root: SVGGElement
	private config: D3AnnotationsConfig
	private className = "d3-timeline-annotations-wrapper"

	constructor(root: SVGGElement, config: D3AnnotationsConfig) {
		this.root = root
		this.config = config
		this.render()
	}

	public updateAnnotations = (annotations: Annotation[]) => {
		this.config.annotations = annotations
		this.render()
	}

	public render = () => {
		select(this.root)
			.selectAll("." + this.className)
			.data(this.config.annotations)
			.join(this.enter, this.update, this.exit)
	}

	private enter = (newElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> => {
		const annotations = newElements
            .append("rect")
            .attr("class", this.className)
			.attr("clip-path", `url(#${this.config.clipPathId})`)
			.attr("x", (annotation: Annotation) => this.config.fileScale(annotation.start_time))
			.attr("width", (annotation: Annotation) => Math.max(1, this.config.fileScale(annotation.end_time) - this.config.fileScale(annotation.start_time)))
			.attr("height", this.config.height)
			.attr("fill", (annotation: Annotation) => annotation.color)
			.attr("opacity", 0.15)
            .attr("pointer-events", "none")
			
		return annotations
	}

	private update = (updatedAnnotations: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		return updatedAnnotations
			.attr("x", (annotation: Annotation) => this.config.fileScale(annotation.start_time))
			.attr("width", (annotation: Annotation) => Math.max(1, this.config.fileScale(annotation.end_time) - this.config.fileScale(annotation.start_time)))
			.attr("height", this.config.height)
			.attr("fill", (annotation: Annotation) => annotation.color)
	}

	private exit = (exitedElements: Selection<any, any, any, any>) => {
		exitedElements.remove()
	}
}
