import { useRef, useState } from "react"
import { useOnMount } from "./useOnMount"

export type AsyncTask = { 
	isWaiting: boolean
	error: string | undefined
	run: <T>(...args: T[]) => Promise<any>
}

export const useAsyncTask = (task: (...args: any[]) => Promise<any>) => {
	// Create an asynchronous task that handles its own loading and error state.
	// Provides the run function so that the task can be run at any time.

	const [isWaiting, setIsWaiting] = useState(false)
	const [error, setError] = useState<string | undefined>(undefined)
	const abortControllerRef = useRef<AbortController | null>(null)
	const isMounted = useRef<boolean>()

	const run = <T,>(...args: T[]): Promise<any> => {
		abortControllerRef.current?.abort()
		abortControllerRef.current = new AbortController()

		setIsWaiting(true)
		setError(undefined)

		return new Promise((resolve, reject) => {
			task(abortControllerRef.current?.signal, ...args)
				.then(result => {
					if (isMounted.current) {
						setIsWaiting(false)
					}
					
					resolve(result)
				})
				.catch(err => {
					if (err.name !== "AbortError") {
						console.error(err)
						reject(err)

						if (isMounted.current) {
							setError(err)
							setIsWaiting(false)
						}
					}
				})
		})
	}

	// Abort ongoing task when the component unmounts.
	// We can't update state on an unmounted component.
	useOnMount(() => {
		isMounted.current = true

		return () => {
			isMounted.current = false
			abortControllerRef.current?.abort()
		}
	})

	return { isWaiting, error, run }
}
