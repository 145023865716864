import { useCallback, useEffect } from "react"
import { KeyboardShortcut, hotkeyActions } from "../Types/KeyboardShortcut"
import { currentlyActiveVisualizationAreaAtom } from "../Atoms/Visualizations"
import { useRecoilCallback, useSetRecoilState } from "recoil"
import { D3TimeBasedVisualization } from "../Components/Visualizations/D3TimeBasedVisualization"
import { lastInProgressAnnotationAtom } from "../Atoms/Annotations"
import { useInProgressAnnotation } from "../Components/Visualizations/TimeSeriesGraphGroup/React/useInProgressAnnotation"
import { hotkeysAtom, hotkeysEnabledAtom } from "../Atoms/Hotkeys"
import { authUpdates, useAuthProvider } from "../../../../../Providers/AuthProvider"
import { useOnMount } from "../../../../../Hooks/useOnMount"

type D3KeyboardShortcutsProps = {
	d3Controller?: D3TimeBasedVisualization<any, any, any, any> | undefined
	windowId: string
	shortcuts: string[]
	overrideShortcuts?: Map<string, Function>
}

export const useD3KeyboardShortcuts = (props: D3KeyboardShortcutsProps) => {
	const setLastInProgressAnnotation = useSetRecoilState(lastInProgressAnnotationAtom)
	const { updateAnnotation, cancelAnnotation } = useInProgressAnnotation(props.windowId)
	const authProvider = useAuthProvider()

	useOnMount(() => {
		authProvider.update(authUpdates.TOKEN, authUpdates.PERMISSIONS)
	})

	const hotkeyActionHandler = useCallback(
		(hotkey: KeyboardShortcut, event: KeyboardEvent) => {
			if (!hotkey || !props.shortcuts.includes(hotkey.action)) {
				return
			}

			const activeElement = document.activeElement
			
            const isInputField = activeElement && (
				activeElement.tagName === "INPUT" || 
				activeElement.tagName === "TEXTAREA" || 
				activeElement.getAttribute("contenteditable") === "true"
			)

            if (isInputField) {
                return // Don't execute shortcuts when typing
            }

			const { action, data } = hotkey
			if (props.overrideShortcuts) {
				const overrideAction = props.overrideShortcuts.get(action)
				if (overrideAction) {
					overrideAction(data)
					return
				}
			}

			switch (action) {
				case hotkeyActions.NEXT_PAGE:
					props.d3Controller?.goToNextPage()
					break
				case hotkeyActions.PREVIOUS_PAGE:
					props.d3Controller?.goToPreviousPage()
					break
				case hotkeyActions.ZOOM_IN:
					props.d3Controller?.zoomIn()
					break
				case hotkeyActions.ZOOM_OUT:
					props.d3Controller?.zoomOut()
					break
				case hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS:
					if (!authProvider.permissions?.create_annotations) break

					const lastHoveredDate = (props.d3Controller as any)?.getLastHoveredDate()

					if (!lastHoveredDate) {
						throw new Error("There was a problem updating the in progress annotation.")
					}

					setLastInProgressAnnotation({ ...data, keyPressed: event.key })
					updateAnnotation(event.key, data, lastHoveredDate)
					break
				case hotkeyActions.CANCEL_ANNOTATION:
					cancelAnnotation()
					break
				case hotkeyActions.PLAY_PAUSE:
					props.d3Controller?.playPause()
					break
			}
		},
		[authProvider.permissions?.create_annotations, cancelAnnotation, props.d3Controller, props.overrideShortcuts, props.shortcuts, setLastInProgressAnnotation, updateAnnotation]
	)

	const keyPressed = useRecoilCallback(({ snapshot }) => 
		async (event: KeyboardEvent) => {
			const hotkeysEnabled = await snapshot.getPromise(hotkeysEnabledAtom)
			const hotkeys = await snapshot.getPromise(hotkeysAtom)
			const activeVisualizationArea =  await snapshot.getPromise(currentlyActiveVisualizationAreaAtom)

			if (activeVisualizationArea !== props.windowId || !hotkeysEnabled) {
				return
			}

			const foundConfigs = hotkeys.filter(config => {
				const filteredTriggers = config.triggers.filter(trigger => trigger !== null).map(trigger => (trigger as string).toLowerCase())
				return filteredTriggers.some(trigger => trigger === event.key?.toLowerCase())
			})

			foundConfigs?.forEach(hotkey => hotkeyActionHandler(hotkey, event))
		},
		[hotkeyActionHandler, props.windowId]
	)

	useEffect(() => {
		document.addEventListener("keydown", keyPressed)
		return () => document.removeEventListener("keydown", keyPressed)
	}, [keyPressed])
}
