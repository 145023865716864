import React, { useEffect } from "react";
import { getColors, MobergButton, MobergColumn, MobergDropdown, MobergFontFamily, MobergNumberInput, MobergRow, MobergTheme } from "../../../../../../../../../Moberg";
import { useEBOOSTEventReviewProvider } from "../../../../../../../../../Providers/EBOOSTEventReviewProvider";
import { allWindowsDispatch } from "../../../../../Atoms/Visualizations";
import { WindowClickedEvent } from "../../../../../Types/LinkedWindowInfo";
import { useSetRecoilState } from "recoil";
import { canOpenAnnotationsModalAtom } from "../../../../../Atoms/Annotations";
import { formatTimestamp, useEventReviewProvider } from "../../../../../../../../../Providers/EventReviewProvider";
import { Scrollbar } from "../../../../../../../../../Constants/StyledComponents";
import { useValidationProvider } from "../../../../../../../../../Providers/ValidationProvider";

interface IICPatternFeaturesFormProps {
	style?: React.CSSProperties
}

const ClickToSetDateTimeInput: React.FC = () => {
	const { updateValidationError, errors } = useValidationProvider()
	const red = getColors(MobergTheme.RED).main
	const EBOOSTEventReviewProvider = useEBOOSTEventReviewProvider()
	const eventReviewProvider = useEventReviewProvider()
	const canOpenAnnotationsModal = useSetRecoilState(canOpenAnnotationsModalAtom)
	const validationErrorKey = "Time point of max frequency"
	const errorMessage = errors.get(validationErrorKey)
	const showInfoMessage = EBOOSTEventReviewProvider.isSetDateTimeActive && !errorMessage

	useEffect(() => {
		const startTime = eventReviewProvider.currentAnnotation?.start_time
		const endTime = eventReviewProvider.currentAnnotation?.end_time

		if (!startTime || !endTime) {
			return
		}

		if (EBOOSTEventReviewProvider.isSetDateTimeActive) {
			allWindowsDispatch.on("click", (event: WindowClickedEvent) => {
				const timePointOfMaxFrequency = event.lastHoveredDate?.getTime()
				if (timePointOfMaxFrequency) {
					EBOOSTEventReviewProvider.setTimeOfMaxFrequency(timePointOfMaxFrequency)
					EBOOSTEventReviewProvider.setIsSetDateTimeActive(false)
					updateValidationError(validationErrorKey, undefined)
					canOpenAnnotationsModal(true)
					allWindowsDispatch.on("click", null)
				} else {
					updateValidationError(validationErrorKey, "No time point selected. Click on a valid display to set the time.")
				}
			})

		} else {
			allWindowsDispatch.on("click", null)
		}
	}, [EBOOSTEventReviewProvider, EBOOSTEventReviewProvider.isSetDateTimeActive, canOpenAnnotationsModal, eventReviewProvider.currentAnnotation?.end_time, eventReviewProvider.currentAnnotation?.start_time, updateValidationError])

	function enableClickToSetDatetime() {
		EBOOSTEventReviewProvider.setIsSetDateTimeActive(true)
		canOpenAnnotationsModal(false)
	}

	return (
		<MobergColumn>
			<MobergRow gap="8px">
				<span style={{ fontFamily: MobergFontFamily.REGULAR, whiteSpace: "nowrap" }}><strong>Time point of max frequency</strong></span>

				{EBOOSTEventReviewProvider.timeOfMaxFrequency && (
					<span style={{ fontFamily: MobergFontFamily.REGULAR, whiteSpace: "nowrap" }}>
						{formatTimestamp(EBOOSTEventReviewProvider.timeOfMaxFrequency)}
					</span>
				)}

				<MobergButton theme={MobergTheme.BLUE} onClick={enableClickToSetDatetime}>
					Click to set
				</MobergButton>
			</MobergRow>
			{errorMessage && <p style={{ fontFamily: MobergFontFamily.REGULAR, color: red, flexBasis: "100%", display: "flex" }}>{errorMessage}</p>}
			{showInfoMessage && <p style={{ fontFamily: MobergFontFamily.REGULAR, color: "#000", flexBasis: "100%", display: "flex" }}>Click on a display with a timeline to select a time.</p>}
		</MobergColumn>
	)
}

export const IICPatternFeaturesForm: React.FC<IICPatternFeaturesFormProps> = () => {
	const { updateValidationError } = useValidationProvider()
	const EBOOSTEventReviewProvider = useEBOOSTEventReviewProvider()
	const eventReviewProvider = useEventReviewProvider()
	const locationDropdownOptions = ['Left', 'Right', 'Bilat', 'Bilateral-Independent', 'Multifocal']

	function validatePrevalence(prevalence: number | null): string | undefined {
		return prevalence !== null && (Number.isNaN(prevalence) || prevalence < 0 || prevalence > 100)
			? "Prevalence must be a number between 0 and 100"
			: undefined
	}

	function validateMaxFrequency(maxFrequency: number | null): string | undefined {
		return maxFrequency !== null && (!isRoundedToTenth(maxFrequency) || maxFrequency < 0 || maxFrequency > 5)
			? "Max frequency must be a number between 0 and 5 rounded to the nearest tenth"
			: undefined
	}

	function validatePredominantFrequency(predominantFrequency: number | null): string | undefined {
		return predominantFrequency !== null && (!isRoundedToTenth(predominantFrequency) || predominantFrequency < 0 || predominantFrequency > 5)
			? "Predominant frequency must be a number between 0 and 5 rounded to the nearest tenth"
			: undefined
	}

	const buildInputLabel = (label: string) => (<span style={{ fontFamily: MobergFontFamily.REGULAR, whiteSpace: "nowrap" }}><strong>{label}</strong></span>)

	const annotationId = eventReviewProvider.currentAnnotation?.id

	if (!annotationId) {
		return null
	}

	return (
		<MobergColumn verticalAlign="start" style={{ fontFamily: MobergFontFamily.REGULAR }} gap="4px">
			<Scrollbar style={{ height: "100%" }}>
				<MobergRow expand={false} verticalAlign="start" style={{ flexWrap: "wrap", columnGap: "32px", rowGap: "8px" }}>
					<MobergRow expand={false} gap="8px">
						{buildInputLabel("Location")}
						<MobergDropdown
							selectedValue={EBOOSTEventReviewProvider.location}
							onChange={EBOOSTEventReviewProvider.setLocation}
							options={locationDropdownOptions.map(location => ({ label: location, value: location }))}
							width={195}
						/>
					</MobergRow>

					<MobergRow expand={false} gap="8px">
						{buildInputLabel("Prevalence (%)")}
						<MobergNumberInput
							value={EBOOSTEventReviewProvider.prevalence}
							validationFunction={validatePrevalence}
							onValidationErrorChange={error => updateValidationError("Prevalence", error)}
							onChange={EBOOSTEventReviewProvider.setPrevalence}
						/>
					</MobergRow>

					<MobergRow expand={false} gap="8px">
						{buildInputLabel("Max frequency (Hz)")}
						<MobergNumberInput
							value={EBOOSTEventReviewProvider.maxFrequency}
							validationFunction={validateMaxFrequency}
							onValidationErrorChange={error => updateValidationError("Max Frequency", error)}
							onChange={EBOOSTEventReviewProvider.setMaxFrequency}
						/>
					</MobergRow>

					<MobergRow expand={false} gap="8px">
						{buildInputLabel("Predominant frequency (Hz)")}
						<MobergNumberInput
							value={EBOOSTEventReviewProvider.predominantFrequency}
							validationFunction={validatePredominantFrequency}
							onValidationErrorChange={error => updateValidationError("Predominant Frequency", error)}
							onChange={EBOOSTEventReviewProvider.setPredominantFrequency}
						/>
					</MobergRow>

					<ClickToSetDateTimeInput />
				</MobergRow>
			</Scrollbar>
		</MobergColumn>
	)
}

function isRoundedToTenth(num: number): boolean {
	return Math.round(10 * num) / 10 === num
}
