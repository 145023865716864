import React from "react";
import { MobergColumn, MobergFontFamily, MobergFontSize, MobergRow } from "../../../../../../../../../Moberg";
import CircularCheckboxComponent from "../../../../../../../../../Components/CircularCheckbox/CircularCheckbox";
import { IICPatternType, useEBOOSTEventReviewProvider } from "../../../../../../../../../Providers/EBOOSTEventReviewProvider";
import { useEventReviewProvider } from "../../../../../../../../../Providers/EventReviewProvider";

interface IICPatternTypeFormProps {
    style?: React.CSSProperties
}

export const IICPatternTypeForm: React.FC<IICPatternTypeFormProps> = () => {
    const EBOOSTEventReviewProvider = useEBOOSTEventReviewProvider()
    const eventReviewProvider = useEventReviewProvider()
    
    return (
        <MobergColumn gap="4px">
            <div style={{ fontFamily: MobergFontFamily.REGULAR, whiteSpace: "nowrap", fontSize: MobergFontSize.SMALL }}>
                <strong>Select predominant pattern of NON-SEIZURE epochs</strong>
            </div>
            <MobergRow gap="16px">
                <MobergColumn gap="4px">
                    <CircularCheckboxComponent 
                        key={`LPD-${eventReviewProvider.currentAnnotation?.id}`}
                        style={{color: "#000"}}
                        labelStyle={{ fontSize: MobergFontSize.SMALL }}
                        checked={EBOOSTEventReviewProvider.patternType === IICPatternType.LPD}
                        onChangeFxn={() => EBOOSTEventReviewProvider.setPatternType(IICPatternType.LPD)}
                        checkboxLabel={IICPatternType.LPD}
                    />
                    <CircularCheckboxComponent
                        style={{color: "#000"}}
                        key={`GPD-${eventReviewProvider.currentAnnotation?.id}`}
                        labelStyle={{ fontSize: MobergFontSize.SMALL}}
                        checked={EBOOSTEventReviewProvider.patternType === IICPatternType.GPD}
                        onChangeFxn={() => EBOOSTEventReviewProvider.setPatternType(IICPatternType.GPD)}
                        checkboxLabel={IICPatternType.GPD}
                    />
                </MobergColumn>
                <MobergColumn gap="4px">
                    <CircularCheckboxComponent
                        key={`LRDA-${eventReviewProvider.currentAnnotation?.id}`}
                        style={{color: "#000"}}
                        labelStyle={{ fontSize: MobergFontSize.SMALL }}
                        checked={EBOOSTEventReviewProvider.patternType === IICPatternType.LRDA}
                        onChangeFxn={() => EBOOSTEventReviewProvider.setPatternType(IICPatternType.LRDA)}
                        checkboxLabel={IICPatternType.LRDA}
                    />
                    <CircularCheckboxComponent
                        key={`Other-${eventReviewProvider.currentAnnotation?.id}`}
                        style={{color: "#000"}}
                        labelStyle={{ fontSize: MobergFontSize.SMALL }}
                        checked={EBOOSTEventReviewProvider.patternType === IICPatternType.OTHER}
                        onChangeFxn={() => EBOOSTEventReviewProvider.setPatternType(IICPatternType.OTHER)}
                        checkboxLabel={IICPatternType.OTHER}
                    />
                </MobergColumn>
            </MobergRow> 
        </MobergColumn>
    )
}