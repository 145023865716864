export let admissionDischargeQuestions = [
    [
        {
            groupLabel: "Admission & Discharge",
            prompt: "Admission Date",
            type: "datepicker",
            required: true,
            default: null,
            questionType: "admissionDate",
            identifier: {
                NINDS: "C0019062"
            }
        },
        {
            groupLabel: "Admission & Discharge",
            prompt: "Discharge Date",
            type: "datepicker",
            required: true,
            default: null,
            questionType: "dischargeDate",
            identifier: {
                NINDS: "C0019062"
            }
        },
    ],
    {
        groupLabel: "Admission & Discharge",
        prompt: "Head CT Result",
        type: "dropdown",
        required: true,
        default: "",
        options: [
            { label: "Abnormality Related to DoC", value: "Abnormality Related to DoC" },
            { label: "No abnormality related to DoC", value: "No abnormality related to DoC" },
            { label: "Indeterminate", value: "Indeterminate" },
        ],
        questionType: "headCTResult",
        identifier: {
            NINDS: "C0019062"
        }
    },
    {
        groupLabel: "Admission & Discharge",
        prompt: "Discharge disposition",
        type: "dropdown",
        required: true,
        default: "",
        options: [
            { label: "Discharged", value: "Discharged" },
            { label: "Transferred within the facility", value: "Transferred within the facility" },
            { label: "Transferred to another hospital", value: "Transferred to another hospital" },
            { label: "Died", value: "Died" },
            { label: "No change in patient location", value: "No change in patient location" },
        ],
        questionType: "dischargeDisposition",
        identifier: {
            NINDS: "C0019062"
        }
    }
]

export let GCSQuestions = [
    {
        groupLabel: "GCS on admission",
        prompt: "GCS - Verbal Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "5", "Unknown"],
        questionType: "GCSVerbalResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
    {
        groupLabel: "GCS on admission",
        prompt: "GCS - Eye Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "Unknown"],
        questionType: "GCSEyeResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
    {
        groupLabel: "GCS on admission",
        prompt: "GCS - Motor Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "5", "6", "Unknown"],
        questionType: "GCSMotorResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
]

export let bestGCSQuestions = [
    {
        groupLabel: "Best GCS during admission",
        prompt: "GCS - Verbal Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "5", "Unknown"],
        questionType: "bestGCSVerbalResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
    {
        groupLabel: "Best GCS during admission",
        prompt: "GCS - Eye Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "Unknown"],
        questionType: "bestGCSEyeResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
    {
        groupLabel: "Best GCS during admission",
        prompt: "GCS - Motor Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "5", "6", "Unknown"],
        questionType: "bestGCSMotorResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
]

export let worstGCSQuestions = [
    {
        groupLabel: "Worst GCS during admission",
        prompt: "GCS - Verbal Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "5", "Unknown"],
        questionType: "worstGCSVerbalResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
    {
        groupLabel: "Worst GCS during admission",
        prompt: "GCS - Eye Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "Unknown"],
        questionType: "worstGCSEyeResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
    {
        groupLabel: "Worst GCS during admission",
        prompt: "GCS - Motor Response",
        type: "checkbox",
        required: true,
        default: "",
        options: ["1", "2", "3", "4", "5", "6", "Unknown"],
        questionType: "worstGCSMotorResponse",
        multiple: false,
        identifier: {
            NINDS: "C0019062"
        }
    },
]

export let formJson = {
    formName: "Curing Coma - Patient Information",
    Questions: [
        admissionDischargeQuestions,
        GCSQuestions,
        bestGCSQuestions,
        worstGCSQuestions,
    ]
}