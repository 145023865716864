import { utcHour, utcMinute, utcSecond } from "d3";
import { EEGMontageGraph } from "../../../../Types/MontageGraph";
import { ConfigurationBuilder } from "../../D3ConfigurationBuilder";
import { D3GraphsOverlay, D3GraphsOverlayConfig } from "../../TimeSeriesGraphGroup/D3/D3GraphsOverlay";
import { D3SdDetection } from "./D3SdDetection";
import { EEGCanvasConfig } from "../../EEGMontage/D3/D3EEGCanvas";
import { EEGCanvasWrapperConfig } from "./D3EEGCanvasesWrapper";
import { D3AnnotationsConfig } from "../../../D3/D3Annotations";
import { D3TimelineConfig } from "../../../D3/Timeline/D3Timeline";
import { D3UTCAxisConfig } from "../../../D3/D3UTCAxis";
import { D3EEGYAxisConfig } from "../../EEGMontage/D3/D3BandScaleAxis";
import { GraphType } from "../../../../../../../../Enums/GraphType";
import { RenderStrategy } from "../../../../Types/Trace";
import { DataSource } from "../../../../Types/DataSource";
import { D3AnnotationReviewIndicatorConfig } from "../../EventReview/D3/D3AnnotationReviewIndicator";
import { D3SDEventCanvasConfig } from "./D3SDEventCanvas";

export class D3SdDetectionConfigurationBuilder extends ConfigurationBuilder<D3SdDetection> {
    
    getXAxisConfig = (): D3UTCAxisConfig => {
		return {
			viewScale: this.visualization.config.viewScale,
			fileScale: this.visualization.config.fileScale,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
		}
	}

	getYAxisConfig = (): D3EEGYAxisConfig => ({ 
		scale: this.visualization.channelScale,
		tickValues: this.visualization.mainEEGConfig.channelLabels
	})

	getOverlayConfig = (svg: SVGSVGElement) : D3GraphsOverlayConfig => {
		return {
			boundingBox: this.visualization.overlayBoundingBox,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			svg,
			svgOffsetX: this.visualization.graphBoundingBox.x,
			viewScale: this.visualization.config.viewScale,
			clipPathId: this.getGraphClipPathId(),
			inProgressAnnotation: this.visualization.config.inProgressAnnotation,
		}
	}

	getTimelineConfig = (): D3TimelineConfig => {
		const timelineUpdateTimes = (start: number, end: number) => {
			this.visualization.viewTimesChanged(start, end)
			this.visualization.updateLinkedWindows()
		}

		return {
			id: this.visualization.config.id,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			viewScale: this.visualization.config.viewScale,
			fileScale: this.visualization.config.fileScale,
			width: this.visualization.graphBoundingBox.width,
			annotations: this.visualization.config.annotations,
			playbackSpeed: this.visualization.config.playbackSpeed,
			currentTimelineController: this.visualization.config.timelineController,
			isLinked: this.visualization.config.isLinked,
			timeZone: this.visualization.config.timeZone,
			onDrag: this.visualization.onTimelineSliderDrag,
			onDragEnd: this.visualization.onTimelineSliderDragEnd,
			updateViewTimes: timelineUpdateTimes,
			goToStart: this.visualization.goToStart,
			goToEnd: this.visualization.goToEnd,
			goToNextPage: this.visualization.goToNextPage,
			goToPreviousPage: this.visualization.goToPreviousPage,
		}
	}

	getAnnotationsConfig = (): D3AnnotationsConfig => {
		const graphs: EEGMontageGraph[] = [
			{
				id: this.visualization.config.id,
				name: "SD Detection",
				offset: 0,
				height: this.visualization.graphBoundingBox.height,
				width: this.visualization.graphBoundingBox.width,
				channels: this.visualization.mainEEGConfig.leads,
				channelPairs: this.visualization.mainEEGConfig.channelPairs,
				xScale: this.visualization.config.viewScale,
				renderStrategy: RenderStrategy.LINE
			},
		]

		return {
			type: GraphType.EEG_MONTAGE,
			overlay: this.visualization.getOverlay() as D3GraphsOverlay,
			annotations: this.visualization.config.hideAnnotations ? [] :this.visualization.config.annotations,
			clipPathId: this.getGraphClipPathId(),
			viewScale: this.visualization.config.viewScale,
			boundingBox: this.visualization.overlayBoundingBox,
			graphs,
			canOpenAnnotationsModal: this.visualization.config.enableOpeningAnnotationsModal
		}
	}

	getCurrentAnnotationReviewIndicator = (): D3AnnotationReviewIndicatorConfig => {
		return {
			viewScale: this.visualization.config.viewScale,
			startTime: this.visualization.config.currentlyReviewedAnnotation?.start_time,
			endTime: this.visualization.config.currentlyReviewedAnnotation?.end_time,
			clipPath: this.getGraphClipPathId()
		}
	}

	getMainEEGCanvasConfig = (): EEGCanvasConfig => {
		return {
			graphId: "main",
			dataObjectId: this.visualization.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number,
			viewScale: this.visualization.config.viewScale,
			channelScale: this.visualization.channelScale,
			sensitivityScaleVoltsToPixels: this.visualization.mainEEGSensitivityScale,
			eegModality: this.visualization.mainEEGConfig.eegModality,
			color: "black"
		}
	}

    getOverlayEEGCanvasConfig = (): EEGCanvasConfig => {
		return {
			graphId: "overlay",
			dataObjectId: this.visualization.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number,
			viewScale: this.visualization.config.viewScale,
			channelScale: this.visualization.channelScale,
			sensitivityScaleVoltsToPixels: this.visualization.overlayEEGSensitivityScale,
			eegModality: this.visualization.overlayEEGConfig.eegModality,
			color: "red"
		}
	}

	getEEGCanvasesConfig = (): EEGCanvasWrapperConfig => {
		return {
			canvases: [this.getMainEEGCanvasConfig(), this.getOverlayEEGCanvasConfig()]
		}
	}

	getVerticalLinesConfig = () => {
		const { viewDuration } = this.visualization.getStartTimeEndTimeViewDuration()

		let tickInterval

		if (viewDuration <= 1 * 60 * 1000) {
			tickInterval = utcSecond
		} else if (viewDuration <= 10 * 60 * 1000) {
			tickInterval = utcMinute
		} else {
			tickInterval = utcHour
		}

		return {
			xScale: this.visualization.config.viewScale,
			height: this.visualization.graphBoundingBox.height,
			color: "gray",
			opacity: 0.5,
			tickInterval: tickInterval,
		}
	}

	getGraphClipPathId = () => `sd-detection-clip-path-${this.visualization.config.id}`

	getSDEventCanvasConfig = (): D3SDEventCanvasConfig => ({
		channelScale: this.visualization.channelScale,
		viewScale: this.visualization.config.viewScale,
		dataObjectId: this.visualization.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) ?? Infinity,
		eventExpansionMinutes: 5,
		autoDetectionEnabled: this.visualization.config.autoDetectionEnabled
	})
}