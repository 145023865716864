import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AnnotationReview, AnnotationReviews } from "../Pages/Data/Visualize/DataReview/Components/Visualizations/EventReview/React/EventReview";
import { Annotation } from "../Managers/VisualizationManager/Variables/Annotations";
import { SetterOrUpdater, useRecoilState, useRecoilValue } from "recoil";
import { annotationsAtom, hideAnnotationsAtom } from "../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { useBackendLinksProvider } from "./BackendLinksProvider";
import { useEndpointProvider } from "./EndpointProvider";
import { selectedLayoutIdAtom } from "../Pages/Data/Visualize/DataReview/Atoms/Layout";
import { EventReviewConfig, eventReviewConfigsAtom } from "../Pages/Data/Visualize/DataReview/Atoms/EventReview";

export type EventReviewContextValue = {
    showMoreMetadata: boolean
    setShowMoreMetadata: Dispatch<SetStateAction<boolean>>
    currentAnnotationReview: AnnotationReview | undefined 
    updateCurrentAnnotationReview: (annotationReview: AnnotationReview | undefined) => void
    currentAnnotation: Annotation | undefined
    setCurrentAnnotation: Dispatch<SetStateAction<Annotation | undefined>>
    totalEvents: number
    annotationReviews: AnnotationReviews
    setAnnotationReviews: Dispatch<SetStateAction<AnnotationReviews>>
    sortedAnnotations: Annotation[]
    annotationIndex: number
    addAnnotationReviewEntry: (reviewJSON: AnnotationReview) => Promise<any>
    hideAnnotations: boolean
    setHideAnnotations: Dispatch<SetStateAction<boolean>>
    showIncompleteEventsOnly: boolean
    setShowIncompleteEventsOnly: Dispatch<SetStateAction<boolean>>
    showAllEvents: boolean
    setShowAllEvents: Dispatch<SetStateAction<boolean>>
    unmodifiedAnnotationReview: AnnotationReview | undefined
    setFilters: SetterOrUpdater<EventReviewConfig>
    isRequestingReviews: boolean
}

function sortAnnotations(rawAnnotations: Annotation[]) {
    const annotationsCopy = [...rawAnnotations]
    annotationsCopy.sort((annotation1, annotation2) => {
        if (annotation1.start_time < annotation2.start_time) {
            return -1
        }
        return 1
    })
    return annotationsCopy
}

const EventReviewContext = createContext<EventReviewContextValue>({} as any)

export function formatTimestamp(startTimeInMilliseconds: number): string {
    const options: Intl.DateTimeFormatOptions & { fractionalSecondDigits: number, hourCycle: string } = {
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        fractionalSecondDigits: 3,
        hourCycle: "h23"
    }

    return new Intl.DateTimeFormat("en-us", options).format(new Date(startTimeInMilliseconds))
}

export const EventReviewProvider: React.FC = ({ children }) => {
    const backendLinksProvider = useBackendLinksProvider()
    const endpointProvider = useEndpointProvider()
    const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
    const [showMoreMetadata, setShowMoreMetadata] = useState(false)
    const [showAllEvents, setShowAllEvents] = useState(false)
    const [currentAnnotationReview, setCurrentAnnotationReview] = useState<AnnotationReview | undefined>()
    const [currentAnnotation, setCurrentAnnotation] = useState<Annotation | undefined>()
    const [showIncompleteEventsOnly, setShowIncompleteEventsOnly] = useState(false)
    const [unmodifiedAnnotationReview, setUnmodifiedAnnotationReview] = useState<AnnotationReview | undefined>()
    const [hideAnnotations, setHideAnnotations] = useRecoilState(hideAnnotationsAtom(selectedLayoutId ?? ""))
    const [annotationReviews, setAnnotationReviews] = useState<AnnotationReviews>([])
    const [filters, setFilters] = useRecoilState(eventReviewConfigsAtom(selectedLayoutId ?? ""))
    const [isRequestingReviews, setIsRequestingReviews] = useState(false)

    const annotations = useRecoilValue(annotationsAtom)
    const filteredAnnotations = getFilteredAnnotations()
    const sortedAnnotations = sortAnnotations(filteredAnnotations)

    const annotationIndex = sortedAnnotations.findIndex((annotation: Annotation) => annotation.id === currentAnnotation?.id)
    const totalEvents = sortedAnnotations.length

    useEffect(() => {
        setIsRequestingReviews(true)

        endpointProvider
            .post(backendLinksProvider.LINKS.DATA.PROFILING.GET_ANNOTATION_REVIEWS, { annotation_ids: annotations.map(a => a.id) })
            .then(reviews => {
                setAnnotationReviews(reviews)

                // Look for an existing review for the annotation to replace the current one, 
                // in case we've created a default review in the meantime.
                const foundReview = reviews.find((r: AnnotationReview) => r.annotation_id === currentAnnotation?.id)

                if (foundReview) {
                    updateCurrentAnnotationReview(foundReview)
                }
            })
            .finally(() => setIsRequestingReviews(false))
    // If the annotations change, we need to re-request the reviews. 
    // There isn't really a way around this if we want to filter the reviews that we request by annotation ID.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annotations, backendLinksProvider, endpointProvider])

    function addAnnotationReviewEntry(reviewJSON: AnnotationReview) {
        // While we are adding things to the database, we only care about keeping the latest review for each annotation.
        // If there is a current review for the current annotation, update the existing one.
        // Otherwise, add a new annotation review for that annotation.
        updateCurrentAnnotationReview(reviewJSON)
        setAnnotationReviews(previous => previous.map(review => (review.annotation_id === reviewJSON.annotation_id) ? reviewJSON : review))
        return endpointProvider.post(backendLinksProvider.LINKS.DATA.PROFILING.ADD_ANNOTATION_REVIEW, {'annotation_review': reviewJSON})
    }

    function updateCurrentAnnotationReview(annotationReview: AnnotationReview | undefined) {
        setCurrentAnnotationReview(annotationReview)
        setUnmodifiedAnnotationReview(annotationReview)
    }

    function getFilteredAnnotations() {
        let filtered = [...annotations]
        const { includeTags, excludeTags } = filters

        if (includeTags?.length > 0) {
            const includeSet = new Set(includeTags.map(t => t.toLowerCase()))
            filtered = filtered.filter(annotation => annotation.tags.some(tag => includeSet.has(tag.toLowerCase())))
        }

        if (excludeTags?.length > 0) {
            const excludeSet = new Set(excludeTags.map(t => t.toLowerCase()))
            filtered = filtered.filter(annotation => !annotation.tags.some(tag => excludeSet.has(tag.toLowerCase())))
        }

        return filtered
    }

    // Make sure the current annotation is found in the list of annotations that we want to review!
    useEffect(() => {
        if (!currentAnnotation) {
            return
        }

        const foundAnnotation = sortedAnnotations.find(annotation => annotation.id === currentAnnotation.id)

        if (!foundAnnotation) {
            setCurrentAnnotation(undefined)
            setCurrentAnnotationReview(undefined)
        }
    }, [currentAnnotation, sortedAnnotations])


    // Make sure the list of annotation reviews only contains reviews for the annotations in the list
    useEffect(() => {
        const annotationIds = new Set(annotations.map(a => a.id))
        setAnnotationReviews(previous => previous.filter(review => annotationIds.has(review.annotation_id)))
    }, [annotations])


    return (
        <EventReviewContext.Provider 
            value={{showMoreMetadata, setShowMoreMetadata, showAllEvents, setShowAllEvents, currentAnnotationReview, updateCurrentAnnotationReview, sortedAnnotations,
                totalEvents, annotationReviews, setAnnotationReviews, currentAnnotation, setCurrentAnnotation, annotationIndex, 
                addAnnotationReviewEntry, hideAnnotations, setHideAnnotations, showIncompleteEventsOnly, setShowIncompleteEventsOnly, unmodifiedAnnotationReview,
                setFilters, isRequestingReviews }}
        >
            {children}
        </EventReviewContext.Provider>
    )
}

export const useEventReviewProvider = () => useContext(EventReviewContext)