import React, { useContext } from "react";
import { MobergColumn, MobergFontFamily, MobergFontSize, MobergRow } from "../../../../../../../../../Moberg";
import { formatTimestamp, useEventReviewProvider } from "../../../../../../../../../Providers/EventReviewProvider";
import { EventReviewSeeAllEventsWindow } from "./EventReviewSeeAllEvents";
import { useEBOOSTEventReviewProvider } from "../../../../../../../../../Providers/EBOOSTEventReviewProvider";
import { DurationUnit, fromMilliseconds, getFormattedDuration } from "../../../../../Hooks/useDuration";
import { DimensionsContext } from "../../../../../../../../../Providers/DimensionsProvider";
import { Scrollbar } from "../../../../../../../../../Constants/StyledComponents";
import { useValidationProvider } from "../../../../../../../../../Providers/ValidationProvider";

interface EventReviewMetadataWindowProps {
    style?: React.CSSProperties
}

export const DefaultMetadataWindow: React.FC = () => {
    const { firstError } = useValidationProvider()
    const eventReviewProvider = useEventReviewProvider()
    const EBOOSTEventReviewProvider = useEBOOSTEventReviewProvider()
    const { height } = useContext(DimensionsContext)
    const annotationDuration = eventReviewProvider.currentAnnotationReview ? getAnnotationDuration() : "N/A"
    const annotationName = eventReviewProvider.currentAnnotationReview ? getAnnotationName() : "N/A"
    const annotationReviewer = eventReviewProvider.currentAnnotationReview ? getAnnotationReviewer() : "N/A"
    const annotationReviewTimestamp = eventReviewProvider.currentAnnotationReview ? getAnnotationReviewTimestamp() : "N/A"
    const annotationStartTime = eventReviewProvider.currentAnnotationReview ? getAnnotationStartTime() : "N/A"
    const rowHeight = eventReviewProvider.showMoreMetadata ? "19px" : "28px"
    const fontSize = eventReviewProvider.showMoreMetadata ? MobergFontSize.SMALL : MobergFontSize.LARGE
    const rowStyle = { height: rowHeight }

    function getAnnotationDuration() {
        const currentAnnotation = eventReviewProvider.currentAnnotation
        const startTime = currentAnnotation?.start_time
        const endTime = currentAnnotation?.end_time

        if (!startTime || !endTime) {
            return
        }

        return getFormattedDuration(fromMilliseconds(endTime - startTime, [DurationUnit.MILLISECONDS, DurationUnit.SECONDS, DurationUnit.MINUTES]))
    }

    function getAnnotationName() {
       return eventReviewProvider.currentAnnotation?.text
    }

    function getAnnotationReviewer() {
        return eventReviewProvider.currentAnnotationReview?.user_email ?? "unknown"
    }

    function getAnnotationReviewTimestamp() {
        const timestamp = eventReviewProvider.currentAnnotationReview?.timestamp

        if (!timestamp) {
            return
        }

        return formatTimestamp(timestamp)
    }

    function getAnnotationStartTime() {
        const currentAnnotation = eventReviewProvider.currentAnnotation
        const startTime = currentAnnotation?.start_time

        if (!startTime) {
            return
        }

        return formatTimestamp(startTime)
    }

    return (
        <MobergColumn verticalAlign="start" style={{ fontFamily: MobergFontFamily.REGULAR, fontSize, padding: "10px", minWidth: "250px", height: height, overflowY: "auto", borderRight: "1px solid lightgray" }}>
            <Scrollbar style={{ height: "100%" }}>
                <MobergRow horizontalAlign="space-between" style={{...rowStyle, position: "relative"}}>
                    <div><strong>Completed:</strong> {EBOOSTEventReviewProvider.reviewedEventIds.size} of {eventReviewProvider.totalEvents} </div>
                </MobergRow>

                <MobergRow horizontalAlign="space-between" style={rowStyle}>
                    <div><strong>Event:</strong> {eventReviewProvider.annotationIndex > -1 ? eventReviewProvider.annotationIndex + 1 : "N/A"} of {eventReviewProvider.totalEvents}</div>
                </MobergRow>

                <MobergRow style={{...rowStyle, fontWeight: "bold" }} gap="8px">
                    <span>Status:</span>
                    {EBOOSTEventReviewProvider.isComplete && !firstError
                        ? <span style={{ color: "green" }}>Complete</span> 
                        : <span style={{ color: "red" }}>Incomplete</span>
                    }
                </MobergRow>

                {eventReviewProvider.showMoreMetadata && (<>
                    <MobergRow style={rowStyle}><div><strong>Name:</strong> {annotationName} </div></MobergRow>
                    <MobergRow style={rowStyle}><div><strong>Start time:</strong> {annotationStartTime} </div></MobergRow>
                    <MobergRow style={rowStyle}><div><strong>Duration:</strong> {annotationDuration} </div></MobergRow>
                    <MobergRow style={rowStyle}><div><strong>User:</strong> {annotationReviewer} </div></MobergRow>
                    <MobergRow style={rowStyle}><div><strong>Last modified:</strong> {annotationReviewTimestamp} </div></MobergRow>
                </>)}
            </Scrollbar>
        </MobergColumn>
    )
}

export const EventReviewMetadataWindow: React.FC<EventReviewMetadataWindowProps> = () => {
    const eventReviewProvider = useEventReviewProvider()

    return (
        <MobergRow expand={false}>
            <DefaultMetadataWindow />
            {eventReviewProvider.showAllEvents && <EventReviewSeeAllEventsWindow />}
        </MobergRow>
    )
}