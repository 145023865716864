import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { currentPatientFileInfoAtom } from "../../../Atoms/PatientFile"
import { getColors, MobergColumn, MobergDropdown, MobergIconSize, MobergInputLabel, MobergNumberInput, MobergRow, MobergTheme } from "../../../../../../../Moberg"
import { Analytic, PRxAnalysis } from "../../../Types/AnalysisDetails"
import { DurationUnit, fromMilliseconds, getDurationUnitRange, getFormattedDuration, toMilliseconds, useDuration } from "../../../Hooks/useDuration"
import { InfoMessage } from "../../../../../../../Constants/StyledComponents"
import { MdInfoOutline } from "react-icons/md"

type EditPRxProps = {
	initialState: PRxAnalysis
	onChange: (analysis: PRxAnalysis) => void
}

export const EditPRx: React.FC<EditPRxProps> = ({ initialState, onChange }) => {
	const { patientModalities } = useRecoilValue(currentPatientFileInfoAtom)
	const sortedPatientModalities = [...patientModalities].sort()

	const [ABP, setABPModality] = useState<string>(initialState.ABP)
	const [ICP, setICPModality] = useState<string>(initialState.ICP)

	const [calculationWindow, updateCalculationWindow] = useDuration(fromMilliseconds(initialState.calculationWindowMs, getDurationUnitRange(DurationUnit.SECONDS, DurationUnit.HOURS)))
	const [calculationPeriod, updateCalculationPeriod] = useDuration(fromMilliseconds(initialState.calculationPeriodMs, getDurationUnitRange(DurationUnit.SECONDS, DurationUnit.MINUTES)))
	const [validationErrors, setValidationErrors] = useState(new Map<string, string | undefined>())

	const formIsInvalid = Object.values(validationErrors).filter(err => err !== undefined).length > 0 || isNaN(calculationPeriod.value) || isNaN(calculationWindow.value)

	const validateDurationValue = (value: number, units: DurationUnit): string | undefined => {
		if (value <= 0) {
			return "The duration must be greater than 0."
		}

		if (toMilliseconds(value, units) < 1000) {
			return "The duration cannot be less than 1 second"
		}

		return undefined
	}

	const updateValidationError = (key: string, error: string | undefined) => {
		setValidationErrors(previous => {
			const next = new Map(previous)
			next.set(key, error)
			return next
		})
	}

	useEffect(() => {
		if (formIsInvalid) {
			return
		}

		onChange({
			analytic: Analytic.PRx,
			ABP,
			ICP,
			calculationPeriodMs: calculationPeriod.milliseconds,
			calculationWindowMs: calculationWindow.milliseconds,
		})
	})

	return (
		<MobergColumn gap="16px">
			<MobergColumn gap="8px">
				<MobergInputLabel text={"Calculation Window"} />
				<MobergRow gap="16px">
					<div style={{ width: "120px" }}>
						<MobergNumberInput
							defaultValue={initialState.calculationWindowMs}
							value={calculationWindow.value}
							onChange={newValue => updateCalculationWindow(newValue ?? 1, calculationWindow.units)}
							validationFunction={value => validateDurationValue(value, calculationWindow.units)}
							onValidationErrorChange={error => updateValidationError("calculation window", error)}
							style={{ width: "112px" }}
						/>
					</div>

					<MobergDropdown
						options={getDurationUnitRange(DurationUnit.SECONDS, DurationUnit.HOURS).map((unit: DurationUnit) => ({ label: unit, value: unit }))}
						onChange={newUnits => updateCalculationWindow(calculationWindow.value, newUnits)}
						selectedValue={calculationWindow.units}
					/>
				</MobergRow>
			</MobergColumn>

			<MobergColumn gap="8px">
				<MobergInputLabel text={"Calculate every"} />
				<MobergRow gap="16px">
					<div style={{ width: "120px" }}>
						<MobergNumberInput
							defaultValue={initialState.calculationPeriodMs}
							value={calculationPeriod.value}
							onChange={newValue => updateCalculationPeriod(newValue ?? 1, calculationPeriod.units)}
							validationFunction={value => validateDurationValue(value, calculationPeriod.units)}
							onValidationErrorChange={error => updateValidationError("calculation period", error)}
							style={{ width: "112px" }}
						/>
					</div>

					<MobergDropdown
						options={getDurationUnitRange(DurationUnit.SECONDS, DurationUnit.MINUTES).map((unit: DurationUnit) => ({ label: unit, value: unit }))}
						onChange={newUnits => updateCalculationPeriod(calculationPeriod.value, newUnits)}
						selectedValue={calculationPeriod.units}
					/>
				</MobergRow>
			</MobergColumn>

			<MobergColumn gap="8px">
				<MobergInputLabel text={"ABP"} />

				<MobergDropdown
					options={sortedPatientModalities.map(modality => ({ label: modality, value: modality }))}
					onChange={newValue => setABPModality(newValue)}
					selectedValue={ABP}
				/>
			</MobergColumn>

			<MobergColumn gap="8px">
				<MobergInputLabel text={"ICP"} />

				<MobergDropdown
					options={sortedPatientModalities.map(modality => ({ label: modality, value: modality }))}
					onChange={newValue => setICPModality(newValue)}
					selectedValue={ICP}
				/>
			</MobergColumn>

			<InfoMessage style={{ gap: "8px", color: getColors(MobergTheme.BLUE).main }}>
				<span>
					<MdInfoOutline size={MobergIconSize.REGULAR} />
				</span>
				<div>
					If the viewing window is smaller than
					<span style={{ fontWeight: "bold" }}> {getFormattedDuration(calculationPeriod)}, </span>
					PRx will be calculated more frequently to ensure continuous display.
				</div>
			</InfoMessage>
		</MobergColumn>
	)
}
