import { VisualizationComponent } from "../VisualizationComponent"

export enum LinkedWindowEventType {
    JUMP_START = "jump_start", // move the window's start time to the time provided in the event
    JUMP_MIDDLE = "jump_middle", // move the window's middle time to the time provided in the event
    JUMP_END = "jump_end", // move the window's end time to the time provided in the event
    CLICKED = "clicked" // An event that fires when a window is clicked
}

export type LinkedWindowEvent = {
    controller: string
    options?: LinkedWindowEventOptions
}

export type LinkedWindowEventOptions = {
    autoScale: boolean
    windowTypes?: Array<VisualizationComponent> // Only affect these window types
}

export type LinkedWindowJumpEvent = LinkedWindowEvent & {
    type: LinkedWindowEventType
    date: Date
}

export type WindowClickedEvent = LinkedWindowEvent & {
    type: LinkedWindowEventType.CLICKED
    lastHoveredDate: Date | undefined
}
