import { Selection, EnterElement, select } from "d3"
import { D3VisualizationRenderer } from "../../D3VisualizationRenderer"
import { D3PersystTrendsWindow } from "./D3PersystTrendsWindow"
import { D3PersystWindowConfigurationBuilder } from "./D3PersystTrendsWindowConfigurationBuilder"
import { D3UTCAxis } from "../../../D3/D3UTCAxis"
import { D3Timeline } from "../../../D3/Timeline/D3Timeline"
import { PersystWindowConfig } from "../../../../Types/PersystWindow"
import { D3GraphsOverlay } from "../../TimeSeriesGraphGroup/D3/D3GraphsOverlay"
import { D3AnnotationsWrapper } from "../../../D3/D3Annotations"
import { D3ClipPath } from "../../../D3/D3ClipPath"
import { D3ImageCanvas } from "../../../D3/D3ImageCanvas"
import { ImagePage } from "../../../../Data/ImagePage"
import { D3ImageOverlay } from "../../../D3/D3ImageOverlay"
import { D3AnnotationReviewIndicator } from "../../EventReview/D3/D3AnnotationReviewIndicator"
import { attachD3WheelAndDragListeners } from "../../mouseEvents"

export class D3PersystWindowRenderer extends D3VisualizationRenderer<D3PersystTrendsWindow, D3PersystWindowConfigurationBuilder> {
    private timelineContainerClassName = "d3-persyst-timeline"
    private boundingBoxClassName = "d3-persyst-window-bounding-box"
    private timelineSpacing = 30

    // Children
    public xAxis?: D3UTCAxis
    public overlay?: D3GraphsOverlay
    public annotationsWrapper?: D3AnnotationsWrapper
    public graphClipPath?: D3ClipPath
    public overlayClipPath?: D3ClipPath
    public imageCanvas?: D3ImageCanvas
    public titles?: D3ImageOverlay
    public labels?: D3ImageOverlay
    public currentAnnotationReviewIndicator?: D3AnnotationReviewIndicator
    
    public viewTimesChanged = () => { 
        this.xAxis?.render()
        this.timeline?.viewTimesChanged()
        this.annotationsWrapper?.render()
        this.imageCanvas?.render()
        this.overlay?.render()
        this.currentAnnotationReviewIndicator?.render()
    }

    public onTimelineSliderDrag = () => {
        requestAnimationFrame(() => {
            this.xAxis?.render()
            this.annotationsWrapper?.render()
            this.imageCanvas?.render()
            this.currentAnnotationReviewIndicator?.render()
        })
    }

    private createCurrentAnnotationReviewIndicator = (config: D3PersystTrendsWindow, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.currentAnnotationReviewIndicator = new D3AnnotationReviewIndicator(root, this.configBuilder.getCurrentAnnotationReviewIndicator(), "currentAnnotationReviewIndicator", this.visualization.reactCallbacks)
		this.currentAnnotationReviewIndicator.render()
	}

    public updateChildren = (svg: SVGSVGElement) => {
        this.xAxis?.updateConfig(this.configBuilder.getXAxisConfig())
        this.overlay?.updateConfig(this.configBuilder.getOverlayConfig(svg))
        this.timeline?.updateConfig(this.configBuilder.getTimelineConfig())
        this.annotationsWrapper?.updateConfig(this.configBuilder.getAnnotationsConfig())
        this.graphClipPath?.updateConfig(this.configBuilder.getGraphClipPathConfig())
        this.overlayClipPath?.updateConfig(this.configBuilder.getOverlayClipPathConfig())
        this.imageCanvas?.updateConfig(this.configBuilder.getPersystCanvasConfig())
        this.titles?.updateConfig(this.configBuilder.getTitlesConfig())
        this.labels?.updateConfig(this.configBuilder.getLabelsConfig())
        this.currentAnnotationReviewIndicator?.updateConfig(this.configBuilder.getCurrentAnnotationReviewIndicator())
    }

    public getLastHoveredDate = () => this.overlay?.getLastHoveredDate()

    public renderPage = (page: ImagePage) => {
        this.imageCanvas?.renderPage(page)
    }

    public onTitlesAndLabelsLoaded = () => {
        this.titles?.updateConfig(this.configBuilder.getTitlesConfig())
        this.labels?.updateConfig(this.configBuilder.getLabelsConfig())
    }

	protected enter(enterElements: Selection<EnterElement, any, any, any>): Selection<any, any, any, any> {
		const svg = enterElements.append("svg").attr("class", this.className).attr("width", "100%").attr("height", "100%")

		const boundingBox = svg
			.append("g")
			.attr("class", this.boundingBoxClassName)
			.attr("transform", `translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y})`)

        boundingBox.each((config, index, nodes) => this.createBoundingBoxChildren(config, index, nodes, svg.node() as SVGSVGElement))

        const currentAnnotationReviewIndicator = svg
            .append("g")
            .attr("class", "currentAnnotationReviewIndicatorContainer")
            .attr("transform", `translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y})`)

        currentAnnotationReviewIndicator.each(this.createCurrentAnnotationReviewIndicator)
		
        const timelineContainer = svg
			.append("g")
			.attr("class", this.timelineContainerClassName)
			.attr("transform", `translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y + this.visualization.boundingBox.height + this.timelineSpacing})`)

		timelineContainer.each(this.createTimeline)
        svg.each((config, i, nodes) => this.createClipPath(config, i, nodes))

        attachD3WheelAndDragListeners(svg, this.visualization, this.overlay!)

		return svg
	}

	protected update(updateElements: Selection<any, any, any, any>): Selection<any, any, any, any> {
		const svg = updateElements

        svg.select("." + this.timelineContainerClassName).attr(
			"transform",
			`translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y + this.visualization.boundingBox.height + this.timelineSpacing})`
		)

        const currentAnnotationReviewIndicator = svg.select(".currentAnnotationReviewIndicatorContainer")
		
        currentAnnotationReviewIndicator.attr("transform", `translate(${this.visualization.boundingBox.x}, ${this.visualization.boundingBox.y})`)

        svg.each(() => this.updateChildren(svg.node()))

		return svg
	}

	protected canRender(): boolean {
		return this.visualization.boundingBox.height > 0 && this.visualization.boundingBox.width > 0
	}

    private createBoundingBoxChildren = (config: D3PersystTrendsWindow, index: number, nodes: ArrayLike<SVGGElement>, svg: SVGSVGElement) => {
        const root = nodes[index]

        const layer1 = select(root).append("g").node() as SVGGElement
        const layer2 = select(root).append("g").node() as SVGGElement
        const layer3 = select(root).append("g").node() as SVGGElement

        this.xAxis = new D3UTCAxis(layer1, this.configBuilder.getXAxisConfig(), this.visualization.reactCallbacks)
        this.imageCanvas = new D3ImageCanvas(layer1, this.configBuilder.getPersystCanvasConfig(), this.visualization.timeSeriesPageManager as any, this.visualization.reactCallbacks)
        this.titles = new D3ImageOverlay(layer2, this.configBuilder.getTitlesConfig(), "d3-persyst-titles", this.visualization.reactCallbacks)
        this.labels = new D3ImageOverlay(layer2, this.configBuilder.getLabelsConfig(), "d3-persyst-labels", this.visualization.reactCallbacks)
		this.overlay = new D3GraphsOverlay(layer3, this.configBuilder.getOverlayConfig(svg), this.visualization.reactCallbacks)
		this.annotationsWrapper = new D3AnnotationsWrapper(layer3, this.configBuilder.getAnnotationsConfig(), this.visualization.reactCallbacks)
    }

    private createTimeline = (config: PersystWindowConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.timeline = new D3Timeline(root, this.configBuilder.getTimelineConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
	}

    private createClipPath = (config: PersystWindowConfig, index: number, nodes: ArrayLike<SVGSVGElement>) => {
		const root = nodes[index]
		this.graphClipPath = new D3ClipPath(root, this.configBuilder.getGraphClipPathConfig(), this.visualization.reactCallbacks)
		this.overlayClipPath = new D3ClipPath(root, this.configBuilder.getOverlayClipPathConfig(), this.visualization.reactCallbacks)
	}

}
