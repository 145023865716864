import { ImageGraph } from "../../../../Types/ImageGraph"
import { D3AnnotationsConfig } from "../../../D3/D3Annotations"
import { D3ClipPathConfig } from "../../../D3/D3ClipPath"
import { D3UTCAxisConfig } from "../../../D3/D3UTCAxis"
import { D3VerticalLinesConfig } from "../../../D3/D3VerticalLines"
import { D3TimelineConfig } from "../../../D3/Timeline/D3Timeline"
import { ConfigurationBuilder } from "../../D3ConfigurationBuilder"
import { D3GraphsOverlay, D3GraphsOverlayConfig } from "../../TimeSeriesGraphGroup/D3/D3GraphsOverlay"
import { D3ImageCanvasConfig } from "../../../D3/D3ImageCanvas"
import { D3PersystEEGWindow } from "./D3PersystEEGWindow"
import { D3ImageOverlayConfig } from "../../../D3/D3ImageOverlay"
import { RenderStrategy } from "../../../../Types/Trace"
import { DataSource } from "../../../../Types/DataSource"
import { LEFT_MARGIN } from "../../Constants"
import { GraphType } from "../../../../../../../../Enums/GraphType"
import { D3AnnotationReviewIndicatorConfig } from "../../EventReview/D3/D3AnnotationReviewIndicator"

export class D3PersystEEGWindowConfigurationBuilder extends ConfigurationBuilder<D3PersystEEGWindow> {
	public getXAxisConfig = (): D3UTCAxisConfig => ({
		liveModeEnabled: this.visualization.config.liveModeEnabled,
		viewScale: this.visualization.config.viewScale,
		fileScale: this.visualization.config.fileScale,
	})

	public getTimelineConfig = (): D3TimelineConfig => {
		const timelineUpdateTimes = (start: number, end: number) => {
			this.visualization.viewTimesChanged(start, end)
			this.visualization.updateLinkedWindows()
		}

		return {
			id: this.visualization.config.id,
			liveModeEnabled: this.visualization.config.liveModeEnabled,
			viewScale: this.visualization.config.viewScale,
			fileScale: this.visualization.config.fileScale,
			width: this.visualization.boundingBox.width,
			annotations: this.visualization.config.annotations,
			playbackSpeed: this.visualization.config.playbackSpeed,
			currentTimelineController: this.visualization.config.timelineController,
			isLinked: this.visualization.config.isLinked,
			timeZone: this.visualization.config.timeZone,
			onDrag: this.visualization.onTimelineSliderDrag,
			onDragEnd: this.visualization.onTimelineSliderDragEnd,
			updateViewTimes: timelineUpdateTimes,
			goToStart: this.visualization.goToStart,
			goToEnd: this.visualization.goToEnd,
			goToNextPage: this.visualization.goToNextPage,
			goToPreviousPage: this.visualization.goToPreviousPage,
		}
	}

	public getOverlayConfig = (svg: SVGSVGElement): D3GraphsOverlayConfig => ({
		boundingBox: this.visualization.overlayBoundingBox,
		liveModeEnabled: this.visualization.config.liveModeEnabled,
		svg,
		svgOffsetX: this.visualization.boundingBox.x,
		viewScale: this.visualization.config.viewScale,
		clipPathId: this.getOverlayClipPathId(),
		inProgressAnnotation: this.visualization.config.inProgressAnnotation,
	})

	public getVerticalLinesConfig = (): D3VerticalLinesConfig => ({
		xScale: this.visualization.config.viewScale,
		height: this.visualization.boundingBox.height,
	})

	public getGraphClipPathConfig = (): D3ClipPathConfig => ({
		id: this.getGraphClipPathId(),
		boundingBox: this.visualization.boundingBox,
	})

	public getOverlayClipPathConfig = (): D3ClipPathConfig => ({
		id: this.getOverlayClipPathId(),
		boundingBox: this.visualization.overlayBoundingBox,
	})

	public getAnnotationsConfig = (): D3AnnotationsConfig => {
		const graphs: ImageGraph[] = [
			{
				id: this.visualization.config.id,
				name: "Persyst EEG",
				offset: 0,
				height: this.visualization.boundingBox.height,
				width: this.visualization.boundingBox.width,
				xScale: this.visualization.config.viewScale,
				renderStrategy: RenderStrategy.IMAGE
			},
		]

		return {
			type: GraphType.IMAGE,
			overlay: this.visualization.getOverlay() as D3GraphsOverlay,
			annotations: this.visualization.config.hideAnnotations ? [] : this.visualization.config.annotations,
			clipPathId: this.getGraphClipPathId(),
			viewScale: this.visualization.config.viewScale,
			boundingBox: this.visualization.overlayBoundingBox,
			graphs,
			canOpenAnnotationsModal: this.visualization.config.enableOpeningAnnotationsModal
		}
	}

	getCurrentAnnotationReviewIndicator = (): D3AnnotationReviewIndicatorConfig => {
		return {
			viewScale: this.visualization.config.viewScale,
			startTime: this.visualization.config.currentlyReviewedAnnotation?.start_time,
			endTime: this.visualization.config.currentlyReviewedAnnotation?.end_time,
			clipPath: this.getGraphClipPathId()
		}
	}

    public getPersystCanvasConfig = (): D3ImageCanvasConfig => ({
        graphId: this.visualization.config.id,
        viewScale: this.visualization.config.viewScale,
        height: this.visualization.boundingBox.height,
		dataObjectId: this.visualization.reactCallbacks.dataSourceMap.get(DataSource.CURRENT_PATIENT) as number
    })

	public getEEGLabelsConfig = (): D3ImageOverlayConfig => ({
		x: 0,
		y: 0,
		height: this.visualization.boundingBox.height,
		width: LEFT_MARGIN,
		imageData: this.visualization.getTitlesAndLabels()?.labels ?? new ArrayBuffer(0)
	})

	public getGraphClipPathId = () => `d3-eeg-montage-graph-clip-path-${this.visualization.config.id}`

	public getOverlayClipPathId = () => `d3-eeg-montage-overlay-clip-path-${this.visualization.config.id}`
}
