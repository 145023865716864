import { Selection, EnterElement } from "d3-selection"
import { D3OneToOneRenderable } from "../../../D3/D3OneToOneRenderable"
import { ScaleTime } from "d3"

export type D3AnnotationReviewIndicatorConfig = {
	startTime: number | undefined
	endTime: number | undefined
	viewScale: ScaleTime<any, any, any>
	clipPath: string
}

export class D3AnnotationReviewIndicator extends D3OneToOneRenderable<SVGGElement, SVGRectElement, D3AnnotationReviewIndicatorConfig> {
	protected enter(newElements: Selection<EnterElement, D3AnnotationReviewIndicatorConfig, any, any>): Selection<SVGRectElement, D3AnnotationReviewIndicatorConfig, SVGGElement, any> {
		const currentAnnotationReviewIndicator = newElements
			.append("rect")
			.attr("class", "currentAnnotationReviewIndicator")
			.attr("x", this.config.startTime ? this.config.viewScale(this.config.startTime) : 0)
			.attr("y", 0)
			.attr("width", this.calculateAnnotationWidth())
			.attr("height", 5)
			.attr("fill", "red")
			.attr("opacity", this.config.startTime ? "100%" : "0%")
			.attr("clip-path", `url(#${this.config.clipPath})`)
			.attr("title", "Current event")
		return currentAnnotationReviewIndicator
	}

	protected update(updatedElements: Selection<SVGRectElement, D3AnnotationReviewIndicatorConfig, any, any>): Selection<SVGRectElement, D3AnnotationReviewIndicatorConfig, SVGGElement, any> {
		return updatedElements
			.attr("x", this.config.startTime ? this.config.viewScale(this.config.startTime) : 0)
			.attr("width", this.calculateAnnotationWidth())
			.attr("opacity", this.config.startTime ? "100%" : "0%")
	}

	private calculateAnnotationWidth = () => {
		return Math.max(this.config.viewScale(this.config.endTime || 0) - this.config.viewScale(this.config.startTime || 0), 5)
	}
}