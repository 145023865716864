import { atom } from "recoil"
import { DEFAULT_EEG_JSON } from "./EEGMontage"
import { DEFAULT_TIME_SERIES_GROUP_JSON } from "./ModalityGraphGroup"
import { dispatch, scaleUtc } from "d3"
import { LinkedWindowEventOptions, LinkedWindowEventType, LinkedWindowJumpEvent, WindowClickedEvent } from "../Types/LinkedWindowInfo"
import { DEFAULT_SD_DETECTION_JSON } from "./SdDetection"
import { DEFAULT_HISTOGRAM_JSON } from "../Types/Histogram"
import { DEFAULT_SCATTER_PLOT_JSON } from "../Types/ScatterPlot"
import { DEFAULT_CPPOPT_PLOT_JSON } from "../Types/CPPOptPlot"
import { TimeBasedVisualizationConfig } from "../Types/TimeBasedVisualizationConfig"
import { WithAnnotations } from "../Types/Annotations"
import { VisualizationComponent } from "../VisualizationComponent"

export const currentlyActiveVisualizationAreaAtom = atom<string>({
	key: "currentlyActiveVisualizationArea",
	default: "",
})

export function getDefaultVisualizationProps(componentId: string): object {
	switch (componentId) {
		case VisualizationComponent.EEG_MONTAGE:
			return DEFAULT_EEG_JSON
		case VisualizationComponent.TIME_SERIES_GROUP:
			return DEFAULT_TIME_SERIES_GROUP_JSON
		case VisualizationComponent.SD_DETECTION:
			return DEFAULT_SD_DETECTION_JSON
		case VisualizationComponent.HISTOGRAM:
			return DEFAULT_HISTOGRAM_JSON
		case VisualizationComponent.SCATTER_PLOT:
			return DEFAULT_SCATTER_PLOT_JSON
		case VisualizationComponent.CPPOPT_PLOT:
			return DEFAULT_CPPOPT_PLOT_JSON
		default:
			return {}
	}
}

export const linkedWindowsDispatch = dispatch<LinkedWindowJumpEvent>("update")
export const allWindowsDispatch = dispatch<LinkedWindowJumpEvent | WindowClickedEvent>("jump", "click")

export const jumpAllWindows = (date: Date, type: LinkedWindowEventType=LinkedWindowEventType.JUMP_END, options?: LinkedWindowEventOptions) => {
	const event: LinkedWindowJumpEvent = { type, date, controller: "global", options: { autoScale: true, ...options }}
	allWindowsDispatch.call("jump", undefined, event)
}

export const getDefaultVisualizationAtom = (): TimeBasedVisualizationConfig => {
	return {
		id: "?",
		patientId: "?",
		windowType: VisualizationComponent.TIME_SERIES_GROUP,
		hasInitialized: false,
		viewScale: scaleUtc(),
		fileScale: scaleUtc(),
		timelineController: null,
		isLinked: false,
		playbackSpeed: 1,
		timeZone: "America/New_York",
		liveModeEnabled: true,
		patientIsAdmitted: true,
		dimensions: { width: 0, height: 0 },
	}
}

export const getDefaultAnnotationVisualizationAtom = (): TimeBasedVisualizationConfig & WithAnnotations => {
	return {
		...getDefaultVisualizationAtom(),
		annotations: [],
		inProgressAnnotation: {
			id: "?",
			startDate: null,
			endDate: null,
			color: "red",
			opacity: 0.2,
			text: "",
			modalities: [],
			keyPressed: null,
		},
		enableOpeningAnnotationsModal: true,
		currentlyReviewedAnnotation: undefined,
		hideAnnotations: false
	}
}
