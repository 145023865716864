export enum VisualizationComponent {
    CPPOPT_PLOT = "cppopt_plot",
    TIME_SERIES_GROUP = "time_series_group",
    EEG_MONTAGE = "eeg_montage",
    HISTOGRAM = "histogram",
    MAPOPT_PLOT = "mapopt_plot",
    PERSYST_TRENDS = "persyst_trends",
    PERSYST_EEG = "persyst_eeg",
    PLACEHOLDER = "placeholder",
    SD_DETECTION = "sd_detection",
    VISUALIZATION_SELECTOR = "visualization_selector",
    SCATTER_PLOT = 'scatter_plot',
    EVENT_REVIEW = 'event_review'
}
