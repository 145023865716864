import { CSSProperties, KeyboardEvent, useCallback, useLayoutEffect, useState } from "react"
import { MobergInputLabel } from "./MobergInputHeader"
import React from "react"
import { MobergColumn } from "../MobergLayout/MobergColumn"
import { MobergRow } from "../MobergLayout/MobergRow"
import { MdOutlineErrorOutline } from "react-icons/md"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { MobergTheme, getColors } from "../MobergThemes/MobergColors"
import { isNaN } from "lodash"
import { useOnMount } from "../../Hooks/useOnMount"

type MobergNumberInputProps = {
	label?: string
	placeholder?: string
	defaultValue?: number
	value?: number | null
	limit?: number
	orientation?: "row" | "column"
	style?: CSSProperties
	onChange?: (value: number | null) => void
	onClick?: () => void
	onSubmit?: () => void
	onValidationErrorChange?: (error: string | undefined) => void
	validationFunction?: (value: number) => string | undefined
}

export const MobergNumberInput: React.FC<MobergNumberInputProps> = ({ label, placeholder, orientation, defaultValue, value: controlledValue, style, limit=25, onChange, onClick, onSubmit, onValidationErrorChange, validationFunction }) => {
		// An input for numbers because it's not just as simple as restricting it to numeric characters (e.g. 1, 10, 1.5, 1e5, Infinity, -8.6, etc.)
		// The output from this component is always a number or null (missing value).
		// Prevent invalid values (like null, or negative, etc.) from being emitted by providing a validationFunction to return an error message instead.

		const [value, setValue] = useState<string>(defaultValue?.toString() ?? "")
		const [validationError, setValidationError] = useState<string>()

		const handleValidation = useCallback((value: string) => {
			const isNumber = !isNaN(Number(value))

			let error: string | undefined = undefined

			if (!isNumber) {
				error = "Please enter a number."
			} else {
				if (validationFunction) {
					const validationError = validationFunction(Number(value))
					if (validationError !== undefined) {
						error = validationError
					}
				}
			}

			setValidationError(error)

			if (onValidationErrorChange) {
				onValidationErrorChange(error)
			}

			if (error) {
				return error
			}

			return undefined

		}, [onValidationErrorChange, validationFunction])

		const handleChange = useCallback((value: string) => {
			if (value.length > limit) {
				return
			}

			setValue(value)
			const hasError = handleValidation(value)

			if (onChange) {
				let change: any

				if (isNaN(value) || hasError) {
					return
				}

				if (value === '') {
					if (defaultValue !== undefined) {
						change = defaultValue
					} else {
						change = null
					}
				} else {
					change = Number(value)
				}

				onChange(change)
			}
		}, [defaultValue, handleValidation, limit, onChange])

		useLayoutEffect(() => {
			if (validationError) {
				// The user typed something invalid, so we don't want to overwrite the input box with the previous valid value.
				// This allows the user to type things like -1e-5, which is a valid number, but only when you type out the whole thing.
				return 
			}

			if (value === "" && defaultValue !== undefined) {
				// If there is a default value, emit the default value when the input box is empty, but do not update the value in the input box.
				return
			}

			if (controlledValue !== undefined && controlledValue !== null && controlledValue !== Number(value)) {
				handleChange(controlledValue.toString())
			} else if (controlledValue === null && value !== "") {
				if (defaultValue !== undefined) {
					handleChange(defaultValue.toString())
				} else {
					handleChange("")
				}
			}
		}, [controlledValue, defaultValue, handleChange, validationError, value])

		const checkForSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "Enter" && onSubmit) {
				onSubmit()
			}
		}

		const getContent = () => {
			const defaultWidth = "90px"
			const red = getColors(MobergTheme.RED).main

			const inputStyle: CSSProperties = { 
				padding: "8px 16px",
				paddingRight: validationError ? "32px" : "16px", 
				borderRadius: "6px", 
				borderWidth: "1px",
				borderStyle: "solid",
				borderColor: validationError ? "transparent" : "#cdcdcd", 
				width: style?.width ?? defaultWidth,
				...style
			}

			if (validationError) {
				inputStyle.outline = `2px solid ${red}`
				inputStyle.outlineOffset = "-2px"
			}

			return (
			<>
				{label && <MobergInputLabel text={label} style={{ whiteSpace: "nowrap" }} />}

				<div style={{ position: "relative", width: style?.width ?? defaultWidth }}>
					<input
						value={value}
						placeholder={placeholder}
						onChange={event => handleChange(event.target.value.trim())}
						onClick={onClick}
						onKeyDown={checkForSubmit}
						type={"text"}
						style={inputStyle}
					/>

					{validationError && (
						<MdOutlineErrorOutline 
							size={MobergIconSize.REGULAR}
							title={validationError}
							color={red}
							style={{ position: "absolute", right: "8px", top: "25%"}}
						/>
					)}
				</div>
			</>
		)}

		useOnMount(() => {
			handleValidation(value)
		})

		return (<>
			{orientation === "row" 
				? <MobergRow gap="16px"> {getContent()} </MobergRow> 
				: <MobergColumn gap="8px"> {getContent()} </MobergColumn>
			}
		</>)
}
