import React, { useContext, useRef } from "react"
import { useRecoilValue } from "recoil"
import { DimensionsContext, DimensionsProvider } from "../../../../../../../../Providers/DimensionsProvider"
import { annotationsAtom, canOpenAnnotationsModalAtom } from "../../../../Atoms/Annotations"
import { selectedLayoutIdAtom } from "../../../../Atoms/Layout"
import { currentPatientFileInfoAtom } from "../../../../Atoms/PatientFile"
import { persystTrendsAtom } from "../../../../Atoms/PersystWindow"
import { pageManagerRegistry } from "../../../../Data/PageManagerRegistry"
import { viewScaleRegistry } from "../../../../Data/ViewScaleRegistry"
import { useD3KeyboardShortcuts } from "../../../../Hooks/useD3KeyboardShortcuts"
import { useResizeObserver } from "../../../../Hooks/useResizeObserver"
import { hotkeyActions } from "../../../../Types/KeyboardShortcut"
import { PersystTrendsWindowConfig, PersystTrendsWindowJSON } from "../../../../Types/PersystWindow"
import { useInProgressAnnotation } from "../../TimeSeriesGraphGroup/React/useInProgressAnnotation"
import { PersystTrendsImagePageManager } from "../../../../Data/PersystTrendsImagePageManager"
import { useD3CheckReloadData } from "../../../../Hooks/useD3CheckReloadData"
import { PersystTrendsWindowSettings } from "./PersystTrendsWindowSettings"
import { D3PersystTrendsWindow } from "../D3/D3PersystTrendsWindow"
import { fileScaleRegistry } from "../../../../Data/FileScaleRegistry"
import { useD3Controller } from "../../../../Hooks/useD3Controller"
import { useSocketProvider } from "../../../../../../../../Providers/SocketProvider"
import { useEventReviewProvider } from "../../../../../../../../Providers/EventReviewProvider"
import { VisualizationComponent } from "../../../../VisualizationComponent"

export type PersystTrendsWindowProps = {
	windowId: string
	json: PersystTrendsWindowJSON
}

export const PersystTrendsWindow = (props: PersystTrendsWindowProps) => { 
	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<div style={{ flex: 0 }}>
				<PersystTrendsWindowSettings windowId={props.windowId} />
			</div>
			<div style={{ flex: 1 }}>
				<DimensionsProvider>
					<PersystTrendsVisualization {...props} />
				</DimensionsProvider>
			</div>
		</div>
		
	)
}

export const PersystTrendsVisualization = (props: PersystTrendsWindowProps) => {
	const container = useRef<HTMLDivElement>(null)
	const eventReviewProvider = useEventReviewProvider()
	const { getDataQuerySocket } = useSocketProvider()
	const { fileStartDate, fileEndDate } = useRecoilValue(currentPatientFileInfoAtom)

	const selectedLayoutId = useRecoilValue(selectedLayoutIdAtom)
	const visualizationId = { layoutId: selectedLayoutId as string, windowId: props.windowId }

	const atom = persystTrendsAtom(visualizationId)
	const config = useRecoilValue(atom)

    const annotations = useRecoilValue(annotationsAtom)
    const { inProgressAnnotation } = useInProgressAnnotation(props.windowId)

	const viewScale = viewScaleRegistry.get(visualizationId, VisualizationComponent.PERSYST_TRENDS, { fileStartDate, fileEndDate, viewDuration: props.json.viewDuration ?? 30 * 60 * 1000 })
	const [viewStart, viewEnd] = viewScale.domain()
	const viewDuration = viewEnd.getTime() - viewStart.getTime()
	const dimensions = useContext(DimensionsContext)
	const enableOpeningAnnotationsModal = useRecoilValue(canOpenAnnotationsModalAtom)

	const d3Controller = useD3Controller<D3PersystTrendsWindow, PersystTrendsWindowConfig, PersystTrendsWindowJSON, PersystTrendsImagePageManager>({
		atom,
		initProps: props.json,
		nodeRef: container,
		pageManager: pageManagerRegistry.get(visualizationId, VisualizationComponent.PERSYST_TRENDS, new PersystTrendsImagePageManager(), getDataQuerySocket),
		props: {
			id: props.windowId,
			windowType: VisualizationComponent.PERSYST_TRENDS,
			annotations,
			inProgressAnnotation,
			viewScale,
			fileScale: fileScaleRegistry.get(visualizationId, VisualizationComponent.PERSYST_TRENDS, fileStartDate, fileEndDate),
			enableOpeningAnnotationsModal,
			currentlyReviewedAnnotation: eventReviewProvider.currentAnnotation,
			hideAnnotations: eventReviewProvider.hideAnnotations
		},
		d3ControllerConstructor(node, config, pageManager, callbacks) {
			return new D3PersystTrendsWindow(node, config, pageManager, callbacks)
		},
		extractJSON(config) {
            return {
                viewDuration: viewDuration,
                playbackSpeed: config.playbackSpeed,
				artifactReductionEnabled: config.artifactReductionEnabled
            }
        },
	})

	useResizeObserver({
		ref: container,
		afterResize: () => {
            d3Controller?.resetTitlesAndLabels()
        }
	})

    useD3KeyboardShortcuts({
        d3Controller,
        windowId: props.windowId,
        shortcuts: [
            hotkeyActions.NEXT_PAGE,
			hotkeyActions.PREVIOUS_PAGE,
			hotkeyActions.UPDATE_CUSTOM_ANNOTATION_MARKERS,
			hotkeyActions.CANCEL_ANNOTATION,
			hotkeyActions.PLAY_PAUSE
        ]
    })

	useD3CheckReloadData({
        d3Controller,
        clearDataIfChanges: {
			dimensions,
            viewDuration,
			artifactReduction: config.artifactReductionEnabled
        }
    })

	return <div ref={container} style={{ height: "100%" }} />
}