import React, { useContext } from "react"
import { MdClose, MdSettings } from "react-icons/md"
import {
	MobergButton,
	MobergButtonShape,
	MobergButtonVariant,
	MobergDropdown,
	MobergIconSize,
	MobergInputLabel,
	MobergNumberInput,
	MobergRow,
	MobergShortTextInput,
	MobergTheme,
} from "../../../../../Moberg"
import { ColorSpectrum } from "../../../../../Pages/Data/Visualize/DataReview/Types/ColorSpectrum"
import { HeatmapTraceConfigJSON, RenderStrategy, TraceConfigJSON, TraceOption } from "../../../../../Pages/Data/Visualize/DataReview/Types/Trace"
import { ConfigureWindowModalContext } from "../ConfigureWindowModal"
import { SpectrumSample } from "../SpectrumSample"
import { EditAnalysisModal } from "../../../../../Pages/Data/Visualize/DataReview/Components/Modals/EditAnalysis/EditAnalysisModal"
import { useModalProvider } from "../../../../../Providers/ModalProvider"
import { getAnalyticDisplayName, OnDemandAnalysis } from "../../../../../Pages/Data/Visualize/DataReview/Types/AnalysisDetails"
import { HeatmapGraphModalitiesContext } from "./HeatmapGraphModalities"

export const SourceHeatmapModalities: React.FC = () => {
	const { traceOptions, updateGraphProperty } = useContext(ConfigureWindowModalContext)
	const { activeSourceTraces, handleDelete, handleColorSpectrumChange, handleValidationErrorChange, updateTrace } = useContext(HeatmapGraphModalitiesContext)
	const { createModal } = useModalProvider()

    const sourceDataTraceOptions = traceOptions.filter((traceOption: TraceOption) => !traceOption.value.onDemandAnalysis)

	const addModality = () =>
		updateGraphProperty("traces", (previous: TraceConfigJSON[]) => {
			const newTrace: HeatmapTraceConfigJSON = {
				...sourceDataTraceOptions[0].value,
				id: `trace-${new Date(Date.now()).toISOString()}`,
				renderStrategy: RenderStrategy.HEATMAP,
				lowerBound: 0,
				upperBound: 100,
				colorSpectrum: ColorSpectrum.INFERNO,
			}

			return [...previous, newTrace]
		})

    function traceAnalysisArgumentsChanged(trace: HeatmapTraceConfigJSON, newArgs: OnDemandAnalysis | undefined) {
		updateTrace(trace.id, {
            ...trace,
			onDemandAnalysis: newArgs,
			dataKey: newArgs 
				? `${trace.rawDataKey}-${JSON.stringify(newArgs)}` // we have to be able to uniquely identify analytic traces by the same name, but with different calculations.
				: trace.rawDataKey 
		})
	}

	return (
		<div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto auto auto auto auto", columnGap: "16px", rowGap: "8px", padding: "0 16px", maxWidth: "fit-content" }}>

			<MobergInputLabel text={"Source"} />
            <MobergInputLabel text={"Label"} />
			<MobergInputLabel text={"Color spectrum"} />
			<MobergInputLabel text={"Lower bound"} />
			<MobergInputLabel text={""} />
			<MobergInputLabel text={"Upper bound"} />
			<MobergInputLabel text={"Post processing"} style={{ whiteSpace: "nowrap" }} />
			<MobergInputLabel text={""} />

			{activeSourceTraces.map(trace => {
				return (
					<>
						<MobergDropdown
							key={`${trace.id}-modalitySelector`}
							options={sourceDataTraceOptions}
							onChange={newTrace => updateTrace(trace.id, newTrace)}
							selectedValue={trace}
							equals={(a, b) => a.rawDataKey === b.rawDataKey && a.dataSource === b.dataSource}
							width={175}
						/>

                        <MobergShortTextInput
                            limit={20}
                            defaultValue={trace.name}
                            value={trace.name}
                            onChange={(name: string) => updateTrace(trace.id, { ...trace, name })}
                            style={{ width: "150px" }}
                        />

						<MobergDropdown
							key={`${trace.id}-spectrumSelector`}
							selectedValue={trace.colorSpectrum}
							onChange={spectrum => handleColorSpectrumChange(spectrum, trace)}
							options={[
								{ label: "Inferno", value: ColorSpectrum.INFERNO },
								{ label: "Viridis", value: ColorSpectrum.VIRIDIS },
								{ label: "Grays", value: ColorSpectrum.GRAYS },
								{ label: "Greens", value: ColorSpectrum.GREENS },
								{ label: "Reds", value: ColorSpectrum.REDS },
								{ label: "Red-Blue", value: ColorSpectrum.RED_BLUE },
								{ label: "Red-Yellow-Green", value: ColorSpectrum.RED_YELLOW_GREEN },
							]}
							width={175}
						/>

						<MobergNumberInput 
							key={`${trace.id}-lowerBound`}
							defaultValue={trace.lowerBound} 
							onChange={lowerBound => updateTrace(trace.id, {...trace, lowerBound: lowerBound ?? 0 })}
							onValidationErrorChange={error => handleValidationErrorChange(`${trace.id}-lowerBound`, error)}
						/>

						<MobergRow key={`${trace.id}-spectrumSample`}>
							<SpectrumSample colorSpectrum={trace.colorSpectrum} />
						</MobergRow>

						<MobergNumberInput
							key={`${trace.id}-upperBound`}
							defaultValue={trace.upperBound}
							onChange={upperBound => updateTrace(trace.id, { ...trace, upperBound: upperBound ?? 0 })}
							onValidationErrorChange={error => handleValidationErrorChange(`${trace.id}-upperBound`, error)}
						/>

                        <MobergRow gap="16px">
                            <MobergButton
                                tooltip={"Edit the post processing of " + trace.dataKey}
                                shape={MobergButtonShape.SQUARE}
                                onClick={() => {
                                    createModal(<EditAnalysisModal 
                                        analysis={trace.onDemandAnalysis}
                                        rawDataKey={trace.rawDataKey}
                                        onChange={(newArgs: OnDemandAnalysis | undefined) => traceAnalysisArgumentsChanged(trace, newArgs)}
                                    />)
                                }}
                            >
                                <MdSettings size={MobergIconSize.REGULAR} />
                            </MobergButton>

                            {trace.onDemandAnalysis 
                                ? `(${getAnalyticDisplayName(trace.onDemandAnalysis?.analytic)})`
                                : "(none)" 
                            }
                        </MobergRow>

						<MobergButton
							key={`${trace.id}-deleteHeatmap`}
							shape={MobergButtonShape.SQUARE}
							onClick={() => handleDelete(trace.id)}
							style={{ padding: "6px" }}> 
							<MdClose size={MobergIconSize.REGULAR} />
						</MobergButton>
					</>
				)
			})}

			<MobergButton onClick={addModality} theme={MobergTheme.BLUE} variant={MobergButtonVariant.OUTLINE} style={{ width: "175px" }}>
				Add source data
			</MobergButton>

		</div>
	)
}
